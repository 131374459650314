/*======= All App Files Include Here ======*/
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/*====== All Custom Variable Interduce Here ======*/
:root {
  --blue: #5D92F4;
  --indigo: #174176;
  --purple: #564fc1;
  --pink: #FF2B72;
  --red: #FF3739;
  --orange: #D46B08;
  --yellow: #FFB70F;
  --green: #00D014;
  --teal: #677080;
  --cyan: #00D0BD;
  --white: #fff;
  --gray: #727891;
  --gray-dark: #677080;
  --light-yellow: #FFF59D;
  --twitter: #52AEFF;
  --facebook: #3B5998;
  --google: #F13A30;
  --pinterest: #d32f2f;
  --instagram: #8d6e63;
  --rss: #ff9800;
  --tumblr: #607d8b;
  --linkedin: #4875B4;
  --dribbble: #f06292;
  --youtube: #d50000;
  --github: #424242;
  --skype: #4fc3f7;
  --primary: #5D92F4;
  --secondary: #677080;
  --success: #00D014;
  --info: #00D0BD;
  --warning: #FFB70F;
  --danger: #FF3739;
  --light: #FAFAFA;
  --dark: #464D69;
  --light-yellow: #FFF59D;
  --twitter: #52AEFF;
  --facebook: #3B5998;
  --google: #F13A30;
  --pinterest: #d32f2f;
  --instagram: #8d6e63;
  --rss: #ff9800;
  --tumblr: #607d8b;
  --linkedin: #4875B4;
  --dribbble: #f06292;
  --youtube: #d50000;
  --github: #424242;
  --skype: #4fc3f7;
  --orange: #D46B08;
  --purple: #564fc1;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Heebo", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464D69;
  text-align: left;
  background-color: #F4F7FA; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #5D92F4;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #1562ef;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #727891;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 1.5rem; }

h2, .h2 {
  font-size: 1.25rem; }

h3, .h3 {
  font-size: 1.125rem; }

h4, .h4 {
  font-size: 1rem; }

h5, .h5 {
  font-size: 0.875rem; }

h6, .h6 {
  font-size: 0.75rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #727891; }
  .blockquote-footer::before {
    content: "\2014   \A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F4F7FA;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #727891; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #FF2B72;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #464D69;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 87.5%;
  color: #464D69; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #EBEDF2; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #EBEDF2; }
  .table tbody + tbody {
    border-top: 2px solid #EBEDF2; }
  .table .table {
    background-color: #F4F7FA; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #EBEDF2; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #EBEDF2; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(244, 247, 250, 0.7); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d2e0fc; }

.table-hover .table-primary:hover {
  background-color: #bad0fa; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bad0fa; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4d7db; }

.table-hover .table-secondary:hover {
  background-color: #c6cacf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c6cacf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8f2bd; }

.table-hover .table-success:hover {
  background-color: #a2eea9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a2eea9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f2ed; }

.table-hover .table-info:hover {
  background-color: #a2eee8; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a2eee8; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffebbc; }

.table-hover .table-warning:hover {
  background-color: #ffe3a3; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe3a3; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffc7c8; }

.table-hover .table-danger:hover {
  background-color: #ffaeaf; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffaeaf; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cbcdd5; }

.table-hover .table-dark:hover {
  background-color: #bdbfca; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bdbfca; }

.table-light-yellow,
.table-light-yellow > th,
.table-light-yellow > td {
  background-color: #fffce4; }

.table-hover .table-light-yellow:hover {
  background-color: #fff9cb; }
  .table-hover .table-light-yellow:hover > td,
  .table-hover .table-light-yellow:hover > th {
    background-color: #fff9cb; }

.table-twitter,
.table-twitter > th,
.table-twitter > td {
  background-color: #cfe8ff; }

.table-hover .table-twitter:hover {
  background-color: #b6dcff; }
  .table-hover .table-twitter:hover > td,
  .table-hover .table-twitter:hover > th {
    background-color: #b6dcff; }

.table-facebook,
.table-facebook > th,
.table-facebook > td {
  background-color: #c8d1e2; }

.table-hover .table-facebook:hover {
  background-color: #b7c3d9; }
  .table-hover .table-facebook:hover > td,
  .table-hover .table-facebook:hover > th {
    background-color: #b7c3d9; }

.table-google,
.table-google > th,
.table-google > td {
  background-color: #fbc8c5; }

.table-hover .table-google:hover {
  background-color: #f9b1ad; }
  .table-hover .table-google:hover > td,
  .table-hover .table-google:hover > th {
    background-color: #f9b1ad; }

.table-pinterest,
.table-pinterest > th,
.table-pinterest > td {
  background-color: #f3c5c5; }

.table-hover .table-pinterest:hover {
  background-color: #efb0b0; }
  .table-hover .table-pinterest:hover > td,
  .table-hover .table-pinterest:hover > th {
    background-color: #efb0b0; }

.table-instagram,
.table-instagram > th,
.table-instagram > td {
  background-color: #dfd6d3; }

.table-hover .table-instagram:hover {
  background-color: #d4c8c4; }
  .table-hover .table-instagram:hover > td,
  .table-hover .table-instagram:hover > th {
    background-color: #d4c8c4; }

.table-rss,
.table-rss > th,
.table-rss > td {
  background-color: #ffe2b8; }

.table-hover .table-rss:hover {
  background-color: #ffd89f; }
  .table-hover .table-rss:hover > td,
  .table-hover .table-rss:hover > th {
    background-color: #ffd89f; }

.table-tumblr,
.table-tumblr > th,
.table-tumblr > td {
  background-color: #d2dbdf; }

.table-hover .table-tumblr:hover {
  background-color: #c3cfd4; }
  .table-hover .table-tumblr:hover > td,
  .table-hover .table-tumblr:hover > th {
    background-color: #c3cfd4; }

.table-linkedin,
.table-linkedin > th,
.table-linkedin > td {
  background-color: #ccd8ea; }

.table-hover .table-linkedin:hover {
  background-color: #bacae3; }
  .table-hover .table-linkedin:hover > td,
  .table-hover .table-linkedin:hover > th {
    background-color: #bacae3; }

.table-dribbble,
.table-dribbble > th,
.table-dribbble > td {
  background-color: #fbd3e0; }

.table-hover .table-dribbble:hover {
  background-color: #f9bcd0; }
  .table-hover .table-dribbble:hover > td,
  .table-hover .table-dribbble:hover > th {
    background-color: #f9bcd0; }

.table-youtube,
.table-youtube > th,
.table-youtube > td {
  background-color: #f3b8b8; }

.table-hover .table-youtube:hover {
  background-color: #efa2a2; }
  .table-hover .table-youtube:hover > td,
  .table-hover .table-youtube:hover > th {
    background-color: #efa2a2; }

.table-github,
.table-github > th,
.table-github > td {
  background-color: #cacaca; }

.table-hover .table-github:hover {
  background-color: #bdbdbd; }
  .table-hover .table-github:hover > td,
  .table-hover .table-github:hover > th {
    background-color: #bdbdbd; }

.table-skype,
.table-skype > th,
.table-skype > td {
  background-color: #ceeefd; }

.table-hover .table-skype:hover {
  background-color: #b6e6fc; }
  .table-hover .table-skype:hover > td,
  .table-hover .table-skype:hover > th {
    background-color: #b6e6fc; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #f3d6ba; }

.table-hover .table-orange:hover {
  background-color: #efc9a4; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #efc9a4; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d0ceee; }

.table-hover .table-purple:hover {
  background-color: #bebbe7; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #bebbe7; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(244, 247, 250, 0.7); }

.table-hover .table-active:hover {
  background-color: rgba(226, 234, 242, 0.7); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(226, 234, 242, 0.7); }

.table .thead-dark th {
  color: #F4F7FA;
  background-color: #464D69;
  border-color: #555e80; }

.table .thead-light th {
  color: #464D69;
  background-color: #F4F7FA;
  border-color: #EBEDF2; }

.table-dark {
  color: #F4F7FA;
  background-color: #464D69; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #555e80; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #464D69;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #464D69;
    background-color: #fff;
    border-color: #d4e2fc;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(93, 146, 244, 0.25); }
  .form-control::placeholder {
    color: #727891;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #F4F7FA;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #464D69;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1.563rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #727891; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00D014; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 208, 20, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #00D014; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #00D014;
    box-shadow: 0 0 0 0.2rem rgba(0, 208, 20, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00D014; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00D014; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #51ff61; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background: #04ff1c linear-gradient(180deg, #28fe3d, #04ff1c) repeat-x; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F4F7FA, 0 0 0 0.2rem rgba(0, 208, 20, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00D014; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(0, 208, 20, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FF3739; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 55, 57, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #FF3739; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #FF3739;
    box-shadow: 0 0 0 0.2rem rgba(255, 55, 57, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FF3739; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FF3739; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ffb7b7; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background: #ff6a6b linear-gradient(180deg, #fd7f81, #ff6a6b) repeat-x; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #F4F7FA, 0 0 0 0.2rem rgba(255, 55, 57, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FF3739; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 55, 57, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 146, 244, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(93, 146, 244, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x;
  border-color: #5D92F4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-primary:hover {
    color: #fff;
    background: #397af2 linear-gradient(180deg, #558df3, #397af2) repeat-x;
    border-color: #2d72f1; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(93, 146, 244, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #5D92F4;
    border-color: #5D92F4; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2d72f1;
    background-image: none;
    border-color: #216af0; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(93, 146, 244, 0.5); }

.btn-secondary {
  color: #fff;
  background: #677080 linear-gradient(180deg, #7c8492, #677080) repeat-x;
  border-color: #677080;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-secondary:hover {
    color: #fff;
    background: #565d6b linear-gradient(180deg, #6e7480, #565d6b) repeat-x;
    border-color: #505764; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #677080;
    border-color: #677080; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #505764;
    background-image: none;
    border-color: #4b515d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }

.btn-success {
  color: #fff;
  background: #00D014 linear-gradient(180deg, #25d637, #00D014) repeat-x;
  border-color: #00D014;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-success:hover {
    color: #fff;
    background: #00aa10 linear-gradient(180deg, #25b533, #00aa10) repeat-x;
    border-color: #009d0f; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00D014;
    border-color: #00D014; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #009d0f;
    background-image: none;
    border-color: #00900e; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }

.btn-info {
  color: #fff;
  background: #00D0BD linear-gradient(180deg, #25d6c6, #00D0BD) repeat-x;
  border-color: #00D0BD;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-info:hover {
    color: #fff;
    background: #00aa9a linear-gradient(180deg, #25b5a9, #00aa9a) repeat-x;
    border-color: #009d8f; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00D0BD;
    border-color: #00D0BD; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #009d8f;
    background-image: none;
    border-color: #009083; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }

.btn-warning {
  color: #464D69;
  background: #FFB70F linear-gradient(180deg, #fdc132, #FFB70F) repeat-x;
  border-color: #FFB70F;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-warning:hover {
    color: #464D69;
    background: #e8a200 linear-gradient(180deg, #eaaf26, #e8a200) repeat-x;
    border-color: #db9900; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #464D69;
    background-color: #FFB70F;
    border-color: #FFB70F; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #464D69;
    background-color: #db9900;
    background-image: none;
    border-color: #ce9000; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }

.btn-danger {
  color: #fff;
  background: #FF3739 linear-gradient(180deg, #fd5456, #FF3739) repeat-x;
  border-color: #FF3739;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-danger:hover {
    color: #fff;
    background: #ff1113 linear-gradient(180deg, #fd3336, #ff1113) repeat-x;
    border-color: #ff0407; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #FF3739;
    border-color: #FF3739; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ff0407;
    background-image: none;
    border-color: #f60002; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }

.btn-light {
  color: #464D69;
  background: #FAFAFA linear-gradient(180deg, #f9fafa, #FAFAFA) repeat-x;
  border-color: #FAFAFA;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light:hover {
    color: #464D69;
    background: #e7e7e7 linear-gradient(180deg, #e9e9ea, #e7e7e7) repeat-x;
    border-color: #e1e1e1; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #464D69;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #464D69;
    background-color: #e1e1e1;
    background-image: none;
    border-color: #dadada; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-dark {
  color: #fff;
  background: #464D69 linear-gradient(180deg, #60677f, #464D69) repeat-x;
  border-color: #464D69;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dark:hover {
    color: #fff;
    background: #373c52 linear-gradient(180deg, #53586b, #373c52) repeat-x;
    border-color: #32374a; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #464D69;
    border-color: #464D69; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #32374a;
    background-image: none;
    border-color: #2d3143; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }

.btn-light-yellow {
  color: #464D69;
  background: #FFF59D linear-gradient(180deg, #fdf5ab, #FFF59D) repeat-x;
  border-color: #FFF59D;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-light-yellow:hover {
    color: #464D69;
    background: #fff177 linear-gradient(180deg, #fdf28a, #fff177) repeat-x;
    border-color: #fff06a; }
  .btn-light-yellow:focus, .btn-light-yellow.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }
  .btn-light-yellow.disabled, .btn-light-yellow:disabled {
    color: #464D69;
    background-color: #FFF59D;
    border-color: #FFF59D; }
  .btn-light-yellow:not(:disabled):not(.disabled):active, .btn-light-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-light-yellow.dropdown-toggle {
    color: #464D69;
    background-color: #fff06a;
    background-image: none;
    border-color: #ffee5d; }
    .btn-light-yellow:not(:disabled):not(.disabled):active:focus, .btn-light-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light-yellow.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }

.btn-twitter {
  color: #464D69;
  background: #52AEFF linear-gradient(180deg, #6ab9fe, #52AEFF) repeat-x;
  border-color: #52AEFF;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-twitter:hover {
    color: #fff;
    background: #2c9cff linear-gradient(180deg, #4aaafe, #2c9cff) repeat-x;
    border-color: #1f96ff; }
  .btn-twitter:focus, .btn-twitter.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #464D69;
    background-color: #52AEFF;
    border-color: #52AEFF; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #1f96ff;
    background-image: none;
    border-color: #1290ff; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }

.btn-facebook {
  color: #fff;
  background: #3B5998 linear-gradient(180deg, #5771a7, #3B5998) repeat-x;
  border-color: #3B5998;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-facebook:hover {
    color: #fff;
    background: #30497c linear-gradient(180deg, #4e638f, #30497c) repeat-x;
    border-color: #2d4373; }
  .btn-facebook:focus, .btn-facebook.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(59, 89, 152, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #3B5998;
    border-color: #3B5998; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    background-image: none;
    border-color: #293e6a; }
    .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(59, 89, 152, 0.5); }

.btn-google {
  color: #fff;
  background: #F13A30 linear-gradient(180deg, #f1564e, #F13A30) repeat-x;
  border-color: #F13A30;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-google:hover {
    color: #fff;
    background: #eb1b10 linear-gradient(180deg, #ec3c33, #eb1b10) repeat-x;
    border-color: #df1a0f; }
  .btn-google:focus, .btn-google.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }
  .btn-google.disabled, .btn-google:disabled {
    color: #fff;
    background-color: #F13A30;
    border-color: #F13A30; }
  .btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
  .show > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #df1a0f;
    background-image: none;
    border-color: #d3180e; }
    .btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-google.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }

.btn-pinterest {
  color: #fff;
  background: #d32f2f linear-gradient(180deg, #d84d4d, #d32f2f) repeat-x;
  border-color: #d32f2f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-pinterest:hover {
    color: #fff;
    background: #b52626 linear-gradient(180deg, #bf4646, #b52626) repeat-x;
    border-color: #ab2424; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #fff;
    background-color: #d32f2f;
    border-color: #d32f2f; }
  .btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #ab2424;
    background-image: none;
    border-color: #a02222; }
    .btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }

.btn-instagram {
  color: #fff;
  background: #8d6e63 linear-gradient(180deg, #9c837a, #8d6e63) repeat-x;
  border-color: #8d6e63;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-instagram:hover {
    color: #fff;
    background: #775c53 linear-gradient(180deg, #89746c, #775c53) repeat-x;
    border-color: #6f574e; }
  .btn-instagram:focus, .btn-instagram.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    color: #fff;
    background-color: #8d6e63;
    border-color: #8d6e63; }
  .btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #6f574e;
    background-image: none;
    border-color: #685149; }
    .btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }

.btn-rss {
  color: #464D69;
  background: #ff9800 linear-gradient(180deg, #fda626, #ff9800) repeat-x;
  border-color: #ff9800;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-rss:hover {
    color: #fff;
    background: #d98100 linear-gradient(180deg, #dd9326, #d98100) repeat-x;
    border-color: #cc7a00; }
  .btn-rss:focus, .btn-rss.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-rss.disabled, .btn-rss:disabled {
    color: #464D69;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-rss:not(:disabled):not(.disabled):active, .btn-rss:not(:disabled):not(.disabled).active,
  .show > .btn-rss.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    background-image: none;
    border-color: #bf7200; }
    .btn-rss:not(:disabled):not(.disabled):active:focus, .btn-rss:not(:disabled):not(.disabled).active:focus,
    .show > .btn-rss.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-tumblr {
  color: #fff;
  background: #607d8b linear-gradient(180deg, #768f9c, #607d8b) repeat-x;
  border-color: #607d8b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-tumblr:hover {
    color: #fff;
    background: #506974 linear-gradient(180deg, #697e88, #506974) repeat-x;
    border-color: #4b626d; }
  .btn-tumblr:focus, .btn-tumblr.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }
  .btn-tumblr.disabled, .btn-tumblr:disabled {
    color: #fff;
    background-color: #607d8b;
    border-color: #607d8b; }
  .btn-tumblr:not(:disabled):not(.disabled):active, .btn-tumblr:not(:disabled):not(.disabled).active,
  .show > .btn-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #4b626d;
    background-image: none;
    border-color: #465b65; }
    .btn-tumblr:not(:disabled):not(.disabled):active:focus, .btn-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tumblr.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }

.btn-linkedin {
  color: #fff;
  background: #4875B4 linear-gradient(180deg, #6289bf, #4875B4) repeat-x;
  border-color: #4875B4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-linkedin:hover {
    color: #fff;
    background: #3d6399 linear-gradient(180deg, #5979a7, #3d6399) repeat-x;
    border-color: #395d90; }
  .btn-linkedin:focus, .btn-linkedin.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(72, 117, 180, 0.5); }
  .btn-linkedin.disabled, .btn-linkedin:disabled {
    color: #fff;
    background-color: #4875B4;
    border-color: #4875B4; }
  .btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #395d90;
    background-image: none;
    border-color: #365786; }
    .btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-linkedin.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 117, 180, 0.5); }

.btn-dribbble {
  color: #fff;
  background: #f06292 linear-gradient(180deg, #f178a2, #f06292) repeat-x;
  border-color: #f06292;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-dribbble:hover {
    color: #fff;
    background: #ed3f7a linear-gradient(180deg, #ee5b8d, #ed3f7a) repeat-x;
    border-color: #ec3372; }
  .btn-dribbble:focus, .btn-dribbble.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }
  .btn-dribbble.disabled, .btn-dribbble:disabled {
    color: #fff;
    background-color: #f06292;
    border-color: #f06292; }
  .btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #ec3372;
    background-image: none;
    border-color: #ea286a; }
    .btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dribbble.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }

.btn-youtube {
  color: #fff;
  background: #d50000 linear-gradient(180deg, #da2526, #d50000) repeat-x;
  border-color: #d50000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-youtube:hover {
    color: #fff;
    background: #af0000 linear-gradient(180deg, #b92526, #af0000) repeat-x;
    border-color: #a20000; }
  .btn-youtube:focus, .btn-youtube.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }
  .btn-youtube.disabled, .btn-youtube:disabled {
    color: #fff;
    background-color: #d50000;
    border-color: #d50000; }
  .btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-youtube.dropdown-toggle {
    color: #fff;
    background-color: #a20000;
    background-image: none;
    border-color: #950000; }
    .btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-youtube.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }

.btn-github {
  color: #fff;
  background: #424242 linear-gradient(180deg, #5d5d5e, #424242) repeat-x;
  border-color: #424242;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-github:hover {
    color: #fff;
    background: #2f2f2f linear-gradient(180deg, #4c4d4d, #2f2f2f) repeat-x;
    border-color: #292929; }
  .btn-github:focus, .btn-github.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-github.disabled, .btn-github:disabled {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
  .show > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #292929;
    background-image: none;
    border-color: #222222; }
    .btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-github.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-skype {
  color: #464D69;
  background: #4fc3f7 linear-gradient(180deg, #68cbf7, #4fc3f7) repeat-x;
  border-color: #4fc3f7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-skype:hover {
    color: #fff;
    background: #2ab7f5 linear-gradient(180deg, #49c0f6, #2ab7f5) repeat-x;
    border-color: #1eb2f5; }
  .btn-skype:focus, .btn-skype.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }
  .btn-skype.disabled, .btn-skype:disabled {
    color: #464D69;
    background-color: #4fc3f7;
    border-color: #4fc3f7; }
  .btn-skype:not(:disabled):not(.disabled):active, .btn-skype:not(:disabled):not(.disabled).active,
  .show > .btn-skype.dropdown-toggle {
    color: #fff;
    background-color: #1eb2f5;
    background-image: none;
    border-color: #12aef4; }
    .btn-skype:not(:disabled):not(.disabled):active:focus, .btn-skype:not(:disabled):not(.disabled).active:focus,
    .show > .btn-skype.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }

.btn-orange {
  color: #fff;
  background: #D46B08 linear-gradient(180deg, #d9802c, #D46B08) repeat-x;
  border-color: #D46B08;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-orange:hover {
    color: #fff;
    background: #af5807 linear-gradient(180deg, #b9702b, #af5807) repeat-x;
    border-color: #a35206; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(212, 107, 8, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background-color: #D46B08;
    border-color: #D46B08; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #a35206;
    background-image: none;
    border-color: #974c06; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 107, 8, 0.5); }

.btn-purple {
  color: #fff;
  background: #564fc1 linear-gradient(180deg, #6e68ca, #564fc1) repeat-x;
  border-color: #564fc1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .btn-purple:hover {
    color: #fff;
    background: #443dad linear-gradient(180deg, #5e59b8, #443dad) repeat-x;
    border-color: #403aa3; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(86, 79, 193, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #564fc1;
    border-color: #564fc1; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #403aa3;
    background-image: none;
    border-color: #3c369a; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(86, 79, 193, 0.5); }

.btn-outline-primary {
  color: #5D92F4;
  background-color: transparent;
  background-image: none;
  border-color: #5D92F4; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #5D92F4;
    border-color: #5D92F4; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(93, 146, 244, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5D92F4;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #5D92F4;
    border-color: #5D92F4; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(93, 146, 244, 0.5); }

.btn-outline-secondary {
  color: #677080;
  background-color: transparent;
  background-image: none;
  border-color: #677080; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #677080;
    border-color: #677080; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #677080;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #677080;
    border-color: #677080; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(103, 112, 128, 0.5); }

.btn-outline-success {
  color: #00D014;
  background-color: transparent;
  background-image: none;
  border-color: #00D014; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #00D014;
    border-color: #00D014; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00D014;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00D014;
    border-color: #00D014; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 20, 0.5); }

.btn-outline-info {
  color: #00D0BD;
  background-color: transparent;
  background-image: none;
  border-color: #00D0BD; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00D0BD;
    border-color: #00D0BD; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00D0BD;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00D0BD;
    border-color: #00D0BD; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 208, 189, 0.5); }

.btn-outline-warning {
  color: #FFB70F;
  background-color: transparent;
  background-image: none;
  border-color: #FFB70F; }
  .btn-outline-warning:hover {
    color: #464D69;
    background-color: #FFB70F;
    border-color: #FFB70F; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFB70F;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #464D69;
    background-color: #FFB70F;
    border-color: #FFB70F; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 183, 15, 0.5); }

.btn-outline-danger {
  color: #FF3739;
  background-color: transparent;
  background-image: none;
  border-color: #FF3739; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #FF3739;
    border-color: #FF3739; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #FF3739;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #FF3739;
    border-color: #FF3739; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 55, 57, 0.5); }

.btn-outline-light {
  color: #FAFAFA;
  background-color: transparent;
  background-image: none;
  border-color: #FAFAFA; }
  .btn-outline-light:hover {
    color: #464D69;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #FAFAFA;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #464D69;
    background-color: #FAFAFA;
    border-color: #FAFAFA; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(250, 250, 250, 0.5); }

.btn-outline-dark {
  color: #464D69;
  background-color: transparent;
  background-image: none;
  border-color: #464D69; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #464D69;
    border-color: #464D69; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #464D69;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #464D69;
    border-color: #464D69; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(70, 77, 105, 0.5); }

.btn-outline-light-yellow {
  color: #FFF59D;
  background-color: transparent;
  background-image: none;
  border-color: #FFF59D; }
  .btn-outline-light-yellow:hover {
    color: #464D69;
    background-color: #FFF59D;
    border-color: #FFF59D; }
  .btn-outline-light-yellow:focus, .btn-outline-light-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }
  .btn-outline-light-yellow.disabled, .btn-outline-light-yellow:disabled {
    color: #FFF59D;
    background-color: transparent; }
  .btn-outline-light-yellow:not(:disabled):not(.disabled):active, .btn-outline-light-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light-yellow.dropdown-toggle {
    color: #464D69;
    background-color: #FFF59D;
    border-color: #FFF59D; }
    .btn-outline-light-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-light-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light-yellow.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 245, 157, 0.5); }

.btn-outline-twitter {
  color: #52AEFF;
  background-color: transparent;
  background-image: none;
  border-color: #52AEFF; }
  .btn-outline-twitter:hover {
    color: #464D69;
    background-color: #52AEFF;
    border-color: #52AEFF; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }
  .btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
    color: #52AEFF;
    background-color: transparent; }
  .btn-outline-twitter:not(:disabled):not(.disabled):active, .btn-outline-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-outline-twitter.dropdown-toggle {
    color: #464D69;
    background-color: #52AEFF;
    border-color: #52AEFF; }
    .btn-outline-twitter:not(:disabled):not(.disabled):active:focus, .btn-outline-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-twitter.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(82, 174, 255, 0.5); }

.btn-outline-facebook {
  color: #3B5998;
  background-color: transparent;
  background-image: none;
  border-color: #3B5998; }
  .btn-outline-facebook:hover {
    color: #fff;
    background-color: #3B5998;
    border-color: #3B5998; }
  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5); }
  .btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
    color: #3B5998;
    background-color: transparent; }
  .btn-outline-facebook:not(:disabled):not(.disabled):active, .btn-outline-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-outline-facebook.dropdown-toggle {
    color: #fff;
    background-color: #3B5998;
    border-color: #3B5998; }
    .btn-outline-facebook:not(:disabled):not(.disabled):active:focus, .btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-facebook.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(59, 89, 152, 0.5); }

.btn-outline-google {
  color: #F13A30;
  background-color: transparent;
  background-image: none;
  border-color: #F13A30; }
  .btn-outline-google:hover {
    color: #fff;
    background-color: #F13A30;
    border-color: #F13A30; }
  .btn-outline-google:focus, .btn-outline-google.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }
  .btn-outline-google.disabled, .btn-outline-google:disabled {
    color: #F13A30;
    background-color: transparent; }
  .btn-outline-google:not(:disabled):not(.disabled):active, .btn-outline-google:not(:disabled):not(.disabled).active,
  .show > .btn-outline-google.dropdown-toggle {
    color: #fff;
    background-color: #F13A30;
    border-color: #F13A30; }
    .btn-outline-google:not(:disabled):not(.disabled):active:focus, .btn-outline-google:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-google.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(241, 58, 48, 0.5); }

.btn-outline-pinterest {
  color: #d32f2f;
  background-color: transparent;
  background-image: none;
  border-color: #d32f2f; }
  .btn-outline-pinterest:hover {
    color: #fff;
    background-color: #d32f2f;
    border-color: #d32f2f; }
  .btn-outline-pinterest:focus, .btn-outline-pinterest.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }
  .btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
    color: #d32f2f;
    background-color: transparent; }
  .btn-outline-pinterest:not(:disabled):not(.disabled):active, .btn-outline-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #d32f2f;
    border-color: #d32f2f; }
    .btn-outline-pinterest:not(:disabled):not(.disabled):active:focus, .btn-outline-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pinterest.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(211, 47, 47, 0.5); }

.btn-outline-instagram {
  color: #8d6e63;
  background-color: transparent;
  background-image: none;
  border-color: #8d6e63; }
  .btn-outline-instagram:hover {
    color: #fff;
    background-color: #8d6e63;
    border-color: #8d6e63; }
  .btn-outline-instagram:focus, .btn-outline-instagram.focus {
    box-shadow: 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }
  .btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
    color: #8d6e63;
    background-color: transparent; }
  .btn-outline-instagram:not(:disabled):not(.disabled):active, .btn-outline-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-outline-instagram.dropdown-toggle {
    color: #fff;
    background-color: #8d6e63;
    border-color: #8d6e63; }
    .btn-outline-instagram:not(:disabled):not(.disabled):active:focus, .btn-outline-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-instagram.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(141, 110, 99, 0.5); }

.btn-outline-rss {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .btn-outline-rss:hover {
    color: #464D69;
    background-color: #ff9800;
    border-color: #ff9800; }
  .btn-outline-rss:focus, .btn-outline-rss.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .btn-outline-rss.disabled, .btn-outline-rss:disabled {
    color: #ff9800;
    background-color: transparent; }
  .btn-outline-rss:not(:disabled):not(.disabled):active, .btn-outline-rss:not(:disabled):not(.disabled).active,
  .show > .btn-outline-rss.dropdown-toggle {
    color: #464D69;
    background-color: #ff9800;
    border-color: #ff9800; }
    .btn-outline-rss:not(:disabled):not(.disabled):active:focus, .btn-outline-rss:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-rss.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.btn-outline-tumblr {
  color: #607d8b;
  background-color: transparent;
  background-image: none;
  border-color: #607d8b; }
  .btn-outline-tumblr:hover {
    color: #fff;
    background-color: #607d8b;
    border-color: #607d8b; }
  .btn-outline-tumblr:focus, .btn-outline-tumblr.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }
  .btn-outline-tumblr.disabled, .btn-outline-tumblr:disabled {
    color: #607d8b;
    background-color: transparent; }
  .btn-outline-tumblr:not(:disabled):not(.disabled):active, .btn-outline-tumblr:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #607d8b;
    border-color: #607d8b; }
    .btn-outline-tumblr:not(:disabled):not(.disabled):active:focus, .btn-outline-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tumblr.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(96, 125, 139, 0.5); }

.btn-outline-linkedin {
  color: #4875B4;
  background-color: transparent;
  background-image: none;
  border-color: #4875B4; }
  .btn-outline-linkedin:hover {
    color: #fff;
    background-color: #4875B4;
    border-color: #4875B4; }
  .btn-outline-linkedin:focus, .btn-outline-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 117, 180, 0.5); }
  .btn-outline-linkedin.disabled, .btn-outline-linkedin:disabled {
    color: #4875B4;
    background-color: transparent; }
  .btn-outline-linkedin:not(:disabled):not(.disabled):active, .btn-outline-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-outline-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #4875B4;
    border-color: #4875B4; }
    .btn-outline-linkedin:not(:disabled):not(.disabled):active:focus, .btn-outline-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-linkedin.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(72, 117, 180, 0.5); }

.btn-outline-dribbble {
  color: #f06292;
  background-color: transparent;
  background-image: none;
  border-color: #f06292; }
  .btn-outline-dribbble:hover {
    color: #fff;
    background-color: #f06292;
    border-color: #f06292; }
  .btn-outline-dribbble:focus, .btn-outline-dribbble.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }
  .btn-outline-dribbble.disabled, .btn-outline-dribbble:disabled {
    color: #f06292;
    background-color: transparent; }
  .btn-outline-dribbble:not(:disabled):not(.disabled):active, .btn-outline-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #f06292;
    border-color: #f06292; }
    .btn-outline-dribbble:not(:disabled):not(.disabled):active:focus, .btn-outline-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dribbble.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(240, 98, 146, 0.5); }

.btn-outline-youtube {
  color: #d50000;
  background-color: transparent;
  background-image: none;
  border-color: #d50000; }
  .btn-outline-youtube:hover {
    color: #fff;
    background-color: #d50000;
    border-color: #d50000; }
  .btn-outline-youtube:focus, .btn-outline-youtube.focus {
    box-shadow: 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }
  .btn-outline-youtube.disabled, .btn-outline-youtube:disabled {
    color: #d50000;
    background-color: transparent; }
  .btn-outline-youtube:not(:disabled):not(.disabled):active, .btn-outline-youtube:not(:disabled):not(.disabled).active,
  .show > .btn-outline-youtube.dropdown-toggle {
    color: #fff;
    background-color: #d50000;
    border-color: #d50000; }
    .btn-outline-youtube:not(:disabled):not(.disabled):active:focus, .btn-outline-youtube:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-youtube.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(213, 0, 0, 0.5); }

.btn-outline-github {
  color: #424242;
  background-color: transparent;
  background-image: none;
  border-color: #424242; }
  .btn-outline-github:hover {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
  .btn-outline-github:focus, .btn-outline-github.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }
  .btn-outline-github.disabled, .btn-outline-github:disabled {
    color: #424242;
    background-color: transparent; }
  .btn-outline-github:not(:disabled):not(.disabled):active, .btn-outline-github:not(:disabled):not(.disabled).active,
  .show > .btn-outline-github.dropdown-toggle {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }
    .btn-outline-github:not(:disabled):not(.disabled):active:focus, .btn-outline-github:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-github.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(66, 66, 66, 0.5); }

.btn-outline-skype {
  color: #4fc3f7;
  background-color: transparent;
  background-image: none;
  border-color: #4fc3f7; }
  .btn-outline-skype:hover {
    color: #464D69;
    background-color: #4fc3f7;
    border-color: #4fc3f7; }
  .btn-outline-skype:focus, .btn-outline-skype.focus {
    box-shadow: 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }
  .btn-outline-skype.disabled, .btn-outline-skype:disabled {
    color: #4fc3f7;
    background-color: transparent; }
  .btn-outline-skype:not(:disabled):not(.disabled):active, .btn-outline-skype:not(:disabled):not(.disabled).active,
  .show > .btn-outline-skype.dropdown-toggle {
    color: #464D69;
    background-color: #4fc3f7;
    border-color: #4fc3f7; }
    .btn-outline-skype:not(:disabled):not(.disabled):active:focus, .btn-outline-skype:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-skype.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(79, 195, 247, 0.5); }

.btn-outline-orange {
  color: #D46B08;
  background-color: transparent;
  background-image: none;
  border-color: #D46B08; }
  .btn-outline-orange:hover {
    color: #fff;
    background-color: #D46B08;
    border-color: #D46B08; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 107, 8, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #D46B08;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #fff;
    background-color: #D46B08;
    border-color: #D46B08; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(212, 107, 8, 0.5); }

.btn-outline-purple {
  color: #564fc1;
  background-color: transparent;
  background-image: none;
  border-color: #564fc1; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #564fc1;
    border-color: #564fc1; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(86, 79, 193, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #564fc1;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #564fc1;
    border-color: #564fc1; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(86, 79, 193, 0.5); }

.btn-link {
  font-weight: 400;
  color: #5D92F4;
  background-color: transparent; }
  .btn-link:hover {
    color: #1562ef;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #EBEDF2; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #464D69;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  transition: all .2s linear !important; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F4F7FA; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #464D69;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #3c425a;
    text-decoration: none;
    background: #EBEDF2 linear-gradient(180deg, #eceff3, #EBEDF2) repeat-x; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #EBEDF2;
    background-color: transparent;
    background-image: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #727891;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464D69;
  text-align: center;
  white-space: nowrap;
  background-color: #EBEDF2;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #F4F7FA, 0 0 0 0.2rem rgba(93, 146, 244, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #F5F9FB; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #F4F7FA; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #EBEDF2;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 146, 244, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(93, 146, 244, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 146, 244, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #464D69;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23677080' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #d4e2fc;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(212, 226, 252, 0.5); }
    .custom-select:focus::-ms-value {
      color: #464D69;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #EBEDF2;
    background-color: #F4F7FA; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #d4e2fc;
    box-shadow: 0 0 0 0.2rem rgba(93, 146, 244, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #d4e2fc; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #464D69;
  background-color: #fff;
  border: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #464D69;
    content: "Browse";
    background: #EBEDF2 linear-gradient(180deg, #eceff3, #EBEDF2) repeat-x;
    border-left: 1px solid #EBEDF2;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #EBEDF2; }

.nav-tabs {
  border-bottom: 1px solid #EBEDF2; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #F4F7FA #F4F7FA #EBEDF2; }
    .nav-tabs .nav-link.disabled {
      color: #EBEDF2;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #A5A7B2;
    background-color: #F4F7FA;
    border-color: #EBEDF2 #EBEDF2 #F4F7FA; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5D92F4; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 12px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -12px;
      margin-left: -12px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 12px;
        margin-bottom: 0;
        margin-left: 12px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 12px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #F4F7FA;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #727891;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #727891; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #5D92F4;
  background-color: #fff;
  border: 1px solid #EBEDF2; }
  .page-link:hover {
    color: #1562ef;
    text-decoration: none;
    background-color: #F4F7FA;
    border-color: #EBEDF2; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 146, 244, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #5D92F4;
  border-color: #5D92F4; }

.page-item.disabled .page-link {
  color: #EBEDF2;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #EBEDF2; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #5D92F4; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2d72f1; }

.badge-secondary {
  color: #fff;
  background-color: #677080; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #505764; }

.badge-success {
  color: #fff;
  background-color: #00D014; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #009d0f; }

.badge-info {
  color: #fff;
  background-color: #00D0BD; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #009d8f; }

.badge-warning {
  color: #464D69;
  background-color: #FFB70F; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #db9900; }

.badge-danger {
  color: #fff;
  background-color: #FF3739; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ff0407; }

.badge-light {
  color: #464D69;
  background-color: #FAFAFA; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #e1e1e1; }

.badge-dark {
  color: #fff;
  background-color: #464D69; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #32374a; }

.badge-light-yellow {
  color: #464D69;
  background-color: #FFF59D; }
  .badge-light-yellow[href]:hover, .badge-light-yellow[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #fff06a; }

.badge-twitter {
  color: #464D69;
  background-color: #52AEFF; }
  .badge-twitter[href]:hover, .badge-twitter[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #1f96ff; }

.badge-facebook {
  color: #fff;
  background-color: #3B5998; }
  .badge-facebook[href]:hover, .badge-facebook[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #2d4373; }

.badge-google {
  color: #fff;
  background-color: #F13A30; }
  .badge-google[href]:hover, .badge-google[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #df1a0f; }

.badge-pinterest {
  color: #fff;
  background-color: #d32f2f; }
  .badge-pinterest[href]:hover, .badge-pinterest[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ab2424; }

.badge-instagram {
  color: #fff;
  background-color: #8d6e63; }
  .badge-instagram[href]:hover, .badge-instagram[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #6f574e; }

.badge-rss {
  color: #464D69;
  background-color: #ff9800; }
  .badge-rss[href]:hover, .badge-rss[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #cc7a00; }

.badge-tumblr {
  color: #fff;
  background-color: #607d8b; }
  .badge-tumblr[href]:hover, .badge-tumblr[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #4b626d; }

.badge-linkedin {
  color: #fff;
  background-color: #4875B4; }
  .badge-linkedin[href]:hover, .badge-linkedin[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #395d90; }

.badge-dribbble {
  color: #fff;
  background-color: #f06292; }
  .badge-dribbble[href]:hover, .badge-dribbble[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ec3372; }

.badge-youtube {
  color: #fff;
  background-color: #d50000; }
  .badge-youtube[href]:hover, .badge-youtube[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #a20000; }

.badge-github {
  color: #fff;
  background-color: #424242; }
  .badge-github[href]:hover, .badge-github[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #292929; }

.badge-skype {
  color: #464D69;
  background-color: #4fc3f7; }
  .badge-skype[href]:hover, .badge-skype[href]:focus {
    color: #464D69;
    text-decoration: none;
    background-color: #1eb2f5; }

.badge-orange {
  color: #fff;
  background-color: #D46B08; }
  .badge-orange[href]:hover, .badge-orange[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #a35206; }

.badge-purple {
  color: #fff;
  background-color: #564fc1; }
  .badge-purple[href]:hover, .badge-purple[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #403aa3; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #F4F7FA;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #304c7f;
  background: #dfe9fd linear-gradient(180deg, #e2ebfd, #dfe9fd) repeat-x;
  border-color: #d2e0fc; }
  .alert-primary hr {
    border-top-color: #bad0fa; }
  .alert-primary .alert-link {
    color: #22365a; }

.alert-secondary {
  color: #363a43;
  background: #e1e2e6 linear-gradient(180deg, #e4e5e9, #e1e2e6) repeat-x;
  border-color: #d4d7db; }
  .alert-secondary hr {
    border-top-color: #c6cacf; }
  .alert-secondary .alert-link {
    color: #1f2227; }

.alert-success {
  color: #006c0a;
  background: #ccf6d0 linear-gradient(180deg, #d2f6d6, #ccf6d0) repeat-x;
  border-color: #b8f2bd; }
  .alert-success hr {
    border-top-color: #a2eea9; }
  .alert-success .alert-link {
    color: #003905; }

.alert-info {
  color: #006c62;
  background: #ccf6f2 linear-gradient(180deg, #d2f6f3, #ccf6f2) repeat-x;
  border-color: #b8f2ed; }
  .alert-info hr {
    border-top-color: #a2eee8; }
  .alert-info .alert-link {
    color: #003934; }

.alert-warning {
  color: #855f08;
  background: #fff1cf linear-gradient(180deg, #fdf2d5, #fff1cf) repeat-x;
  border-color: #ffebbc; }
  .alert-warning hr {
    border-top-color: #ffe3a3; }
  .alert-warning .alert-link {
    color: #553d05; }

.alert-danger {
  color: #851d1e;
  background: #ffd7d7 linear-gradient(180deg, #fddcdc, #ffd7d7) repeat-x;
  border-color: #ffc7c8; }
  .alert-danger hr {
    border-top-color: #ffaeaf; }
  .alert-danger .alert-link {
    color: #5b1415; }

.alert-light {
  color: #828282;
  background: #fefefe linear-gradient(180deg, #fdfdfd, #fefefe) repeat-x;
  border-color: #fefefe; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #242837;
  background: #dadbe1 linear-gradient(180deg, #dedfe5, #dadbe1) repeat-x;
  border-color: #cbcdd5; }
  .alert-dark hr {
    border-top-color: #bdbfca; }
  .alert-dark .alert-link {
    color: #101218; }

.alert-light-yellow {
  color: #857f52;
  background: #fffdeb linear-gradient(180deg, #fdfced, #fffdeb) repeat-x;
  border-color: #fffce4; }
  .alert-light-yellow hr {
    border-top-color: #fff9cb; }
  .alert-light-yellow .alert-link {
    color: #65613f; }

.alert-twitter {
  color: #2b5a85;
  background: #dcefff linear-gradient(180deg, #e0f0fe, #dcefff) repeat-x;
  border-color: #cfe8ff; }
  .alert-twitter hr {
    border-top-color: #b6dcff; }
  .alert-twitter .alert-link {
    color: #1f405e; }

.alert-facebook {
  color: #1f2e4f;
  background: #d8deea linear-gradient(180deg, #dce2ec, #d8deea) repeat-x;
  border-color: #c8d1e2; }
  .alert-facebook hr {
    border-top-color: #b7c3d9; }
  .alert-facebook .alert-link {
    color: #11192a; }

.alert-google {
  color: #7d1e19;
  background: #fcd8d6 linear-gradient(180deg, #fbdddb, #fcd8d6) repeat-x;
  border-color: #fbc8c5; }
  .alert-google hr {
    border-top-color: #f9b1ad; }
  .alert-google .alert-link {
    color: #531411; }

.alert-pinterest {
  color: #6e1818;
  background: #f6d5d5 linear-gradient(180deg, #f6dadb, #f6d5d5) repeat-x;
  border-color: #f3c5c5; }
  .alert-pinterest hr {
    border-top-color: #efb0b0; }
  .alert-pinterest .alert-link {
    color: #440f0f; }

.alert-instagram {
  color: #493933;
  background: #e8e2e0 linear-gradient(180deg, #eae5e4, #e8e2e0) repeat-x;
  border-color: #dfd6d3; }
  .alert-instagram hr {
    border-top-color: #d4c8c4; }
  .alert-instagram .alert-link {
    color: #2b221e; }

.alert-rss {
  color: #854f00;
  background: #ffeacc linear-gradient(180deg, #fdecd3, #ffeacc) repeat-x;
  border-color: #ffe2b8; }
  .alert-rss hr {
    border-top-color: #ffd89f; }
  .alert-rss .alert-link {
    color: #523100; }

.alert-tumblr {
  color: #324148;
  background: #dfe5e8 linear-gradient(180deg, #e2e8eb, #dfe5e8) repeat-x;
  border-color: #d2dbdf; }
  .alert-tumblr hr {
    border-top-color: #c3cfd4; }
  .alert-tumblr .alert-link {
    color: #1d262a; }

.alert-linkedin {
  color: #253d5e;
  background: #dae3f0 linear-gradient(180deg, #dee6f2, #dae3f0) repeat-x;
  border-color: #ccd8ea; }
  .alert-linkedin hr {
    border-top-color: #bacae3; }
  .alert-linkedin .alert-link {
    color: #172539; }

.alert-dribbble {
  color: #7d334c;
  background: #fce0e9 linear-gradient(180deg, #fbe3ec, #fce0e9) repeat-x;
  border-color: #fbd3e0; }
  .alert-dribbble hr {
    border-top-color: #f9bcd0; }
  .alert-dribbble .alert-link {
    color: #592436; }

.alert-youtube {
  color: #6f0000;
  background: #f7cccc linear-gradient(180deg, #f7d2d3, #f7cccc) repeat-x;
  border-color: #f3b8b8; }
  .alert-youtube hr {
    border-top-color: #efa2a2; }
  .alert-youtube .alert-link {
    color: #3c0000; }

.alert-github {
  color: #222222;
  background: #d9d9d9 linear-gradient(180deg, #dddede, #d9d9d9) repeat-x;
  border-color: #cacaca; }
  .alert-github hr {
    border-top-color: #bdbdbd; }
  .alert-github .alert-link {
    color: #090909; }

.alert-skype {
  color: #296580;
  background: #dcf3fd linear-gradient(180deg, #e0f4fd, #dcf3fd) repeat-x;
  border-color: #ceeefd; }
  .alert-skype hr {
    border-top-color: #b6e6fc; }
  .alert-skype .alert-link {
    color: #1d4759; }

.alert-orange {
  color: #6e3804;
  background: #f6e1ce linear-gradient(180deg, #f6e4d5, #f6e1ce) repeat-x;
  border-color: #f3d6ba; }
  .alert-orange hr {
    border-top-color: #efc9a4; }
  .alert-orange .alert-link {
    color: #3d1f02; }

.alert-purple {
  color: #2d2964;
  background: #dddcf3 linear-gradient(180deg, #e0e0f4, #dddcf3) repeat-x;
  border-color: #d0ceee; }
  .alert-purple hr {
    border-top-color: #bebbe7; }
  .alert-purple .alert-link {
    color: #1d1a40; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #F4F7FA;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #5D92F4;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #A5A7B2;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #A5A7B2;
    text-decoration: none;
    background-color: #FAFAFA; }
  .list-group-item-action:active {
    color: #464D69;
    background-color: #F4F7FA; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #EBEDF2;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #5D92F4;
    border-color: #5D92F4; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #304c7f;
  background-color: #d2e0fc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #304c7f;
    background-color: #bad0fa; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #304c7f;
    border-color: #304c7f; }

.list-group-item-secondary {
  color: #363a43;
  background-color: #d4d7db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #363a43;
    background-color: #c6cacf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #363a43;
    border-color: #363a43; }

.list-group-item-success {
  color: #006c0a;
  background-color: #b8f2bd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #006c0a;
    background-color: #a2eea9; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006c0a;
    border-color: #006c0a; }

.list-group-item-info {
  color: #006c62;
  background-color: #b8f2ed; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006c62;
    background-color: #a2eee8; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #006c62;
    border-color: #006c62; }

.list-group-item-warning {
  color: #855f08;
  background-color: #ffebbc; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #855f08;
    background-color: #ffe3a3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #855f08;
    border-color: #855f08; }

.list-group-item-danger {
  color: #851d1e;
  background-color: #ffc7c8; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #851d1e;
    background-color: #ffaeaf; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #851d1e;
    border-color: #851d1e; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #242837;
  background-color: #cbcdd5; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #242837;
    background-color: #bdbfca; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #242837;
    border-color: #242837; }

.list-group-item-light-yellow {
  color: #857f52;
  background-color: #fffce4; }
  .list-group-item-light-yellow.list-group-item-action:hover, .list-group-item-light-yellow.list-group-item-action:focus {
    color: #857f52;
    background-color: #fff9cb; }
  .list-group-item-light-yellow.list-group-item-action.active {
    color: #fff;
    background-color: #857f52;
    border-color: #857f52; }

.list-group-item-twitter {
  color: #2b5a85;
  background-color: #cfe8ff; }
  .list-group-item-twitter.list-group-item-action:hover, .list-group-item-twitter.list-group-item-action:focus {
    color: #2b5a85;
    background-color: #b6dcff; }
  .list-group-item-twitter.list-group-item-action.active {
    color: #fff;
    background-color: #2b5a85;
    border-color: #2b5a85; }

.list-group-item-facebook {
  color: #1f2e4f;
  background-color: #c8d1e2; }
  .list-group-item-facebook.list-group-item-action:hover, .list-group-item-facebook.list-group-item-action:focus {
    color: #1f2e4f;
    background-color: #b7c3d9; }
  .list-group-item-facebook.list-group-item-action.active {
    color: #fff;
    background-color: #1f2e4f;
    border-color: #1f2e4f; }

.list-group-item-google {
  color: #7d1e19;
  background-color: #fbc8c5; }
  .list-group-item-google.list-group-item-action:hover, .list-group-item-google.list-group-item-action:focus {
    color: #7d1e19;
    background-color: #f9b1ad; }
  .list-group-item-google.list-group-item-action.active {
    color: #fff;
    background-color: #7d1e19;
    border-color: #7d1e19; }

.list-group-item-pinterest {
  color: #6e1818;
  background-color: #f3c5c5; }
  .list-group-item-pinterest.list-group-item-action:hover, .list-group-item-pinterest.list-group-item-action:focus {
    color: #6e1818;
    background-color: #efb0b0; }
  .list-group-item-pinterest.list-group-item-action.active {
    color: #fff;
    background-color: #6e1818;
    border-color: #6e1818; }

.list-group-item-instagram {
  color: #493933;
  background-color: #dfd6d3; }
  .list-group-item-instagram.list-group-item-action:hover, .list-group-item-instagram.list-group-item-action:focus {
    color: #493933;
    background-color: #d4c8c4; }
  .list-group-item-instagram.list-group-item-action.active {
    color: #fff;
    background-color: #493933;
    border-color: #493933; }

.list-group-item-rss {
  color: #854f00;
  background-color: #ffe2b8; }
  .list-group-item-rss.list-group-item-action:hover, .list-group-item-rss.list-group-item-action:focus {
    color: #854f00;
    background-color: #ffd89f; }
  .list-group-item-rss.list-group-item-action.active {
    color: #fff;
    background-color: #854f00;
    border-color: #854f00; }

.list-group-item-tumblr {
  color: #324148;
  background-color: #d2dbdf; }
  .list-group-item-tumblr.list-group-item-action:hover, .list-group-item-tumblr.list-group-item-action:focus {
    color: #324148;
    background-color: #c3cfd4; }
  .list-group-item-tumblr.list-group-item-action.active {
    color: #fff;
    background-color: #324148;
    border-color: #324148; }

.list-group-item-linkedin {
  color: #253d5e;
  background-color: #ccd8ea; }
  .list-group-item-linkedin.list-group-item-action:hover, .list-group-item-linkedin.list-group-item-action:focus {
    color: #253d5e;
    background-color: #bacae3; }
  .list-group-item-linkedin.list-group-item-action.active {
    color: #fff;
    background-color: #253d5e;
    border-color: #253d5e; }

.list-group-item-dribbble {
  color: #7d334c;
  background-color: #fbd3e0; }
  .list-group-item-dribbble.list-group-item-action:hover, .list-group-item-dribbble.list-group-item-action:focus {
    color: #7d334c;
    background-color: #f9bcd0; }
  .list-group-item-dribbble.list-group-item-action.active {
    color: #fff;
    background-color: #7d334c;
    border-color: #7d334c; }

.list-group-item-youtube {
  color: #6f0000;
  background-color: #f3b8b8; }
  .list-group-item-youtube.list-group-item-action:hover, .list-group-item-youtube.list-group-item-action:focus {
    color: #6f0000;
    background-color: #efa2a2; }
  .list-group-item-youtube.list-group-item-action.active {
    color: #fff;
    background-color: #6f0000;
    border-color: #6f0000; }

.list-group-item-github {
  color: #222222;
  background-color: #cacaca; }
  .list-group-item-github.list-group-item-action:hover, .list-group-item-github.list-group-item-action:focus {
    color: #222222;
    background-color: #bdbdbd; }
  .list-group-item-github.list-group-item-action.active {
    color: #fff;
    background-color: #222222;
    border-color: #222222; }

.list-group-item-skype {
  color: #296580;
  background-color: #ceeefd; }
  .list-group-item-skype.list-group-item-action:hover, .list-group-item-skype.list-group-item-action:focus {
    color: #296580;
    background-color: #b6e6fc; }
  .list-group-item-skype.list-group-item-action.active {
    color: #fff;
    background-color: #296580;
    border-color: #296580; }

.list-group-item-orange {
  color: #6e3804;
  background-color: #f3d6ba; }
  .list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
    color: #6e3804;
    background-color: #efc9a4; }
  .list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #6e3804;
    border-color: #6e3804; }

.list-group-item-purple {
  color: #2d2964;
  background-color: #d0ceee; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #2d2964;
    background-color: #bebbe7; }
  .list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #2d2964;
    border-color: #2d2964; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #F4F7FA;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #F4F7FA; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Heebo", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #464D69; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-next {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.001)); }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #5D92F4 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2d72f1 !important; }

.bg-secondary {
  background-color: #677080 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #505764 !important; }

.bg-success {
  background-color: #00D014 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #009d0f !important; }

.bg-info {
  background-color: #00D0BD !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #009d8f !important; }

.bg-warning {
  background-color: #FFB70F !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #db9900 !important; }

.bg-danger {
  background-color: #FF3739 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ff0407 !important; }

.bg-light {
  background-color: #FAFAFA !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e1e1e1 !important; }

.bg-dark {
  background-color: #464D69 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #32374a !important; }

.bg-light-yellow {
  background-color: #FFF59D !important; }

a.bg-light-yellow:hover, a.bg-light-yellow:focus,
button.bg-light-yellow:hover,
button.bg-light-yellow:focus {
  background-color: #fff06a !important; }

.bg-twitter {
  background-color: #52AEFF !important; }

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #1f96ff !important; }

.bg-facebook {
  background-color: #3B5998 !important; }

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #2d4373 !important; }

.bg-google {
  background-color: #F13A30 !important; }

a.bg-google:hover, a.bg-google:focus,
button.bg-google:hover,
button.bg-google:focus {
  background-color: #df1a0f !important; }

.bg-pinterest {
  background-color: #d32f2f !important; }

a.bg-pinterest:hover, a.bg-pinterest:focus,
button.bg-pinterest:hover,
button.bg-pinterest:focus {
  background-color: #ab2424 !important; }

.bg-instagram {
  background-color: #8d6e63 !important; }

a.bg-instagram:hover, a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
  background-color: #6f574e !important; }

.bg-rss {
  background-color: #ff9800 !important; }

a.bg-rss:hover, a.bg-rss:focus,
button.bg-rss:hover,
button.bg-rss:focus {
  background-color: #cc7a00 !important; }

.bg-tumblr {
  background-color: #607d8b !important; }

a.bg-tumblr:hover, a.bg-tumblr:focus,
button.bg-tumblr:hover,
button.bg-tumblr:focus {
  background-color: #4b626d !important; }

.bg-linkedin {
  background-color: #4875B4 !important; }

a.bg-linkedin:hover, a.bg-linkedin:focus,
button.bg-linkedin:hover,
button.bg-linkedin:focus {
  background-color: #395d90 !important; }

.bg-dribbble {
  background-color: #f06292 !important; }

a.bg-dribbble:hover, a.bg-dribbble:focus,
button.bg-dribbble:hover,
button.bg-dribbble:focus {
  background-color: #ec3372 !important; }

.bg-youtube {
  background-color: #d50000 !important; }

a.bg-youtube:hover, a.bg-youtube:focus,
button.bg-youtube:hover,
button.bg-youtube:focus {
  background-color: #a20000 !important; }

.bg-github {
  background-color: #424242 !important; }

a.bg-github:hover, a.bg-github:focus,
button.bg-github:hover,
button.bg-github:focus {
  background-color: #292929 !important; }

.bg-skype {
  background-color: #4fc3f7 !important; }

a.bg-skype:hover, a.bg-skype:focus,
button.bg-skype:hover,
button.bg-skype:focus {
  background-color: #1eb2f5 !important; }

.bg-orange {
  background-color: #D46B08 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #a35206 !important; }

.bg-purple {
  background-color: #564fc1 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #403aa3 !important; }

.bg-gradient-primary {
  background: #5D92F4 linear-gradient(180deg, #74a1f5, #5D92F4) repeat-x !important; }

.bg-gradient-secondary {
  background: #677080 linear-gradient(180deg, #7c8492, #677080) repeat-x !important; }

.bg-gradient-success {
  background: #00D014 linear-gradient(180deg, #25d637, #00D014) repeat-x !important; }

.bg-gradient-info {
  background: #00D0BD linear-gradient(180deg, #25d6c6, #00D0BD) repeat-x !important; }

.bg-gradient-warning {
  background: #FFB70F linear-gradient(180deg, #fdc132, #FFB70F) repeat-x !important; }

.bg-gradient-danger {
  background: #FF3739 linear-gradient(180deg, #fd5456, #FF3739) repeat-x !important; }

.bg-gradient-light {
  background: #FAFAFA linear-gradient(180deg, #f9fafa, #FAFAFA) repeat-x !important; }

.bg-gradient-dark {
  background: #464D69 linear-gradient(180deg, #60677f, #464D69) repeat-x !important; }

.bg-gradient-light-yellow {
  background: #FFF59D linear-gradient(180deg, #fdf5ab, #FFF59D) repeat-x !important; }

.bg-gradient-twitter {
  background: #52AEFF linear-gradient(180deg, #6ab9fe, #52AEFF) repeat-x !important; }

.bg-gradient-facebook {
  background: #3B5998 linear-gradient(180deg, #5771a7, #3B5998) repeat-x !important; }

.bg-gradient-google {
  background: #F13A30 linear-gradient(180deg, #f1564e, #F13A30) repeat-x !important; }

.bg-gradient-pinterest {
  background: #d32f2f linear-gradient(180deg, #d84d4d, #d32f2f) repeat-x !important; }

.bg-gradient-instagram {
  background: #8d6e63 linear-gradient(180deg, #9c837a, #8d6e63) repeat-x !important; }

.bg-gradient-rss {
  background: #ff9800 linear-gradient(180deg, #fda626, #ff9800) repeat-x !important; }

.bg-gradient-tumblr {
  background: #607d8b linear-gradient(180deg, #768f9c, #607d8b) repeat-x !important; }

.bg-gradient-linkedin {
  background: #4875B4 linear-gradient(180deg, #6289bf, #4875B4) repeat-x !important; }

.bg-gradient-dribbble {
  background: #f06292 linear-gradient(180deg, #f178a2, #f06292) repeat-x !important; }

.bg-gradient-youtube {
  background: #d50000 linear-gradient(180deg, #da2526, #d50000) repeat-x !important; }

.bg-gradient-github {
  background: #424242 linear-gradient(180deg, #5d5d5e, #424242) repeat-x !important; }

.bg-gradient-skype {
  background: #4fc3f7 linear-gradient(180deg, #68cbf7, #4fc3f7) repeat-x !important; }

.bg-gradient-orange {
  background: #D46B08 linear-gradient(180deg, #d9802c, #D46B08) repeat-x !important; }

.bg-gradient-purple {
  background: #564fc1 linear-gradient(180deg, #6e68ca, #564fc1) repeat-x !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #EBEDF2 !important; }

.border-top {
  border-top: 1px solid #EBEDF2 !important; }

.border-right {
  border-right: 1px solid #EBEDF2 !important; }

.border-bottom {
  border-bottom: 1px solid #EBEDF2 !important; }

.border-left {
  border-left: 1px solid #EBEDF2 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5D92F4 !important; }

.border-secondary {
  border-color: #677080 !important; }

.border-success {
  border-color: #00D014 !important; }

.border-info {
  border-color: #00D0BD !important; }

.border-warning {
  border-color: #FFB70F !important; }

.border-danger {
  border-color: #FF3739 !important; }

.border-light {
  border-color: #FAFAFA !important; }

.border-dark {
  border-color: #464D69 !important; }

.border-light-yellow {
  border-color: #FFF59D !important; }

.border-twitter {
  border-color: #52AEFF !important; }

.border-facebook {
  border-color: #3B5998 !important; }

.border-google {
  border-color: #F13A30 !important; }

.border-pinterest {
  border-color: #d32f2f !important; }

.border-instagram {
  border-color: #8d6e63 !important; }

.border-rss {
  border-color: #ff9800 !important; }

.border-tumblr {
  border-color: #607d8b !important; }

.border-linkedin {
  border-color: #4875B4 !important; }

.border-dribbble {
  border-color: #f06292 !important; }

.border-youtube {
  border-color: #d50000 !important; }

.border-github {
  border-color: #424242 !important; }

.border-skype {
  border-color: #4fc3f7 !important; }

.border-orange {
  border-color: #D46B08 !important; }

.border-purple {
  border-color: #564fc1 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 0.3125rem !important; }

.mt-5,
.my-5 {
  margin-top: 0.3125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 0.3125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 0.3125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 0.3125rem !important; }

.m-10 {
  margin: 0.625rem !important; }

.mt-10,
.my-10 {
  margin-top: 0.625rem !important; }

.mr-10,
.mx-10 {
  margin-right: 0.625rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 0.625rem !important; }

.ml-10,
.mx-10 {
  margin-left: 0.625rem !important; }

.m-15 {
  margin: 0.9375rem !important; }

.mt-15,
.my-15 {
  margin-top: 0.9375rem !important; }

.mr-15,
.mx-15 {
  margin-right: 0.9375rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 0.9375rem !important; }

.ml-15,
.mx-15 {
  margin-left: 0.9375rem !important; }

.m-20 {
  margin: 1.25rem !important; }

.mt-20,
.my-20 {
  margin-top: 1.25rem !important; }

.mr-20,
.mx-20 {
  margin-right: 1.25rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 1.25rem !important; }

.ml-20,
.mx-20 {
  margin-left: 1.25rem !important; }

.m-25 {
  margin: 1.563rem !important; }

.mt-25,
.my-25 {
  margin-top: 1.563rem !important; }

.mr-25,
.mx-25 {
  margin-right: 1.563rem !important; }

.mb-25,
.my-25 {
  margin-bottom: 1.563rem !important; }

.ml-25,
.mx-25 {
  margin-left: 1.563rem !important; }

.m-30 {
  margin: 1.875rem !important; }

.mt-30,
.my-30 {
  margin-top: 1.875rem !important; }

.mr-30,
.mx-30 {
  margin-right: 1.875rem !important; }

.mb-30,
.my-30 {
  margin-bottom: 1.875rem !important; }

.ml-30,
.mx-30 {
  margin-left: 1.875rem !important; }

.m-40 {
  margin: 2.5rem !important; }

.mt-40,
.my-40 {
  margin-top: 2.5rem !important; }

.mr-40,
.mx-40 {
  margin-right: 2.5rem !important; }

.mb-40,
.my-40 {
  margin-bottom: 2.5rem !important; }

.ml-40,
.mx-40 {
  margin-left: 2.5rem !important; }

.m-50 {
  margin: 3.125rem !important; }

.mt-50,
.my-50 {
  margin-top: 3.125rem !important; }

.mr-50,
.mx-50 {
  margin-right: 3.125rem !important; }

.mb-50,
.my-50 {
  margin-bottom: 3.125rem !important; }

.ml-50,
.mx-50 {
  margin-left: 3.125rem !important; }

.m-60 {
  margin: 3.75rem !important; }

.mt-60,
.my-60 {
  margin-top: 3.75rem !important; }

.mr-60,
.mx-60 {
  margin-right: 3.75rem !important; }

.mb-60,
.my-60 {
  margin-bottom: 3.75rem !important; }

.ml-60,
.mx-60 {
  margin-left: 3.75rem !important; }

.m-70 {
  margin: 4.375rem !important; }

.mt-70,
.my-70 {
  margin-top: 4.375rem !important; }

.mr-70,
.mx-70 {
  margin-right: 4.375rem !important; }

.mb-70,
.my-70 {
  margin-bottom: 4.375rem !important; }

.ml-70,
.mx-70 {
  margin-left: 4.375rem !important; }

.p-0, .square-10, .square-15, .square-20, .square-30, .square-40, .size-40, .square-50, .square-60, .size-60, .square-70, .square-80, .size-80, .square-100, .size-100, .square-120, .size-120 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 0.3125rem !important; }

.pt-5,
.py-5 {
  padding-top: 0.3125rem !important; }

.pr-5,
.px-5 {
  padding-right: 0.3125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 0.3125rem !important; }

.pl-5,
.px-5 {
  padding-left: 0.3125rem !important; }

.p-10 {
  padding: 0.625rem !important; }

.pt-10,
.py-10 {
  padding-top: 0.625rem !important; }

.pr-10,
.px-10 {
  padding-right: 0.625rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 0.625rem !important; }

.pl-10,
.px-10 {
  padding-left: 0.625rem !important; }

.p-15 {
  padding: 0.9375rem !important; }

.pt-15,
.py-15 {
  padding-top: 0.9375rem !important; }

.pr-15,
.px-15 {
  padding-right: 0.9375rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 0.9375rem !important; }

.pl-15,
.px-15 {
  padding-left: 0.9375rem !important; }

.p-20 {
  padding: 1.25rem !important; }

.pt-20,
.py-20 {
  padding-top: 1.25rem !important; }

.pr-20,
.px-20 {
  padding-right: 1.25rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 1.25rem !important; }

.pl-20,
.px-20 {
  padding-left: 1.25rem !important; }

.p-25 {
  padding: 1.563rem !important; }

.pt-25,
.py-25 {
  padding-top: 1.563rem !important; }

.pr-25,
.px-25 {
  padding-right: 1.563rem !important; }

.pb-25,
.py-25 {
  padding-bottom: 1.563rem !important; }

.pl-25,
.px-25 {
  padding-left: 1.563rem !important; }

.p-30 {
  padding: 1.875rem !important; }

.pt-30,
.py-30 {
  padding-top: 1.875rem !important; }

.pr-30,
.px-30 {
  padding-right: 1.875rem !important; }

.pb-30,
.py-30 {
  padding-bottom: 1.875rem !important; }

.pl-30,
.px-30 {
  padding-left: 1.875rem !important; }

.p-40 {
  padding: 2.5rem !important; }

.pt-40,
.py-40 {
  padding-top: 2.5rem !important; }

.pr-40,
.px-40 {
  padding-right: 2.5rem !important; }

.pb-40,
.py-40 {
  padding-bottom: 2.5rem !important; }

.pl-40,
.px-40 {
  padding-left: 2.5rem !important; }

.p-50 {
  padding: 3.125rem !important; }

.pt-50,
.py-50 {
  padding-top: 3.125rem !important; }

.pr-50,
.px-50 {
  padding-right: 3.125rem !important; }

.pb-50,
.py-50 {
  padding-bottom: 3.125rem !important; }

.pl-50,
.px-50 {
  padding-left: 3.125rem !important; }

.p-60 {
  padding: 3.75rem !important; }

.pt-60,
.py-60 {
  padding-top: 3.75rem !important; }

.pr-60,
.px-60 {
  padding-right: 3.75rem !important; }

.pb-60,
.py-60 {
  padding-bottom: 3.75rem !important; }

.pl-60,
.px-60 {
  padding-left: 3.75rem !important; }

.p-70 {
  padding: 4.375rem !important; }

.pt-70,
.py-70 {
  padding-top: 4.375rem !important; }

.pr-70,
.px-70 {
  padding-right: 4.375rem !important; }

.pb-70,
.py-70 {
  padding-bottom: 4.375rem !important; }

.pl-70,
.px-70 {
  padding-left: 4.375rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 0.3125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 0.3125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 0.3125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 0.3125rem !important; }
  .m-sm-10 {
    margin: 0.625rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 0.625rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 0.625rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 0.625rem !important; }
  .m-sm-15 {
    margin: 0.9375rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 0.9375rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 0.9375rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 0.9375rem !important; }
  .m-sm-20 {
    margin: 1.25rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 1.25rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 1.25rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 1.25rem !important; }
  .m-sm-25 {
    margin: 1.563rem !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 1.563rem !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 1.563rem !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 1.563rem !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 1.563rem !important; }
  .m-sm-30 {
    margin: 1.875rem !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 1.875rem !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 1.875rem !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 1.875rem !important; }
  .m-sm-40 {
    margin: 2.5rem !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 2.5rem !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 2.5rem !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 2.5rem !important; }
  .m-sm-50 {
    margin: 3.125rem !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 3.125rem !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 3.125rem !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 3.125rem !important; }
  .m-sm-60 {
    margin: 3.75rem !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 3.75rem !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 3.75rem !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 3.75rem !important; }
  .m-sm-70 {
    margin: 4.375rem !important; }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 4.375rem !important; }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 4.375rem !important; }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 4.375rem !important; }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 4.375rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 0.3125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 0.3125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 0.3125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 0.3125rem !important; }
  .p-sm-10 {
    padding: 0.625rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 0.625rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 0.625rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 0.625rem !important; }
  .p-sm-15 {
    padding: 0.9375rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 0.9375rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 0.9375rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 0.9375rem !important; }
  .p-sm-20 {
    padding: 1.25rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 1.25rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 1.25rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 1.25rem !important; }
  .p-sm-25 {
    padding: 1.563rem !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 1.563rem !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 1.563rem !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 1.563rem !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 1.563rem !important; }
  .p-sm-30 {
    padding: 1.875rem !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 1.875rem !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 1.875rem !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 1.875rem !important; }
  .p-sm-40 {
    padding: 2.5rem !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 2.5rem !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 2.5rem !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 2.5rem !important; }
  .p-sm-50 {
    padding: 3.125rem !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 3.125rem !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 3.125rem !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 3.125rem !important; }
  .p-sm-60 {
    padding: 3.75rem !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 3.75rem !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 3.75rem !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 3.75rem !important; }
  .p-sm-70 {
    padding: 4.375rem !important; }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 4.375rem !important; }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 4.375rem !important; }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 4.375rem !important; }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 4.375rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 0.3125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 0.3125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 0.3125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 0.3125rem !important; }
  .m-md-10 {
    margin: 0.625rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 0.625rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 0.625rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 0.625rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 0.625rem !important; }
  .m-md-15 {
    margin: 0.9375rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 0.9375rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 0.9375rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 0.9375rem !important; }
  .m-md-20 {
    margin: 1.25rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 1.25rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 1.25rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 1.25rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 1.25rem !important; }
  .m-md-25 {
    margin: 1.563rem !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 1.563rem !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 1.563rem !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 1.563rem !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 1.563rem !important; }
  .m-md-30 {
    margin: 1.875rem !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 1.875rem !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 1.875rem !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 1.875rem !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 1.875rem !important; }
  .m-md-40 {
    margin: 2.5rem !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 2.5rem !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 2.5rem !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 2.5rem !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 2.5rem !important; }
  .m-md-50 {
    margin: 3.125rem !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 3.125rem !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 3.125rem !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 3.125rem !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 3.125rem !important; }
  .m-md-60 {
    margin: 3.75rem !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 3.75rem !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 3.75rem !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 3.75rem !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 3.75rem !important; }
  .m-md-70 {
    margin: 4.375rem !important; }
  .mt-md-70,
  .my-md-70 {
    margin-top: 4.375rem !important; }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 4.375rem !important; }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 4.375rem !important; }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 4.375rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 0.3125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 0.3125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0.3125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0.3125rem !important; }
  .p-md-10 {
    padding: 0.625rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 0.625rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 0.625rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 0.625rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 0.625rem !important; }
  .p-md-15 {
    padding: 0.9375rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 0.9375rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 0.9375rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 0.9375rem !important; }
  .p-md-20 {
    padding: 1.25rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 1.25rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 1.25rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 1.25rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 1.25rem !important; }
  .p-md-25 {
    padding: 1.563rem !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 1.563rem !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 1.563rem !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 1.563rem !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 1.563rem !important; }
  .p-md-30 {
    padding: 1.875rem !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 1.875rem !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 1.875rem !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 1.875rem !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 1.875rem !important; }
  .p-md-40 {
    padding: 2.5rem !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 2.5rem !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 2.5rem !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 2.5rem !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 2.5rem !important; }
  .p-md-50 {
    padding: 3.125rem !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 3.125rem !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 3.125rem !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 3.125rem !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 3.125rem !important; }
  .p-md-60 {
    padding: 3.75rem !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 3.75rem !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 3.75rem !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 3.75rem !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 3.75rem !important; }
  .p-md-70 {
    padding: 4.375rem !important; }
  .pt-md-70,
  .py-md-70 {
    padding-top: 4.375rem !important; }
  .pr-md-70,
  .px-md-70 {
    padding-right: 4.375rem !important; }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 4.375rem !important; }
  .pl-md-70,
  .px-md-70 {
    padding-left: 4.375rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 0.3125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 0.3125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 0.3125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 0.3125rem !important; }
  .m-lg-10 {
    margin: 0.625rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 0.625rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 0.625rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 0.625rem !important; }
  .m-lg-15 {
    margin: 0.9375rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 0.9375rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 0.9375rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 0.9375rem !important; }
  .m-lg-20 {
    margin: 1.25rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 1.25rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 1.25rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 1.25rem !important; }
  .m-lg-25 {
    margin: 1.563rem !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 1.563rem !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 1.563rem !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 1.563rem !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 1.563rem !important; }
  .m-lg-30 {
    margin: 1.875rem !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 1.875rem !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 1.875rem !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 1.875rem !important; }
  .m-lg-40 {
    margin: 2.5rem !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 2.5rem !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 2.5rem !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 2.5rem !important; }
  .m-lg-50 {
    margin: 3.125rem !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 3.125rem !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 3.125rem !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 3.125rem !important; }
  .m-lg-60 {
    margin: 3.75rem !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 3.75rem !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 3.75rem !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 3.75rem !important; }
  .m-lg-70 {
    margin: 4.375rem !important; }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 4.375rem !important; }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 4.375rem !important; }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 4.375rem !important; }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 4.375rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 0.3125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 0.3125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 0.3125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 0.3125rem !important; }
  .p-lg-10 {
    padding: 0.625rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 0.625rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 0.625rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 0.625rem !important; }
  .p-lg-15 {
    padding: 0.9375rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 0.9375rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 0.9375rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 0.9375rem !important; }
  .p-lg-20 {
    padding: 1.25rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 1.25rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 1.25rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 1.25rem !important; }
  .p-lg-25 {
    padding: 1.563rem !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 1.563rem !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 1.563rem !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 1.563rem !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 1.563rem !important; }
  .p-lg-30 {
    padding: 1.875rem !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 1.875rem !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 1.875rem !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 1.875rem !important; }
  .p-lg-40 {
    padding: 2.5rem !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 2.5rem !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 2.5rem !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 2.5rem !important; }
  .p-lg-50 {
    padding: 3.125rem !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 3.125rem !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 3.125rem !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 3.125rem !important; }
  .p-lg-60 {
    padding: 3.75rem !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 3.75rem !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 3.75rem !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 3.75rem !important; }
  .p-lg-70 {
    padding: 4.375rem !important; }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 4.375rem !important; }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 4.375rem !important; }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 4.375rem !important; }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 4.375rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 0.3125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 0.3125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 0.3125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 0.3125rem !important; }
  .m-xl-10 {
    margin: 0.625rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 0.625rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 0.625rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 0.625rem !important; }
  .m-xl-15 {
    margin: 0.9375rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 0.9375rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 0.9375rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 0.9375rem !important; }
  .m-xl-20 {
    margin: 1.25rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 1.25rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 1.25rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 1.25rem !important; }
  .m-xl-25 {
    margin: 1.563rem !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 1.563rem !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 1.563rem !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 1.563rem !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 1.563rem !important; }
  .m-xl-30 {
    margin: 1.875rem !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 1.875rem !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 1.875rem !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 1.875rem !important; }
  .m-xl-40 {
    margin: 2.5rem !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 2.5rem !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 2.5rem !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 2.5rem !important; }
  .m-xl-50 {
    margin: 3.125rem !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 3.125rem !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 3.125rem !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 3.125rem !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 3.125rem !important; }
  .m-xl-60 {
    margin: 3.75rem !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 3.75rem !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 3.75rem !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 3.75rem !important; }
  .m-xl-70 {
    margin: 4.375rem !important; }
  .mt-xl-70,
  .my-xl-70 {
    margin-top: 4.375rem !important; }
  .mr-xl-70,
  .mx-xl-70 {
    margin-right: 4.375rem !important; }
  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 4.375rem !important; }
  .ml-xl-70,
  .mx-xl-70 {
    margin-left: 4.375rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 0.3125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 0.3125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 0.3125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 0.3125rem !important; }
  .p-xl-10 {
    padding: 0.625rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 0.625rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 0.625rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 0.625rem !important; }
  .p-xl-15 {
    padding: 0.9375rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 0.9375rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 0.9375rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 0.9375rem !important; }
  .p-xl-20 {
    padding: 1.25rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 1.25rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 1.25rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 1.25rem !important; }
  .p-xl-25 {
    padding: 1.563rem !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 1.563rem !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 1.563rem !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 1.563rem !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 1.563rem !important; }
  .p-xl-30 {
    padding: 1.875rem !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 1.875rem !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 1.875rem !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 1.875rem !important; }
  .p-xl-40 {
    padding: 2.5rem !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 2.5rem !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 2.5rem !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 2.5rem !important; }
  .p-xl-50 {
    padding: 3.125rem !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 3.125rem !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 3.125rem !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 3.125rem !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 3.125rem !important; }
  .p-xl-60 {
    padding: 3.75rem !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 3.75rem !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 3.75rem !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 3.75rem !important; }
  .p-xl-70 {
    padding: 4.375rem !important; }
  .pt-xl-70,
  .py-xl-70 {
    padding-top: 4.375rem !important; }
  .pr-xl-70,
  .px-xl-70 {
    padding-right: 4.375rem !important; }
  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 4.375rem !important; }
  .pl-xl-70,
  .px-xl-70 {
    padding-left: 4.375rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #5D92F4 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #2d72f1 !important; }

.text-secondary {
  color: #677080 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #505764 !important; }

.text-success {
  color: #00D014 !important; }

a.text-success:hover, a.text-success:focus {
  color: #009d0f !important; }

.text-info {
  color: #00D0BD !important; }

a.text-info:hover, a.text-info:focus {
  color: #009d8f !important; }

.text-warning {
  color: #FFB70F !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #db9900 !important; }

.text-danger {
  color: #FF3739 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ff0407 !important; }

.text-light {
  color: #FAFAFA !important; }

a.text-light:hover, a.text-light:focus {
  color: #e1e1e1 !important; }

.text-dark {
  color: #464D69 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #32374a !important; }

.text-light-yellow {
  color: #FFF59D !important; }

a.text-light-yellow:hover, a.text-light-yellow:focus {
  color: #fff06a !important; }

.text-twitter {
  color: #52AEFF !important; }

a.text-twitter:hover, a.text-twitter:focus {
  color: #1f96ff !important; }

.text-facebook {
  color: #3B5998 !important; }

a.text-facebook:hover, a.text-facebook:focus {
  color: #2d4373 !important; }

.text-google {
  color: #F13A30 !important; }

a.text-google:hover, a.text-google:focus {
  color: #df1a0f !important; }

.text-pinterest {
  color: #d32f2f !important; }

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #ab2424 !important; }

.text-instagram {
  color: #8d6e63 !important; }

a.text-instagram:hover, a.text-instagram:focus {
  color: #6f574e !important; }

.text-rss {
  color: #ff9800 !important; }

a.text-rss:hover, a.text-rss:focus {
  color: #cc7a00 !important; }

.text-tumblr {
  color: #607d8b !important; }

a.text-tumblr:hover, a.text-tumblr:focus {
  color: #4b626d !important; }

.text-linkedin {
  color: #4875B4 !important; }

a.text-linkedin:hover, a.text-linkedin:focus {
  color: #395d90 !important; }

.text-dribbble {
  color: #f06292 !important; }

a.text-dribbble:hover, a.text-dribbble:focus {
  color: #ec3372 !important; }

.text-youtube {
  color: #d50000 !important; }

a.text-youtube:hover, a.text-youtube:focus {
  color: #a20000 !important; }

.text-github {
  color: #424242 !important; }

a.text-github:hover, a.text-github:focus {
  color: #292929 !important; }

.text-skype {
  color: #4fc3f7 !important; }

a.text-skype:hover, a.text-skype:focus {
  color: #1eb2f5 !important; }

.text-orange {
  color: #D46B08 !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #a35206 !important; }

.text-purple {
  color: #564fc1 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #403aa3 !important; }

.text-muted {
  color: #727891 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

.rtl {
  direction: rtl;
  text-align: right; }
  .rtl .nav {
    padding-right: 0; }
  .rtl .navbar-nav .nav-item {
    float: right; }
  .rtl .navbar-nav .nav-item + .nav-item {
    margin-right: 1rem;
    margin-left: inherit; }
  .rtl th {
    text-align: right; }
  .rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem; }
  .rtl .dropdown-menu {
    right: 0;
    text-align: right; }
  .rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.25rem 0.25rem 0; }
  .rtl .btn-group > .btn:last-child:not(:first-child),
  .rtl .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .custom-control-label::after,
  .rtl .custom-control-label::before {
    right: 0;
    left: inherit; }
  .rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23677080' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat left 0.75rem center;
    background-size: 8px 10px; }
  .rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0 0.25rem 0.25rem 0; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .input-group > .custom-select:not(:first-child),
  .rtl .input-group > .form-control:not(:first-child) {
    border-radius: 0.25rem 0 0 0.25rem; }
  .rtl .input-group > .custom-select:not(:last-child),
  .rtl .input-group > .form-control:not(:last-child) {
    border-radius: 0 0.25rem 0.25rem 0; }
  .rtl .input-group > .custom-select:not(:last-child):not(:first-child),
  .rtl .input-group > .form-control:not(:last-child):not(:first-child) {
    border-radius: 0; }
  .rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem; }
  .rtl .custom-control-indicator {
    right: 0;
    left: inherit; }
  .rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem; }
  .rtl .radio input,
  .rtl .radio-inline,
  .rtl .checkbox input,
  .rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .list-group {
    padding-right: 0;
    padding-left: 40px; }
  .rtl .close {
    float: left; }
  .rtl .modal-header .close {
    margin: -15px auto -15px -15px; }
  .rtl .modal-footer > :not(:first-child) {
    margin-right: .25rem; }
  .rtl .alert-dismissible .close {
    right: inherit;
    left: 0; }
  .rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0; }
  .rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .offset-1 {
    margin-right: 8.33333%;
    margin-left: 0; }
  .rtl .offset-2 {
    margin-right: 16.66667%;
    margin-left: 0; }
  .rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-4 {
    margin-right: 33.33333%;
    margin-left: 0; }
  .rtl .offset-5 {
    margin-right: 41.66667%;
    margin-left: 0; }
  .rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-7 {
    margin-right: 58.33333%;
    margin-left: 0; }
  .rtl .offset-8 {
    margin-right: 66.66667%;
    margin-left: 0; }
  .rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-10 {
    margin-right: 83.33333%;
    margin-left: 0; }
  .rtl .offset-11 {
    margin-right: 91.66667%;
    margin-left: 0; }
  @media (min-width: 576px) {
    .rtl .offset-sm-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-sm-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-sm-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-sm-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-sm-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-sm-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-sm-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-sm-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-sm-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-sm-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-sm-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-sm-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 768px) {
    .rtl .offset-md-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-md-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-md-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-md-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-md-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-md-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-md-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-md-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-md-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-md-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-md-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-md-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 992px) {
    .rtl .offset-lg-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-lg-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-lg-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-lg-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-lg-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-lg-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-lg-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-lg-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-lg-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-lg-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-lg-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .rtl .offset-xl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xl-1 {
      margin-right: 8.33333%;
      margin-left: 0; }
    .rtl .offset-xl-2 {
      margin-right: 16.66667%;
      margin-left: 0; }
    .rtl .offset-xl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xl-4 {
      margin-right: 33.33333%;
      margin-left: 0; }
    .rtl .offset-xl-5 {
      margin-right: 41.66667%;
      margin-left: 0; }
    .rtl .offset-xl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xl-7 {
      margin-right: 58.33333%;
      margin-left: 0; }
    .rtl .offset-xl-8 {
      margin-right: 66.66667%;
      margin-left: 0; }
    .rtl .offset-xl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xl-10 {
      margin-right: 83.33333%;
      margin-left: 0; }
    .rtl .offset-xl-11 {
      margin-right: 91.66667%;
      margin-left: 0; } }
  .rtl .mr-0,
  .rtl .mx-0 {
    margin-left: 0 !important; }
  .rtl .ml-0,
  .rtl .mx-0 {
    margin-right: 0 !important; }
  .rtl .mr-1,
  .rtl .mx-1 {
    margin-left: 0.25rem !important; }
  .rtl .ml-1,
  .rtl .mx-1 {
    margin-right: 0.25rem !important; }
  .rtl .mr-2,
  .rtl .mx-2 {
    margin-left: 0.5rem !important; }
  .rtl .ml-2,
  .rtl .mx-2 {
    margin-right: 0.5rem !important; }
  .rtl .mr-3,
  .rtl .mx-3 {
    margin-left: 1rem !important; }
  .rtl .ml-3,
  .rtl .mx-3 {
    margin-right: 1rem !important; }
  .rtl .mr-4,
  .rtl .mx-4 {
    margin-left: 1.5rem !important; }
  .rtl .ml-4,
  .rtl .mx-4 {
    margin-right: 1.5rem !important; }
  .rtl .mr-5,
  .rtl .mx-5 {
    margin-left: 0.3125rem !important; }
  .rtl .ml-5,
  .rtl .mx-5 {
    margin-right: 0.3125rem !important; }
  .rtl .mr-10,
  .rtl .mx-10 {
    margin-left: 0.625rem !important; }
  .rtl .ml-10,
  .rtl .mx-10 {
    margin-right: 0.625rem !important; }
  .rtl .mr-15,
  .rtl .mx-15 {
    margin-left: 0.9375rem !important; }
  .rtl .ml-15,
  .rtl .mx-15 {
    margin-right: 0.9375rem !important; }
  .rtl .mr-20,
  .rtl .mx-20 {
    margin-left: 1.25rem !important; }
  .rtl .ml-20,
  .rtl .mx-20 {
    margin-right: 1.25rem !important; }
  .rtl .mr-25,
  .rtl .mx-25 {
    margin-left: 1.563rem !important; }
  .rtl .ml-25,
  .rtl .mx-25 {
    margin-right: 1.563rem !important; }
  .rtl .mr-30,
  .rtl .mx-30 {
    margin-left: 1.875rem !important; }
  .rtl .ml-30,
  .rtl .mx-30 {
    margin-right: 1.875rem !important; }
  .rtl .mr-40,
  .rtl .mx-40 {
    margin-left: 2.5rem !important; }
  .rtl .ml-40,
  .rtl .mx-40 {
    margin-right: 2.5rem !important; }
  .rtl .mr-50,
  .rtl .mx-50 {
    margin-left: 3.125rem !important; }
  .rtl .ml-50,
  .rtl .mx-50 {
    margin-right: 3.125rem !important; }
  .rtl .mr-60,
  .rtl .mx-60 {
    margin-left: 3.75rem !important; }
  .rtl .ml-60,
  .rtl .mx-60 {
    margin-right: 3.75rem !important; }
  .rtl .mr-70,
  .rtl .mx-70 {
    margin-left: 4.375rem !important; }
  .rtl .ml-70,
  .rtl .mx-70 {
    margin-right: 4.375rem !important; }
  .rtl .pr-0,
  .rtl .px-0 {
    padding-left: 0 !important; }
  .rtl .pl-0,
  .rtl .px-0 {
    padding-right: 0 !important; }
  .rtl .pr-1,
  .rtl .px-1 {
    padding-left: 0.25rem !important; }
  .rtl .pl-1,
  .rtl .px-1 {
    padding-right: 0.25rem !important; }
  .rtl .pr-2,
  .rtl .px-2 {
    padding-left: 0.5rem !important; }
  .rtl .pl-2,
  .rtl .px-2 {
    padding-right: 0.5rem !important; }
  .rtl .pr-3,
  .rtl .px-3 {
    padding-left: 1rem !important; }
  .rtl .pl-3,
  .rtl .px-3 {
    padding-right: 1rem !important; }
  .rtl .pr-4,
  .rtl .px-4 {
    padding-left: 1.5rem !important; }
  .rtl .pl-4,
  .rtl .px-4 {
    padding-right: 1.5rem !important; }
  .rtl .pr-5,
  .rtl .px-5 {
    padding-left: 0.3125rem !important; }
  .rtl .pl-5,
  .rtl .px-5 {
    padding-right: 0.3125rem !important; }
  .rtl .pr-10,
  .rtl .px-10 {
    padding-left: 0.625rem !important; }
  .rtl .pl-10,
  .rtl .px-10 {
    padding-right: 0.625rem !important; }
  .rtl .pr-15,
  .rtl .px-15 {
    padding-left: 0.9375rem !important; }
  .rtl .pl-15,
  .rtl .px-15 {
    padding-right: 0.9375rem !important; }
  .rtl .pr-20,
  .rtl .px-20 {
    padding-left: 1.25rem !important; }
  .rtl .pl-20,
  .rtl .px-20 {
    padding-right: 1.25rem !important; }
  .rtl .pr-25,
  .rtl .px-25 {
    padding-left: 1.563rem !important; }
  .rtl .pl-25,
  .rtl .px-25 {
    padding-right: 1.563rem !important; }
  .rtl .pr-30,
  .rtl .px-30 {
    padding-left: 1.875rem !important; }
  .rtl .pl-30,
  .rtl .px-30 {
    padding-right: 1.875rem !important; }
  .rtl .pr-40,
  .rtl .px-40 {
    padding-left: 2.5rem !important; }
  .rtl .pl-40,
  .rtl .px-40 {
    padding-right: 2.5rem !important; }
  .rtl .pr-50,
  .rtl .px-50 {
    padding-left: 3.125rem !important; }
  .rtl .pl-50,
  .rtl .px-50 {
    padding-right: 3.125rem !important; }
  .rtl .pr-60,
  .rtl .px-60 {
    padding-left: 3.75rem !important; }
  .rtl .pl-60,
  .rtl .px-60 {
    padding-right: 3.75rem !important; }
  .rtl .pr-70,
  .rtl .px-70 {
    padding-left: 4.375rem !important; }
  .rtl .pl-70,
  .rtl .px-70 {
    padding-right: 4.375rem !important; }
  .rtl .mr-auto {
    margin-right: inherit !important;
    margin-left: auto !important; }
  .rtl .ml-auto {
    margin-right: auto !important;
    margin-left: inherit !important; }
  @media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
      margin-left: 0 !important; }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
      margin-right: 0 !important; }
    .rtl .mr-sm-1,
    .rtl .mx-sm-1 {
      margin-left: 0.25rem !important; }
    .rtl .ml-sm-1,
    .rtl .mx-sm-1 {
      margin-right: 0.25rem !important; }
    .rtl .mr-sm-2,
    .rtl .mx-sm-2 {
      margin-left: 0.5rem !important; }
    .rtl .ml-sm-2,
    .rtl .mx-sm-2 {
      margin-right: 0.5rem !important; }
    .rtl .mr-sm-3,
    .rtl .mx-sm-3 {
      margin-left: 1rem !important; }
    .rtl .ml-sm-3,
    .rtl .mx-sm-3 {
      margin-right: 1rem !important; }
    .rtl .mr-sm-4,
    .rtl .mx-sm-4 {
      margin-left: 1.5rem !important; }
    .rtl .ml-sm-4,
    .rtl .mx-sm-4 {
      margin-right: 1.5rem !important; }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-sm-10,
    .rtl .mx-sm-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-sm-10,
    .rtl .mx-sm-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-sm-15,
    .rtl .mx-sm-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-sm-15,
    .rtl .mx-sm-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-sm-20,
    .rtl .mx-sm-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-sm-20,
    .rtl .mx-sm-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-sm-25,
    .rtl .mx-sm-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-sm-25,
    .rtl .mx-sm-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-sm-30,
    .rtl .mx-sm-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-sm-30,
    .rtl .mx-sm-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-sm-40,
    .rtl .mx-sm-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-sm-40,
    .rtl .mx-sm-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-sm-50,
    .rtl .mx-sm-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-sm-50,
    .rtl .mx-sm-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-sm-60,
    .rtl .mx-sm-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-sm-60,
    .rtl .mx-sm-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-sm-70,
    .rtl .mx-sm-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-sm-70,
    .rtl .mx-sm-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
      padding-left: 0 !important; }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
      padding-right: 0 !important; }
    .rtl .pr-sm-1,
    .rtl .px-sm-1 {
      padding-left: 0.25rem !important; }
    .rtl .pl-sm-1,
    .rtl .px-sm-1 {
      padding-right: 0.25rem !important; }
    .rtl .pr-sm-2,
    .rtl .px-sm-2 {
      padding-left: 0.5rem !important; }
    .rtl .pl-sm-2,
    .rtl .px-sm-2 {
      padding-right: 0.5rem !important; }
    .rtl .pr-sm-3,
    .rtl .px-sm-3 {
      padding-left: 1rem !important; }
    .rtl .pl-sm-3,
    .rtl .px-sm-3 {
      padding-right: 1rem !important; }
    .rtl .pr-sm-4,
    .rtl .px-sm-4 {
      padding-left: 1.5rem !important; }
    .rtl .pl-sm-4,
    .rtl .px-sm-4 {
      padding-right: 1.5rem !important; }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-sm-10,
    .rtl .px-sm-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-sm-10,
    .rtl .px-sm-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-sm-15,
    .rtl .px-sm-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-sm-15,
    .rtl .px-sm-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-sm-20,
    .rtl .px-sm-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-sm-20,
    .rtl .px-sm-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-sm-25,
    .rtl .px-sm-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-sm-25,
    .rtl .px-sm-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-sm-30,
    .rtl .px-sm-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-sm-30,
    .rtl .px-sm-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-sm-40,
    .rtl .px-sm-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-sm-40,
    .rtl .px-sm-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-sm-50,
    .rtl .px-sm-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-sm-50,
    .rtl .px-sm-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-sm-60,
    .rtl .px-sm-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-sm-60,
    .rtl .px-sm-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-sm-70,
    .rtl .px-sm-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-sm-70,
    .rtl .px-sm-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-sm-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-sm-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }
  @media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
      margin-left: 0 !important; }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
      margin-right: 0 !important; }
    .rtl .mr-md-1,
    .rtl .mx-md-1 {
      margin-left: 0.25rem !important; }
    .rtl .ml-md-1,
    .rtl .mx-md-1 {
      margin-right: 0.25rem !important; }
    .rtl .mr-md-2,
    .rtl .mx-md-2 {
      margin-left: 0.5rem !important; }
    .rtl .ml-md-2,
    .rtl .mx-md-2 {
      margin-right: 0.5rem !important; }
    .rtl .mr-md-3,
    .rtl .mx-md-3 {
      margin-left: 1rem !important; }
    .rtl .ml-md-3,
    .rtl .mx-md-3 {
      margin-right: 1rem !important; }
    .rtl .mr-md-4,
    .rtl .mx-md-4 {
      margin-left: 1.5rem !important; }
    .rtl .ml-md-4,
    .rtl .mx-md-4 {
      margin-right: 1.5rem !important; }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-md-10,
    .rtl .mx-md-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-md-10,
    .rtl .mx-md-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-md-15,
    .rtl .mx-md-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-md-15,
    .rtl .mx-md-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-md-20,
    .rtl .mx-md-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-md-20,
    .rtl .mx-md-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-md-25,
    .rtl .mx-md-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-md-25,
    .rtl .mx-md-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-md-30,
    .rtl .mx-md-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-md-30,
    .rtl .mx-md-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-md-40,
    .rtl .mx-md-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-md-40,
    .rtl .mx-md-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-md-50,
    .rtl .mx-md-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-md-50,
    .rtl .mx-md-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-md-60,
    .rtl .mx-md-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-md-60,
    .rtl .mx-md-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-md-70,
    .rtl .mx-md-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-md-70,
    .rtl .mx-md-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
      padding-left: 0 !important; }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
      padding-right: 0 !important; }
    .rtl .pr-md-1,
    .rtl .px-md-1 {
      padding-left: 0.25rem !important; }
    .rtl .pl-md-1,
    .rtl .px-md-1 {
      padding-right: 0.25rem !important; }
    .rtl .pr-md-2,
    .rtl .px-md-2 {
      padding-left: 0.5rem !important; }
    .rtl .pl-md-2,
    .rtl .px-md-2 {
      padding-right: 0.5rem !important; }
    .rtl .pr-md-3,
    .rtl .px-md-3 {
      padding-left: 1rem !important; }
    .rtl .pl-md-3,
    .rtl .px-md-3 {
      padding-right: 1rem !important; }
    .rtl .pr-md-4,
    .rtl .px-md-4 {
      padding-left: 1.5rem !important; }
    .rtl .pl-md-4,
    .rtl .px-md-4 {
      padding-right: 1.5rem !important; }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-md-10,
    .rtl .px-md-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-md-10,
    .rtl .px-md-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-md-15,
    .rtl .px-md-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-md-15,
    .rtl .px-md-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-md-20,
    .rtl .px-md-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-md-20,
    .rtl .px-md-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-md-25,
    .rtl .px-md-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-md-25,
    .rtl .px-md-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-md-30,
    .rtl .px-md-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-md-30,
    .rtl .px-md-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-md-40,
    .rtl .px-md-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-md-40,
    .rtl .px-md-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-md-50,
    .rtl .px-md-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-md-50,
    .rtl .px-md-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-md-60,
    .rtl .px-md-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-md-60,
    .rtl .px-md-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-md-70,
    .rtl .px-md-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-md-70,
    .rtl .px-md-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-md-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-md-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }
  @media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
      margin-left: 0 !important; }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
      margin-right: 0 !important; }
    .rtl .mr-lg-1,
    .rtl .mx-lg-1 {
      margin-left: 0.25rem !important; }
    .rtl .ml-lg-1,
    .rtl .mx-lg-1 {
      margin-right: 0.25rem !important; }
    .rtl .mr-lg-2,
    .rtl .mx-lg-2 {
      margin-left: 0.5rem !important; }
    .rtl .ml-lg-2,
    .rtl .mx-lg-2 {
      margin-right: 0.5rem !important; }
    .rtl .mr-lg-3,
    .rtl .mx-lg-3 {
      margin-left: 1rem !important; }
    .rtl .ml-lg-3,
    .rtl .mx-lg-3 {
      margin-right: 1rem !important; }
    .rtl .mr-lg-4,
    .rtl .mx-lg-4 {
      margin-left: 1.5rem !important; }
    .rtl .ml-lg-4,
    .rtl .mx-lg-4 {
      margin-right: 1.5rem !important; }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-lg-10,
    .rtl .mx-lg-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-lg-10,
    .rtl .mx-lg-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-lg-15,
    .rtl .mx-lg-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-lg-15,
    .rtl .mx-lg-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-lg-20,
    .rtl .mx-lg-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-lg-20,
    .rtl .mx-lg-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-lg-25,
    .rtl .mx-lg-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-lg-25,
    .rtl .mx-lg-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-lg-30,
    .rtl .mx-lg-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-lg-30,
    .rtl .mx-lg-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-lg-40,
    .rtl .mx-lg-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-lg-40,
    .rtl .mx-lg-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-lg-50,
    .rtl .mx-lg-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-lg-50,
    .rtl .mx-lg-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-lg-60,
    .rtl .mx-lg-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-lg-60,
    .rtl .mx-lg-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-lg-70,
    .rtl .mx-lg-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-lg-70,
    .rtl .mx-lg-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
      padding-left: 0 !important; }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
      padding-right: 0 !important; }
    .rtl .pr-lg-1,
    .rtl .px-lg-1 {
      padding-left: 0.25rem !important; }
    .rtl .pl-lg-1,
    .rtl .px-lg-1 {
      padding-right: 0.25rem !important; }
    .rtl .pr-lg-2,
    .rtl .px-lg-2 {
      padding-left: 0.5rem !important; }
    .rtl .pl-lg-2,
    .rtl .px-lg-2 {
      padding-right: 0.5rem !important; }
    .rtl .pr-lg-3,
    .rtl .px-lg-3 {
      padding-left: 1rem !important; }
    .rtl .pl-lg-3,
    .rtl .px-lg-3 {
      padding-right: 1rem !important; }
    .rtl .pr-lg-4,
    .rtl .px-lg-4 {
      padding-left: 1.5rem !important; }
    .rtl .pl-lg-4,
    .rtl .px-lg-4 {
      padding-right: 1.5rem !important; }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-lg-10,
    .rtl .px-lg-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-lg-10,
    .rtl .px-lg-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-lg-15,
    .rtl .px-lg-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-lg-15,
    .rtl .px-lg-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-lg-20,
    .rtl .px-lg-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-lg-20,
    .rtl .px-lg-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-lg-25,
    .rtl .px-lg-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-lg-25,
    .rtl .px-lg-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-lg-30,
    .rtl .px-lg-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-lg-30,
    .rtl .px-lg-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-lg-40,
    .rtl .px-lg-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-lg-40,
    .rtl .px-lg-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-lg-50,
    .rtl .px-lg-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-lg-50,
    .rtl .px-lg-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-lg-60,
    .rtl .px-lg-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-lg-60,
    .rtl .px-lg-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-lg-70,
    .rtl .px-lg-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-lg-70,
    .rtl .px-lg-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-lg-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-lg-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }
  @media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
      margin-left: 0 !important; }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
      margin-right: 0 !important; }
    .rtl .mr-xl-1,
    .rtl .mx-xl-1 {
      margin-left: 0.25rem !important; }
    .rtl .ml-xl-1,
    .rtl .mx-xl-1 {
      margin-right: 0.25rem !important; }
    .rtl .mr-xl-2,
    .rtl .mx-xl-2 {
      margin-left: 0.5rem !important; }
    .rtl .ml-xl-2,
    .rtl .mx-xl-2 {
      margin-right: 0.5rem !important; }
    .rtl .mr-xl-3,
    .rtl .mx-xl-3 {
      margin-left: 1rem !important; }
    .rtl .ml-xl-3,
    .rtl .mx-xl-3 {
      margin-right: 1rem !important; }
    .rtl .mr-xl-4,
    .rtl .mx-xl-4 {
      margin-left: 1.5rem !important; }
    .rtl .ml-xl-4,
    .rtl .mx-xl-4 {
      margin-right: 1.5rem !important; }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
      margin-left: 0.3125rem !important; }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
      margin-right: 0.3125rem !important; }
    .rtl .mr-xl-10,
    .rtl .mx-xl-10 {
      margin-left: 0.625rem !important; }
    .rtl .ml-xl-10,
    .rtl .mx-xl-10 {
      margin-right: 0.625rem !important; }
    .rtl .mr-xl-15,
    .rtl .mx-xl-15 {
      margin-left: 0.9375rem !important; }
    .rtl .ml-xl-15,
    .rtl .mx-xl-15 {
      margin-right: 0.9375rem !important; }
    .rtl .mr-xl-20,
    .rtl .mx-xl-20 {
      margin-left: 1.25rem !important; }
    .rtl .ml-xl-20,
    .rtl .mx-xl-20 {
      margin-right: 1.25rem !important; }
    .rtl .mr-xl-25,
    .rtl .mx-xl-25 {
      margin-left: 1.563rem !important; }
    .rtl .ml-xl-25,
    .rtl .mx-xl-25 {
      margin-right: 1.563rem !important; }
    .rtl .mr-xl-30,
    .rtl .mx-xl-30 {
      margin-left: 1.875rem !important; }
    .rtl .ml-xl-30,
    .rtl .mx-xl-30 {
      margin-right: 1.875rem !important; }
    .rtl .mr-xl-40,
    .rtl .mx-xl-40 {
      margin-left: 2.5rem !important; }
    .rtl .ml-xl-40,
    .rtl .mx-xl-40 {
      margin-right: 2.5rem !important; }
    .rtl .mr-xl-50,
    .rtl .mx-xl-50 {
      margin-left: 3.125rem !important; }
    .rtl .ml-xl-50,
    .rtl .mx-xl-50 {
      margin-right: 3.125rem !important; }
    .rtl .mr-xl-60,
    .rtl .mx-xl-60 {
      margin-left: 3.75rem !important; }
    .rtl .ml-xl-60,
    .rtl .mx-xl-60 {
      margin-right: 3.75rem !important; }
    .rtl .mr-xl-70,
    .rtl .mx-xl-70 {
      margin-left: 4.375rem !important; }
    .rtl .ml-xl-70,
    .rtl .mx-xl-70 {
      margin-right: 4.375rem !important; }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
      padding-left: 0 !important; }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
      padding-right: 0 !important; }
    .rtl .pr-xl-1,
    .rtl .px-xl-1 {
      padding-left: 0.25rem !important; }
    .rtl .pl-xl-1,
    .rtl .px-xl-1 {
      padding-right: 0.25rem !important; }
    .rtl .pr-xl-2,
    .rtl .px-xl-2 {
      padding-left: 0.5rem !important; }
    .rtl .pl-xl-2,
    .rtl .px-xl-2 {
      padding-right: 0.5rem !important; }
    .rtl .pr-xl-3,
    .rtl .px-xl-3 {
      padding-left: 1rem !important; }
    .rtl .pl-xl-3,
    .rtl .px-xl-3 {
      padding-right: 1rem !important; }
    .rtl .pr-xl-4,
    .rtl .px-xl-4 {
      padding-left: 1.5rem !important; }
    .rtl .pl-xl-4,
    .rtl .px-xl-4 {
      padding-right: 1.5rem !important; }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
      padding-left: 0.3125rem !important; }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
      padding-right: 0.3125rem !important; }
    .rtl .pr-xl-10,
    .rtl .px-xl-10 {
      padding-left: 0.625rem !important; }
    .rtl .pl-xl-10,
    .rtl .px-xl-10 {
      padding-right: 0.625rem !important; }
    .rtl .pr-xl-15,
    .rtl .px-xl-15 {
      padding-left: 0.9375rem !important; }
    .rtl .pl-xl-15,
    .rtl .px-xl-15 {
      padding-right: 0.9375rem !important; }
    .rtl .pr-xl-20,
    .rtl .px-xl-20 {
      padding-left: 1.25rem !important; }
    .rtl .pl-xl-20,
    .rtl .px-xl-20 {
      padding-right: 1.25rem !important; }
    .rtl .pr-xl-25,
    .rtl .px-xl-25 {
      padding-left: 1.563rem !important; }
    .rtl .pl-xl-25,
    .rtl .px-xl-25 {
      padding-right: 1.563rem !important; }
    .rtl .pr-xl-30,
    .rtl .px-xl-30 {
      padding-left: 1.875rem !important; }
    .rtl .pl-xl-30,
    .rtl .px-xl-30 {
      padding-right: 1.875rem !important; }
    .rtl .pr-xl-40,
    .rtl .px-xl-40 {
      padding-left: 2.5rem !important; }
    .rtl .pl-xl-40,
    .rtl .px-xl-40 {
      padding-right: 2.5rem !important; }
    .rtl .pr-xl-50,
    .rtl .px-xl-50 {
      padding-left: 3.125rem !important; }
    .rtl .pl-xl-50,
    .rtl .px-xl-50 {
      padding-right: 3.125rem !important; }
    .rtl .pr-xl-60,
    .rtl .px-xl-60 {
      padding-left: 3.75rem !important; }
    .rtl .pl-xl-60,
    .rtl .px-xl-60 {
      padding-right: 3.75rem !important; }
    .rtl .pr-xl-70,
    .rtl .px-xl-70 {
      padding-left: 4.375rem !important; }
    .rtl .pl-xl-70,
    .rtl .px-xl-70 {
      padding-right: 4.375rem !important; }
    .rtl .mr-xl-auto {
      margin-right: inherit !important;
      margin-left: auto !important; }
    .rtl .ml-xl-auto {
      margin-right: auto !important;
      margin-left: inherit !important; } }

/*==== Core Mixins ====*/
/*====== All Mixin Files Include Here ======*/
.square-10 {
  display: inline-block;
  font-size: 5px;
  height: 10px !important;
  line-height: 10px;
  text-align: center;
  width: 10px !important;
  vertical-align: middle; }

.square-15 {
  display: inline-block;
  font-size: 7px;
  height: 15px !important;
  line-height: 15px;
  text-align: center;
  width: 15px !important;
  vertical-align: middle; }

.square-20 {
  display: inline-block;
  font-size: 10px;
  height: 20px !important;
  line-height: 20px;
  text-align: center;
  width: 20px !important;
  vertical-align: middle; }

.square-30 {
  display: inline-block;
  font-size: 15px;
  height: 30px !important;
  line-height: 30px;
  text-align: center;
  width: 30px !important;
  vertical-align: middle; }

.square-40, .size-40 {
  display: inline-block;
  font-size: 20px;
  height: 40px !important;
  line-height: 40px;
  text-align: center;
  width: 40px !important;
  vertical-align: middle; }

.square-50 {
  display: inline-block;
  font-size: 25px;
  height: 50px !important;
  line-height: 50px;
  text-align: center;
  width: 50px !important;
  vertical-align: middle; }

.square-60, .size-60 {
  display: inline-block;
  font-size: 30px;
  height: 60px !important;
  line-height: 60px;
  text-align: center;
  width: 60px !important;
  vertical-align: middle; }

.square-70 {
  display: inline-block;
  font-size: 35px;
  height: 70px !important;
  line-height: 70px;
  text-align: center;
  width: 70px !important;
  vertical-align: middle; }

.square-80, .size-80 {
  display: inline-block;
  font-size: 40px;
  height: 80px !important;
  line-height: 80px;
  text-align: center;
  width: 80px !important;
  vertical-align: middle; }

.square-100, .size-100 {
  display: inline-block;
  font-size: 50px;
  height: 100px !important;
  line-height: 100px;
  text-align: center;
  width: 100px !important;
  vertical-align: middle; }

.square-120, .size-120 {
  display: inline-block;
  font-size: 60px;
  height: 120px !important;
  line-height: 120px;
  text-align: center;
  width: 120px !important;
  vertical-align: middle; }

/*======= Chip outline ========*/
.chip-outline-primary {
  color: #5D92F4 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #5D92F4 !important; }

.chip-outline-secondary {
  color: #677080 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #677080 !important; }

.chip-outline-success {
  color: #00D014 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #00D014 !important; }

.chip-outline-info {
  color: #00D0BD !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #00D0BD !important; }

.chip-outline-warning {
  color: #FFB70F !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #FFB70F !important; }

.chip-outline-danger {
  color: #FF3739 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #FF3739 !important; }

.chip-outline-light {
  color: #FAFAFA !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #FAFAFA !important; }

.chip-outline-dark {
  color: #464D69 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #464D69 !important; }

.chip-outline-light-yellow {
  color: #FFF59D !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #FFF59D !important; }

.chip-outline-twitter {
  color: #52AEFF !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #52AEFF !important; }

.chip-outline-facebook {
  color: #3B5998 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #3B5998 !important; }

.chip-outline-google {
  color: #F13A30 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #F13A30 !important; }

.chip-outline-pinterest {
  color: #d32f2f !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #d32f2f !important; }

.chip-outline-instagram {
  color: #8d6e63 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #8d6e63 !important; }

.chip-outline-rss {
  color: #ff9800 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #ff9800 !important; }

.chip-outline-tumblr {
  color: #607d8b !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #607d8b !important; }

.chip-outline-linkedin {
  color: #4875B4 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #4875B4 !important; }

.chip-outline-dribbble {
  color: #f06292 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #f06292 !important; }

.chip-outline-youtube {
  color: #d50000 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #d50000 !important; }

.chip-outline-github {
  color: #424242 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #424242 !important; }

.chip-outline-skype {
  color: #4fc3f7 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #4fc3f7 !important; }

.chip-outline-orange {
  color: #D46B08 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #D46B08 !important; }

.chip-outline-purple {
  color: #564fc1 !important;
  background-color: transparent !important;
  background-image: none;
  border: 1px solid #564fc1 !important; }

/*==== Core Utilities =====*/
/*===== All Utilities Files Include Here ======*/
.font-lg {
  font-size: 1.25rem; }

.font-md {
  font-size: 1rem; }

.font-sm {
  font-size: 0.875rem !important; }

.font-xs {
  font-size: 0.75rem !important;
  line-height: 1.25rem; }

.font-2x {
  font-size: 2rem; }

.font-3x {
  font-size: 3rem; }

.font-4x {
  font-size: 4rem !important; }

.font-5x {
  font-size: 5rem; }

.font-xl {
  font-size: 2.625rem; }

.fs-12 {
  font-size: 12px; }

.fs-14 {
  font-size: 14px; }

.fw-normal {
  font-weight: 400 !important; }

.fw-semi-bold {
  font-weight: 500 !important; }

.fw-bold {
  font-weight: 700 !important; }

.text-pink {
  color: #FF2B72 !important; }

.text-indigo {
  color: #174176 !important; }

.text-teal {
  color: #677080 !important; }

.text-purple {
  color: #564fc1 !important; }

.text-yellow {
  color: #D46B08 !important; }

.text-gray {
  color: #A5A7B2; }

.text-base {
  color: #A5A7B2; }

.facebook {
  color: #3B5998; }

.twitter {
  color: #52AEFF; }

.linkedin {
  color: #4875B4; }

.google {
  color: #F13A30; }

/*=========== Background Utilities ==========*/
.gradient-primary {
  background: linear-gradient(180deg, #5D92F4 0%, #09203F 100%); }
  .gradient-primary * {
    color: #fff; }

.gradient-purple {
  background: linear-gradient(0deg, #F53844 0%, #42378F 100%); }
  .gradient-purple * {
    color: #fff; }

.gradient-success {
  background: linear-gradient(180deg, #00D0BD 0%, #007ADF 100%); }
  .gradient-success * {
    color: #fff; }

.gradient-danger {
  background: linear-gradient(0deg, #B02E0C 0%, #FFB70F 100%); }
  .gradient-danger * {
    color: #fff; }

.gradient-warning {
  background: linear-gradient(0deg, #B02E0C 0%, #FFB70F 100%); }
  .gradient-warning * {
    color: #fff; }

.bg-aqua {
  background-color: #E1EBFF; }

.bg-light {
  background-color: #F4F7FA !important; }

.bg-grdnt-violet {
  background: linear-gradient(180deg, #03001E 0%, #7303C0 24.82%, #EC38BC 62.59%, #FDEFF9 100%); }

.bg-grdnt-youtube {
  background: linear-gradient(0deg, #F5AF19 0%, #F12711 100%); }

.bg-grdnt-primary-danger {
  background: linear-gradient(0deg, #EC2F4B 0%, #009FFF 100%); }

.bg-grdnt-purple-danger {
  background: linear-gradient(0deg, #240B36 0%, #C31432 100%); }

.bg-grdnt-purple-dark {
  background: linear-gradient(to top, #24243e, #302b63, #0f0c29); }

/*====== All Custom Files Include Here ======*/
/*======= All Common Style Mention Here ======*/
html {
  font-size: 16px; }

@media (max-width: 1700px) {
  html {
    font-size: 15px; } }

@media (max-width: 1366px) {
  html {
    font-size: 15px; } }

@media (max-width: 1280px) {
  html {
    font-size: 15px; } }

@media (max-width: 991px) {
  html {
    font-size: 14px; } }

body, html {
  height: 100%; }

html {
  font-family: "Heebo", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  color: #464D69;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
  width: 100%; }

[tabindex="-1"]:focus {
  outline: none; }

ul, ol {
  padding: 0; }

select,
button,
textarea,
input {
  vertical-align: baseline; }

html[dir=rtl], html[dir=ltr],
body[dir=rtl],
body[dir=ltr] {
  unicode-bidi: embed; }

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override; }

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 500; }

p {
  font-size: 14px; }

a {
  transition: all 0.5s ease 0s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus, a.active {
    color: inherit;
    text-decoration: none;
    outline: 0; }

*::-moz-selection {
  background-color: #5D92F4;
  color: #FAFAFA; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #727891 !important; }

* {
  outline: none; }
  *:hover, *:focus, *.active {
    outline: none; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

sup {
  top: -0.9em; }

disabled {
  cursor: none; }

.vr-super {
  vertical-align: super; }

.pos-static {
  position: static !important; }

.overlay-wrap {
  position: relative; }

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.overflow-hidden {
  overflow: hidden; }

.border-rad-sm {
  border-radius: 5px; }

address span {
  display: block; }

.pos-center:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.center-holder {
  display: inline-block;
  height: auto;
  padding: 1.25rem;
  vertical-align: middle;
  width: 90%; }

.strike p, .strike h5 {
  text-decoration: line-through; }

.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.switch.on {
  background-color: #00D014; }

.modal-open {
  overflow: visible !important; }

.aqua-ripple > div {
  transition: all 0.2s ease-in-out; }
  .aqua-ripple > div:hover {
    background-color: #E1EBFF !important; }

[class*="col-"] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)); }

/*======== Breadcrumb =========*/
.breadcrumb {
  background: none; }

/*======== Loader Scss ========*/
.rct-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important; }
  .rct-loader .spring-spinner-rotator {
    border-top-color: #5D92F4 !important;
    border-right-color: #5D92F4 !important; }

.loader-overlay {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 99;
  align-items: center;
  overflow: hidden;
  margin: 0 auto; }

.preloader-sidebar {
  background-color: rgba(0, 0, 0, 0.2); }

.preload-widget {
  background-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08); }

.preload-header {
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.fixed-plugin .agency-bg {
  display: none; }

/*======== Responsive =========*/
@media (max-width: 1199px) and (min-width: 768px) {
  .d-sm-full {
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .d-sm-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important; } }

@media (max-width: 991px) and (min-width: 576px) {
  .d-xs-full {
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .d-xs-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important; } }

@media (max-width: 575px) and (min-width: 450px) {
  .d-xxs-full {
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .d-xxs-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important; } }

@media (max-width: 450px) {
  .d-xs-none {
    display: none; } }

@media (max-width: 575px) and (min-width: 320px) {
  .display-n {
    display: none !important; } }

/*======== Header Scss ========*/
.app-header .rct-header {
  background-color: #fff;
  z-index: -9 !important;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.2) !important; }
  .app-header .rct-header .site-logo {
    padding: 1rem 4rem 0.8rem 2rem;
    background-color: #5D92F4; }
  .app-header .rct-header .navbar-left {
    margin-left: 0.85rem; }
  .app-header .rct-header .cart-icon .dropdown-menu .dropdown-foot a {
    color: #fff !important; }

.header-icon {
  height: 35px;
  width: 35px;
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.25rem;
  transition: all 0.5s ease 0s;
  color: #727891; }
  .header-icon:hover, .header-icon:focus, .header-icon.active {
    border-color: #5D92F4 !important;
    color: #5D92F4 !important; }
    .header-icon:hover a, .header-icon:focus a, .header-icon.active a {
      color: #5D92F4; }

.quciklink-dropdown .dropdown-menu,
.notification-dropdown .dropdown-menu,
.language-dropdown .dropdown-menu,
.cart-dropdown .dropdown-menu {
  background-color: #FAFAFA;
  width: 321px; }
  .quciklink-dropdown .dropdown-menu.show,
  .notification-dropdown .dropdown-menu.show,
  .language-dropdown .dropdown-menu.show,
  .cart-dropdown .dropdown-menu.show {
    transform: translate3d(0px, 48px, 0px) !important; }
  .quciklink-dropdown .dropdown-menu .dropdown-top,
  .notification-dropdown .dropdown-menu .dropdown-top,
  .language-dropdown .dropdown-menu .dropdown-top,
  .cart-dropdown .dropdown-menu .dropdown-top {
    padding: 1rem 1.25rem; }
  .quciklink-dropdown .dropdown-menu .dropdown-list,
  .notification-dropdown .dropdown-menu .dropdown-list,
  .language-dropdown .dropdown-menu .dropdown-list,
  .cart-dropdown .dropdown-menu .dropdown-list {
    background: transparent !important; }
    .quciklink-dropdown .dropdown-menu .dropdown-list li,
    .notification-dropdown .dropdown-menu .dropdown-list li,
    .language-dropdown .dropdown-menu .dropdown-list li,
    .cart-dropdown .dropdown-menu .dropdown-list li {
      display: inline-block;
      width: 46.5%;
      box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.02);
      margin: 0.3125rem;
      background-color: #fff; }
      .quciklink-dropdown .dropdown-menu .dropdown-list li a,
      .notification-dropdown .dropdown-menu .dropdown-list li a,
      .language-dropdown .dropdown-menu .dropdown-list li a,
      .cart-dropdown .dropdown-menu .dropdown-list li a {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 1rem; }
        .quciklink-dropdown .dropdown-menu .dropdown-list li a span,
        .notification-dropdown .dropdown-menu .dropdown-list li a span,
        .language-dropdown .dropdown-menu .dropdown-list li a span,
        .cart-dropdown .dropdown-menu .dropdown-list li a span {
          font-size: 0.875rem;
          transition: color 0.3s ease;
          color: #464D69; }
      .quciklink-dropdown .dropdown-menu .dropdown-list li:hover,
      .notification-dropdown .dropdown-menu .dropdown-list li:hover,
      .language-dropdown .dropdown-menu .dropdown-list li:hover,
      .cart-dropdown .dropdown-menu .dropdown-list li:hover {
        background-color: #fff !important; }
        .quciklink-dropdown .dropdown-menu .dropdown-list li:hover a span,
        .notification-dropdown .dropdown-menu .dropdown-list li:hover a span,
        .language-dropdown .dropdown-menu .dropdown-list li:hover a span,
        .cart-dropdown .dropdown-menu .dropdown-list li:hover a span {
          color: #5D92F4 !important; }

.language-dropdown .dropdown-menu,
.cart-dropdown .dropdown-menu {
  left: -15rem !important; }
  .language-dropdown .dropdown-menu a,
  .cart-dropdown .dropdown-menu a {
    font-size: 0.875rem;
    color: #464D69; }
    .language-dropdown .dropdown-menu a:hover,
    .cart-dropdown .dropdown-menu a:hover {
      color: #5D92F4 !important; }

.cart-dropdown .dropdown-menu .dropdown-list li,
.notification-dropdown .dropdown-menu .dropdown-list li {
  width: 97% !important;
  padding: 0.75rem; }
  .cart-dropdown .dropdown-menu .dropdown-list li a,
  .notification-dropdown .dropdown-menu .dropdown-list li a {
    padding: 0;
    height: auto !important;
    display: inline-block; }

.notification-dropdown .dropdown-menu {
  width: 360px;
  left: -19.5rem !important; }

.language-icon {
  height: 2.25rem;
  width: 5rem;
  line-height: 26px;
  padding: 0.25rem;
  color: #464D69;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  background-color: #fff; }

.cart-dropdown .dropdown-foot a, .cart-dropdown .dropdown-foot a:hover {
  color: #fff !important; }

.humburger {
  color: #727891 !important;
  border: 1px solid #EBEDF2 !important;
  height: 2.5rem !important;
  width: 2.5rem !important; }

@media (max-width: 1199px) and (min-width: 320px) {
  .rct-header.mui-fixed {
    left: 0; }
  .rct-app-content {
    width: 100% !important; } }

/*========= Dashboard Overlay ======*/
@-webkit-keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.show {
  -webkit-animation-name: show;
  animation-name: show; }

.dashboard-overlay {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  padding: 1.875rem;
  height: 100vh;
  top: 63px;
  z-index: 99;
  overflow-y: auto;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .dashboard-overlay .dashboard-overlay-content {
    color: #464D69; }
    .dashboard-overlay .dashboard-overlay-content .arrow-icon {
      font-size: 2.25rem;
      color: #00D014; }
    .dashboard-overlay .dashboard-overlay-content .totle-status h2 {
      font-size: 1.5rem; }
  .dashboard-overlay .table tr td:last-child {
    text-align: right; }
  .dashboard-overlay .progress {
    height: 6px; }
  .dashboard-overlay .rating-star li {
    font-size: 1.875rem; }
  .dashboard-overlay .report-title {
    background: #FAFAFA;
    font-size: 0.875rem;
    padding: 0.9375rem;
    margin-bottom: 0; }
  .dashboard-overlay .overlay-head {
    padding: 0 0.9375rem; }
    .dashboard-overlay .overlay-head .closed {
      font-size: 1.375rem;
      color: #fff;
      font-weight: 700; }

/*======== Search Form =======*/
.search-icon .search-wrapper {
  position: relative; }
  .search-icon .search-wrapper::before {
    content: "\F1C3";
    font-family: 'Material-Design-Iconic-Font';
    color: #727891;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
    font-size: 1.25rem;
    cursor: pointer; }
  .search-icon .search-wrapper .search-input-lg {
    padding: 0.5rem 0.75rem 0.5rem 3.25rem;
    width: 200px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 0.875rem; }
    .search-icon .search-wrapper .search-input-lg:focus {
      box-shadow: none;
      width: 250px; }

.search-icon .search-form-wrap {
  transform: translateY(-100%);
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  opacity: 0; }
  .search-icon .search-form-wrap .close-btn {
    position: absolute;
    right: 2.5rem;
    top: 4rem; }
  .search-icon .search-form-wrap form {
    width: 75%; }
    .search-icon .search-form-wrap form .form-group {
      position: relative; }
      .search-icon .search-form-wrap form .form-group .search-input {
        border: none;
        border-bottom: 1px solid #fff;
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 1rem 3rem 1rem 0;
        color: #fff; }
      .search-icon .search-form-wrap form .form-group .search-btn {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0; }
  .search-icon .search-form-wrap.search-slide {
    transform: translateY(0);
    opacity: 1; }

/*======= Responsive ========*/
@media (max-width: 500px) {
  .rct-header .site-logo,
  .summary-icon {
    display: none !important; } }

@media (min-width: 725px) {
  .search-form-wrap,
  .search-icon-btn {
    display: none !important; }
  .search-wrapper {
    display: block !important; } }

@media (max-width: 724px) {
  .app-horizontal .app-header .site-logo {
    padding: 0.875rem 1rem !important; }
  .app-horizontal .app-header .navbar-right {
    margin: 0 0 0 -1.25rem; }
  .search-form-wrap,
  .search-icon-btn {
    display: block !important; }
  .search-wrapper,
  .search-icon:before {
    display: none !important; } }

@media (max-width: 991px) {
  .app-main-container > div > div {
    box-shadow: none !important; }
  .notification-dropdown .dropdown-menu,
  .language-dropdown .dropdown-menu,
  .cart-dropdown .dropdown-menu {
    width: 280px !important;
    left: -16rem !important; } }

@media (max-width: 450px) {
  .language-dropdown .dropdown-menu {
    left: -8rem !important; }
  .notification-dropdown .dropdown-menu {
    left: -13rem !important; } }

/*============ Sidebar Layout ===========*/
.rct-page-wrapper [direction="right"] {
  border-right: none;
  z-index: 999 !important; }

.rct-sidebar {
  background-repeat: no-repeat;
  background-size: cover;
  width: 16.25rem;
  background-position: top center;
  overflow: hidden;
  transition: all 200ms ease 0s;
  height: 100%; }
  .rct-sidebar .rct-sidebar-content {
    position: relative;
    z-index: 0; }
    .rct-sidebar .rct-sidebar-content:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      z-index: -1;
      opacity: 0.9; }
    .rct-sidebar .rct-sidebar-content.sidebar-overlay-dark:before {
      background: linear-gradient(0deg, #464D69 0%, #464d69 100%); }
    .rct-sidebar .rct-sidebar-content.sidebar-overlay-light:before {
      background-color: white; }
  .rct-sidebar.background-none .rct-sidebar-content:before {
    opacity: 1 !important; }
  .rct-sidebar .site-logo {
    padding: 0.89rem 1.5rem;
    align-items: center;
    display: flex; }
  .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block {
    padding: 1.25rem; }
    .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown {
      cursor: pointer; }
      .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu {
        background-color: #FAFAFA;
        width: 200px; }
        .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul {
          background-color: transparent; }
          .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul li:not(.user-profile-top) {
            box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.02);
            margin: 0.3125rem; }
            .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul li:not(.user-profile-top) a {
              background-color: #fff;
              padding: 0.75rem 1rem;
              display: block; }
              .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul li:not(.user-profile-top) a i {
                font-size: 1rem; }
              .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul li:not(.user-profile-top) a span:not(.badge) {
                color: #464D69;
                font-size: 0.875rem;
                transition: color 0.3s ease; }
              .rct-sidebar .rct-sidebar-wrap .top-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul li:not(.user-profile-top) a:hover span:not(.badge) {
                color: #5D92F4; }
  .rct-sidebar .user-profile > img {
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    border-right: 3px solid #fff;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.5); }
  .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.side-title {
    font-size: 0.75rem;
    line-height: 1.25rem;
    position: static;
    padding: 0.5rem 1.5rem !important; }
  .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item {
    position: relative; }
    .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item .menu-icon {
      font-size: 1.25rem; }
    .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item span.menu {
      font-size: 0.875rem; }
    .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item:after {
      position: absolute;
      content: "\F2FB";
      font-family: Material-Design-Iconic-Font;
      right: 1.5rem;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      transform: rotate(0);
      transition: all 0.2s ease-in-out; }
    .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item.item-active {
      background-color: rgba(0, 0, 0, 0.2); }
      .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item.item-active:after {
        transform: rotate(90deg); }
      .rct-sidebar .rct-sidebar-nav .rct-mainMenu li.list-item.item-active span.menu {
        font-weight: 500; }
  .rct-sidebar .rct-sidebar-nav .rct-mainMenu > li:not(.side-title) {
    padding: 1rem 1.5rem; }
  .rct-sidebar .rct-sidebar-nav .rct-mainMenu > li:not(.list-item) {
    padding: 0;
    display: block; }
    .rct-sidebar .rct-sidebar-nav .rct-mainMenu > li:not(.list-item) a {
      display: block;
      padding: 1rem 1.5rem; }
  .rct-sidebar .rct-sidebar-nav .rct-mainMenu .sub-menu ul li {
    padding: 0;
    display: block; }
    .rct-sidebar .rct-sidebar-nav .rct-mainMenu .sub-menu ul li a {
      display: block;
      padding: 0.75rem 1.5rem 0.75rem 3.4rem; }
      .rct-sidebar .rct-sidebar-nav .rct-mainMenu .sub-menu ul li a.item-active {
        font-weight: 500; }
    .rct-sidebar .rct-sidebar-nav .rct-mainMenu .sub-menu ul li.list-item {
      padding: 0.75rem 1.5rem 0.75rem 3.4rem; }

.dropdown .dropdown-menu {
  transform: translate3d(0px, 50px, 0px);
  padding: 0; }

.rct-sidebar .sidebar-overlay-dark * {
  color: #fff; }

.rct-sidebar .sidebar-overlay-light * {
  color: #464D69; }

.rct-sidebar .sidebar-overlay-light .site-logo {
  background-color: #5D92F4; }

.collapsed-sidebar .rct-header {
  left: 0; }

.collapsed-sidebar .rct-sidebar {
  width: 0; }

.collapsed-sidebar .rct-app-content {
  width: 100%; }

/*========= Customizer ==========*/
.rct-customizer {
  width: 16.25rem;
  overflow-x: hidden; }
  .rct-customizer header {
    background: #464D69;
    color: #fff; }
  .rct-customizer .chat-area .chat-head {
    border-top: 1px solid #EBEDF2; }
  .rct-customizer .chat-area .chat-head,
  .rct-customizer .chat-area .chat-body {
    padding: 0.625rem; }
  .rct-customizer .chat-area .chat-body .media-body {
    padding: 0.625rem !important;
    margin: 3px; }
  .rct-customizer .chat-area .attachment {
    align-items: center;
    display: flex; }
  .rct-customizer .chat-area h3 {
    font-size: 14px;
    margin: 0; }
  .rct-customizer .chat-area .send-icons ul > li:nth-last-of-type(-n+2) {
    display: none; }
  .rct-customizer .chat-area .chat-footer {
    padding: 0; }
  .rct-customizer .chat-sidebar .badge-xs {
    padding: 5px; }
  .rct-customizer .chat-sidebar .media-body h5 {
    font-size: 14px; }
  .rct-customizer .panel-title {
    font-size: 14px;
    font-weight: bold;
    margin: 10px; }

@media (max-width: 1199px) {
  .rct-sidebar-wrap .rct-scroll > div:first-child, .rct-page .rct-scroll > div:first-child {
    margin-bottom: 0 !important;
    padding-bottom: 65px !important; }
  .app-boxed .rct-page .rct-scroll > div:first-child {
    padding-bottom: 0px !important; } }

/*======== Horizontal Menu Layout ========*/
.app-horizontal .background-img,
.app-horizontal ul.text-center > li:nth-of-type(3),
.app-horizontal .app-settings > li:nth-of-type(2) {
  display: none; }

.app-horizontal .app-header .humburger {
  display: none; }

.horizontal-menu {
  display: flex;
  background-color: #FFFFFF;
  height: 3.125rem;
  box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
  left: 0;
  right: 0;
  z-index: 99; }
  .horizontal-menu .no-arrow::after {
    display: none; }
  .horizontal-menu > ul {
    width: 100%; }
    .horizontal-menu > ul.horizontal-center {
      justify-content: center; }
    .horizontal-menu > ul > li {
      position: relative; }
      .horizontal-menu > ul > li > a {
        color: #464D69;
        padding: 0.9375rem 1.563rem 0.9375rem 1.563rem;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: center; }
        .horizontal-menu > ul > li > a::after {
          content: "\F2F9";
          font-family: 'Material-Design-Iconic-Font';
          font-size: 0.875rem;
          margin-left: 0.9375rem; }
        .horizontal-menu > ul > li > a:hover {
          background-color: #FAFAFA; }
        .horizontal-menu > ul > li > a i {
          margin-right: 0.625rem; }
      .horizontal-menu > ul > li:hover .sub-menu {
        opacity: 1;
        visibility: visible; }
      .horizontal-menu > ul > li .sub-menu {
        position: absolute;
        left: 0;
        z-index: 99;
        background-color: #FFFFFF;
        width: 15rem;
        visibility: hidden;
        opacity: 0;
        transform-origin: left 0 0;
        transition: all 0.3s ease-in-out 0s;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.09); }
        .horizontal-menu > ul > li .sub-menu > li > a::after {
          content: "\F2FB";
          font-family: 'Material-Design-Iconic-Font';
          position: absolute;
          right: 18px; }
        .horizontal-menu > ul > li .sub-menu > li > a:hover {
          background-color: #FAFAFA; }
        .horizontal-menu > ul > li .sub-menu li {
          position: relative; }
          .horizontal-menu > ul > li .sub-menu li a:hover {
            background-color: #FAFAFA; }
          .horizontal-menu > ul > li .sub-menu li:hover .sub-menu-child {
            opacity: 1;
            visibility: visible; }
          .horizontal-menu > ul > li .sub-menu li .sub-menu-child {
            position: absolute;
            width: 100%;
            left: 15rem;
            top: 0;
            background-color: #FFFFFF;
            opacity: 0;
            visibility: hidden;
            transform-origin: left 0 0;
            transition: all 0.3s ease-in-out 0s;
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.09); }
            .horizontal-menu > ul > li .sub-menu li .sub-menu-child > li > a::after {
              content: "\F2FB";
              font-family: 'Material-Design-Iconic-Font';
              position: absolute;
              right: 10px; }
            .horizontal-menu > ul > li .sub-menu li .sub-menu-child > li > a:hover {
              background-color: #FAFAFA; }
            .horizontal-menu > ul > li .sub-menu li .sub-menu-child li {
              position: relative; }
              .horizontal-menu > ul > li .sub-menu li .sub-menu-child li a:hover {
                background-color: #FAFAFA; }
              .horizontal-menu > ul > li .sub-menu li .sub-menu-child li:hover .sub-menu-sub-child {
                opacity: 1;
                visibility: visible; }
              .horizontal-menu > ul > li .sub-menu li .sub-menu-child li .sub-menu-sub-child {
                position: absolute;
                width: 100%;
                left: 15rem;
                top: 0;
                background-color: #FFFFFF;
                opacity: 0;
                visibility: hidden;
                transform-origin: left 0 0;
                transition: all 0.3s ease-in-out 0s;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.09); }
          .horizontal-menu > ul > li .sub-menu li .deep-level {
            width: 500px;
            height: 350px; }
            .horizontal-menu > ul > li .sub-menu li .deep-level li {
              display: inline-block;
              width: 32%; }
          .horizontal-menu > ul > li .sub-menu li a {
            color: #464D69;
            padding: 0.8125rem 0.625rem 0.8125rem 1.563rem;
            font-size: 0.875rem; }
            .horizontal-menu > ul > li .sub-menu li a i {
              margin-right: 0.625rem; }

@media (max-width: 965px) and (min-width: 320px) {
  .menu-title {
    display: none; }
  .horizontal-menu > ul > li > a {
    font-size: 1.2rem; } }

@media (max-width: 780px) and (min-width: 320px) {
  .horizontal-menu > ul > li .sub-menu {
    width: 12rem; }
    .horizontal-menu > ul > li .sub-menu li a {
      padding: 0.8125rem 0.625rem 0.8125rem 0.563rem; }
      .horizontal-menu > ul > li .sub-menu li a i {
        display: none; }
    .horizontal-menu > ul > li .sub-menu li .sub-menu-child {
      left: 12rem; }
  .horizontal-menu > ul > li .deep-level li {
    width: 26% !important; }
    .horizontal-menu > ul > li .deep-level li a {
      padding: 0.5rem !important; } }

@media (max-width: 700px) and (min-width: 320px) {
  .horizontal-menu > ul > li {
    position: static;
    flex: 1 1 0; }
    .horizontal-menu > ul > li .sub-menu {
      width: 100%;
      transition: all 0.5s ease-in-out 0s; }
      .horizontal-menu > ul > li .sub-menu li .sub-menu-child {
        left: 0;
        z-index: 999;
        position: static;
        max-height: 0; }
        .horizontal-menu > ul > li .sub-menu li .sub-menu-child li a {
          padding-left: 1.875rem; }
      .horizontal-menu > ul > li .sub-menu li:hover .sub-menu-child {
        max-height: 1500px; } }

@media (max-width: 473px) {
  .horizontal-menu > ul > li > a {
    padding: 0rem 0rem 0rem 0rem;
    line-height: 3.65; } }

/*======== Agency Menu Layout ========*/
.app-boxed .app-container {
  margin: 0 auto; }
  .app-boxed .app-container .app-header {
    padding: 1rem 0;
    margin: 0 1rem; }
    .app-boxed .app-container .app-header .rct-header {
      background-color: transparent !important;
      box-shadow: none !important; }
      .app-boxed .app-container .app-header .rct-header > div {
        padding: 0 !important; }
      .app-boxed .app-container .app-header .rct-header .site-logo {
        background-color: transparent !important;
        padding: 0 !important; }
      .app-boxed .app-container .app-header .rct-header .navbar-right li.summary-icon {
        display: none; }
      .app-boxed .app-container .app-header .rct-header .navbar-right li button,
      .app-boxed .app-container .app-header .rct-header .navbar-right li a i,
      .app-boxed .app-container .app-header .rct-header .navbar-right li a button {
        color: #fff; }
      .app-boxed .app-container .app-header .rct-header .navbar-right li .dropdown-menu ul li button,
      .app-boxed .app-container .app-header .rct-header .navbar-right li .dropdown-menu ul li a i {
        color: #464D69; }
  .app-boxed .app-container .rct-page {
    background-color: #F4F7FA;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 1rem; }
    .app-boxed .app-container .rct-page .agency-menu {
      height: 4.688rem;
      padding-right: 1.5rem; }
      .app-boxed .app-container .rct-page .agency-menu .nav-link span {
        transition: color 0.2s ease; }
      .app-boxed .app-container .rct-page .agency-menu .nav .nav-item:hover > .nav-link,
      .app-boxed .app-container .rct-page .agency-menu .nav .nav-item:hover > .nav-link span {
        color: #5D92F4 !important; }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item > .nav-link {
        height: 4.688rem; }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item > .nav-link i {
          font-size: 1.25rem; }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item.nav-item-active:after,
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu:after,
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child:after,
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu-child .nav-item > .sub-menu-sub-child:after {
        content: "";
        position: absolute;
        border: 9px solid black;
        border-color: transparent transparent #FFFFFF #FFFFFF;
        box-shadow: -3px 3.5px 7px rgba(204, 204, 204, 0.25);
        z-index: 4;
        transition: all 0.2s ease; }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu,
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu-child,
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu-sub-child {
        box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.2);
        border-radius: 5px;
        transform: translateY(2rem);
        transition: transform 0.2s ease-in; }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu {
        top: 4.75rem;
        left: 1.5rem; }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu > .nav-item {
          position: relative; }
          .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu > .nav-item:after {
            content: "";
            left: 0;
            top: 0;
            bottom: 0;
            border-left: 5px solid #5D92F4;
            position: absolute;
            opacity: 0;
            transition: all 0.3s ease-in-out; }
          .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu > .nav-item:hover:after {
            opacity: 1; }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu li:first-child .nav-link {
          border-radius: 5px 5px 0 0; }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu li:last-child .nav-link {
          border-radius: 0 0 5px 5px; }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu li .deep-level {
          width: 500px !important; }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu:after {
          top: -0.5rem;
          left: 2rem;
          transform: rotate(135deg); }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item .nav-link {
          display: flex;
          align-items: center; }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child {
          left: 15.8rem;
          width: 75%; }
          .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child:after {
            top: 1rem;
            left: -0.5rem;
            transform: rotate(45deg); }
          .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child li .sub-menu-sub-child {
            width: 75%;
            left: 12rem; }
            .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child li .sub-menu-sub-child li:hover > .nav-link,
            .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child li .sub-menu-sub-child li:hover > .nav-link span {
              color: #5D92F4 !important; }
            .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child li .sub-menu-sub-child:after {
              top: 1rem;
              left: -0.5rem;
              transform: rotate(45deg); }
          .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child li:hover > .sub-menu-sub-child {
            transform: translateY(0rem); }
        .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item:hover > .sub-menu-child {
          transform: translateY(0rem); }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item:hover .sub-menu {
        transform: translateY(0); }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item.nav-item-active:hover:after {
        content: none; }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item.nav-item-active > .nav-link,
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item.nav-item-active > .nav-link span {
        color: #5D92F4 !important; }
      .app-boxed .app-container .rct-page .agency-menu .nav > .nav-item.nav-item-active:after {
        top: 4rem;
        left: 3.55rem;
        transform: rotate(-45deg); }
  .app-boxed .app-container .rct-footer {
    background-color: transparent !important;
    padding-left: 0;
    padding-right: 0;
    margin: 0 1rem; }
    .app-boxed .app-container .rct-footer * {
      color: #fff; }
  .app-boxed .app-container .fixed-plugin .agency-bg {
    display: block !important; }
    .app-boxed .app-container .fixed-plugin .agency-bg li.active .badge {
      border: 3px solid #5D92F4;
      box-shadow: 0 0 5px #5D92F4; }

.app-boxed .mail-sidebar-wrap .user-wrap,
.app-boxed .todo-sidebar-wrap .user-wrap,
.app-boxed .chat-sidebar .user-wrap {
  height: auto !important; }

@media (min-width: 1400px) {
  .app-boxed .app-container {
    width: 1500px; }
  .app-boxed .rct-footer,
  .app-boxed .app-header,
  .app-boxed .rct-page {
    margin: 0 1.875rem; } }

@media (min-width: 1208px) {
  .app-boxed .agency-menu .hamburger-icon {
    display: none; }
  .app-boxed .fixed-plugin .sidebar-overlay,
  .app-boxed .fixed-plugin .sidebar-color,
  .app-boxed .fixed-plugin .sidebar-img,
  .app-boxed .fixed-plugin .sidebar-img-check,
  .app-boxed .fixed-plugin .background-img,
  .app-boxed .fixed-plugin .mini-sidebar-option,
  .app-boxed .fixed-plugin .box-layout-option {
    display: none; } }

@media (max-width: 1207px) {
  .app-boxed .app-header {
    padding: 0 !important; }
  .app-boxed .agency-menu {
    padding: 0 1.5rem;
    height: 3.5rem !important; }
    .app-boxed .agency-menu .nav {
      display: none; }
    .app-boxed .agency-menu .hamburger-icon {
      display: block; }
  .app-boxed .rct-page > .rct-scroll {
    height: calc(100vh - 9.75rem) !important; }
  .app-boxed .rct-footer {
    padding: 0.5rem 0;
    justify-content: center !important; }
    .app-boxed .rct-footer ul {
      display: none !important; } }

@media (max-width: 500px) {
  .app-boxed .rct-header .site-logo {
    display: block !important; }
    .app-boxed .rct-header .site-logo .logo-normal {
      display: none !important; } }

@media (max-width: 1050px) {
  .app-boxed .horizontal-menu > ul > li > a {
    padding: 0.5rem 1rem !important; } }

@media (max-width: 1200px) and (min-width: 900px) {
  .agency-menu .nav > .nav-item:last-child > .sub-menu > .nav-item:last-child:hover > .nav-link:after {
    transform: rotate(90deg); }
  .agency-menu .nav > .nav-item:last-child > .sub-menu > .nav-item:last-child .sub-menu-child {
    left: 0 !important;
    top: 3rem !important;
    width: 100% !important; }
    .agency-menu .nav > .nav-item:last-child > .sub-menu > .nav-item:last-child .sub-menu-child:after {
      content: none !important; }
    .agency-menu .nav > .nav-item:last-child > .sub-menu > .nav-item:last-child .sub-menu-child li .sub-menu-sub-child {
      left: 15.8rem !important; } }

.app-boxed.app-boxed-v2 {
  animation: changeBg 25s linear infinite;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (max-width: 1300px) and (min-width: 1150px) {
    .app-boxed.app-boxed-v2 .activity-widget .rct-scroll {
      max-height: 360px !important; } }
  @media (max-width: 1149px) and (min-width: 991px) {
    .app-boxed.app-boxed-v2 .activity-widget .rct-scroll {
      max-height: 330px !important; } }
  .app-boxed.app-boxed-v2 .fixed-plugin .agency-bg {
    display: none !important; }

/*================= Change Bg ===================*/
@keyframes changeBg {
  0% {
    background-image: url(/static/media/bg-1.4a11e228.jpg); }
  20% {
    background-image: url(/static/media/bg-2.081d7e01.jpg); }
  40% {
    background-image: url(/static/media/bg-3.75642eaf.jpg); }
  60% {
    background-image: url(/static/media/bg-4.7a9f77fa.jpg); }
  80% {
    background-image: url(/static/media/bg-5.179add63.jpg); }
  100% {
    background-image: url(/static/media/bg-1.4a11e228.jpg); } }

/*======== App Global (Main Structure) Mention Here =======*/
#root, .app {
  height: 100%;
  width: 100%; }

.app-main-container {
  display: flex;
  flex-wrap: nowrap; }

.rct-page-content {
  padding: 24px; }

.rct-footer {
  box-shadow: none;
  background-color: #fff;
  padding: 1.25rem;
  z-index: 9; }
  .rct-footer h5 {
    margin-bottom: 0;
    color: #727891;
    font-size: 0.875rem; }

.rct-block {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  border: none;
  transition: all 0.4s ease-in-out 0s;
  margin-bottom: 24px;
  position: relative; }
  .rct-block .rct-block-content {
    padding: 1.25rem 1.25rem; }
  .rct-block:hover {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08); }

.rct-block-title, .faq-page-wrapper .panel-heading {
  padding: 0.9375rem 1.25rem;
  position: relative; }
  .rct-block-title .badge, .faq-page-wrapper .panel-heading .badge {
    vertical-align: middle; }
  .rct-block-title h4, .faq-page-wrapper .panel-heading h4 {
    margin-bottom: 0;
    color: #464D69;
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 30px; }
  .rct-block-title .contextual-link, .faq-page-wrapper .panel-heading .contextual-link {
    position: absolute;
    top: 20px;
    right: 20px; }
    .rct-block-title .contextual-link a, .faq-page-wrapper .panel-heading .contextual-link a {
      color: #727891;
      font-size: 1rem;
      margin-right: 15px; }
      .rct-block-title .contextual-link a:hover, .faq-page-wrapper .panel-heading .contextual-link a:hover {
        color: #5D92F4; }
      .rct-block-title .contextual-link a:last-child, .faq-page-wrapper .panel-heading .contextual-link a:last-child {
        margin-right: 0; }

.rct-block-footer {
  background-color: #fff;
  padding: 0.9375rem 1.25rem;
  border-top: 1px solid #EBEDF2; }

.card-footer {
  background-color: #fff; }

.sub-title {
  padding: 0.9375rem 1.25rem;
  margin-bottom: 1.25rem; }
  .sub-title h4 {
    margin-bottom: 0;
    color: #464D69;
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 500; }

[class*="gradient-"] h4, [class^="gradient-"] h4 {
  color: #fff;
  border-color: #fff; }

/*========== Page Breadcrumb ============*/
.page-title {
  margin-bottom: 1.875rem; }
  .page-title .page-title-wrap > i {
    color: #464D69;
    vertical-align: 3px;
    margin-right: 10px; }
  .page-title h2 {
    font-size: 1.875rem;
    color: #464D69;
    display: inline-block;
    margin: 0; }
  .page-title .rct-creadcrumb {
    color: #464D69;
    font-size: 14px; }
    .page-title .rct-creadcrumb i {
      font-size: 12px;
      margin: 0 5px; }

/*========== Dashboard Scss ========*/
.dash-cards .card-top-action, .dash-cards-lg .card-top-action {
  border-radius: 100%;
  color: #fff;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.21);
  position: absolute;
  top: -10px;
  z-index: 9;
  left: 25px; }

.dash-cards [class*="col-"], .dash-cards-lg [class*="col-"] {
  padding: 0 0.625rem; }

.dash-cards .card, .dash-cards-lg .card {
  background-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  border: none;
  border-radius: 5px;
  margin: 0.125rem 0.125rem 24px 0.125rem;
  transition: all 0.4s ease-in-out 0s;
  padding: 1.25rem 1.25rem;
  position: relative;
  min-height: 190px; }
  .dash-cards .card:hover, .dash-cards-lg .card:hover {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08); }
  .dash-cards .card .card-title, .dash-cards-lg .card .card-title {
    color: #464D69;
    margin-bottom: 1.25rem; }
  .dash-cards .card > span, .dash-cards-lg .card > span {
    color: #464D69; }

.rct-weather-widget {
  min-height: 190px; }
  .rct-weather-widget .weather-content {
    text-align: justify; }
  .rct-weather-widget .dayTime {
    line-height: 30px; }
  .rct-weather-widget i {
    line-height: 110px; }
  .rct-weather-widget h2 {
    font-size: 2rem;
    font-weight: 500; }
  .rct-weather-widget .black-overlay {
    border-radius: 5px; }

.black-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.default-btn-group .btn-secondary {
  background: #fff;
  color: #464D69;
  border-color: #EBEDF2; }
  .default-btn-group .btn-secondary:hover, .default-btn-group .btn-secondary.active, .default-btn-group .btn-secondary:focus {
    background-color: #F4F7FA !important;
    color: #464D69 !important;
    border-color: #EBEDF2 !important; }

.ladgend {
  width: 12px;
  display: inline-block;
  height: 12px;
  border-radius: 30px;
  margin-right: 0.625rem;
  vertical-align: middle; }

.import-report {
  font-size: 1.375rem;
  color: #DEE4E8; }
  .import-report:hover {
    color: #5D92F4; }

.card-action a {
  color: #727891;
  font-size: 0.75rem;
  margin-right: 0.9375rem; }
  .card-action a:last-child {
    margin-right: 0; }
  .card-action a i {
    font-size: 0.875rem;
    vertical-align: middle;
    margin-right: 0.625rem; }

.card-gradient-primary .card-heading {
  color: #fff;
  padding: 3.125rem;
  line-height: 40px; }

.top-selling .product-img img {
  width: 275px !important;
  margin: 0 auto; }

.editor label {
  padding: 0.375rem !important; }

.editor input {
  border: none;
  box-shadow: none; }
  .editor input:focus {
    box-shadow: none; }

.editor .form-wrap {
  border-bottom: 1px solid #EBEDF2; }

.counter-point {
  font-size: 1.5rem;
  color: #727891; }

.featured-section-icon {
  color: #DEE4E8;
  font-size: 3.75rem; }

.responsive-div {
  overflow-x: scroll; }

.current-widget {
  padding: 1.875rem;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 24px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  transition: all 0.4s ease-in-out 0s; }
  .current-widget:hover {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08); }
  .current-widget h3 {
    font-size: 1.875rem; }
  .current-widget h2 {
    font-size: 2.6rem;
    font-weight: 500; }
  .current-widget i {
    font-size: 3.125rem; }

.speedometer {
  width: 100% !important; }

.pt-xl {
  padding-top: 8rem; }

/*======== Responsive =======*/
@media (max-width: 1669px) and (min-width: 320px) {
  .dash-cards .media .mr-25 {
    margin-right: 0.625rem; }
  .dash-cards .media img {
    width: 50px;
    height: 50px; } }

@media (max-width: 1425px) and (min-width: 941px) {
  .rct-weather-widget .weather-content {
    text-align: center; }
  .rct-weather-widget h2, .rct-weather-widget h3, .rct-weather-widget h4 {
    font-size: 1rem;
    margin: 0; }
  .rct-weather-widget i {
    line-height: 70px;
    font-size: 2.5rem !important; } }

@media (max-width: 1560px) and (min-width: 320px) {
  .hover-action button, .hover-action a {
    min-height: 28px;
    max-width: 28px;
    height: 28px !important;
    font-size: 1rem !important; }
  .weather-top img {
    height: 220px; } }

@media (max-width: 1460px) and (min-width: 320px) {
  .product-img img {
    width: 300px !important;
    margin: 0 auto; }
  .current-widget h2 {
    font-size: 1.6rem; } }

@media (max-width: 1430px) {
  .rct-header > div {
    padding: 0 0.625rem; } }

@media (max-width: 1400px) {
  .counter-point ~ p {
    display: none; } }

@media (max-width: 1366px) and (min-width: 992px) {
  .current-widget {
    padding: 0.9375rem; }
    .current-widget i {
      font-size: 2.125rem; }
    .current-widget h2, .current-widget h3 {
      font-size: 1.8rem; }
    .current-widget h3 {
      margin-bottom: 0.625rem !important; } }

@media (max-width: 1300px) and (min-width: 992px) {
  .card-heading {
    padding: 1.125rem !important; }
  .rct-weather-widget .d-flex {
    display: inline-block !important; } }

@media (max-width: 1269px) {
  .new-customer-list li a {
    font-size: 1rem !important;
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important; }
  .featured-section-icon {
    font-size: 2.75rem; }
  .counter-point {
    font-size: 1.2rem; } }

@media (max-width: 1199px) and (min-width: 320px) {
  .fixed-plugin .app-settings > li:nth-of-type(2), .fixed-plugin .app-settings > li:nth-of-type(3) {
    display: none; } }

@media (max-width: 900px) and (min-width: 320px) {
  .navbar-right li a button, .navbar-right li button {
    width: 30px;
    height: 30px; }
    .navbar-right li a button .badge-top-right, .navbar-right li button .badge-top-right {
      top: -6px;
      right: 0; }
  .rct-dropdown.show .dropdown-menu, .notification-icon.show .dropdown-menu, .cart-icon.show .dropdown-menu {
    transform: translate3d(0, 40px, 0) !important; }
  .notification-icon .dropdown-menu, .cart-icon .dropdown-menu {
    right: -32px !important; }
  .rct-footer.d-flex {
    display: block !important;
    text-align: center;
    padding: 0.625rem; }
    .rct-footer.d-flex .footer-menus {
      margin-bottom: 0.625rem !important; }
      .rct-footer.d-flex .footer-menus li a {
        padding: 5px !important;
        min-width: 20px;
        min-height: 30px; } }

@media (max-width: 900px) and (min-width: 576px) {
  .w-xs-full {
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .w-xs-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important; } }

@media (max-width: 835px) {
  .navbar-left > li:nth-of-type(2), .navbar-right > li:last-child {
    display: none; } }

@media (max-width: 800px) and (min-width: 320px) {
  .dropdown-group-link img {
    margin-right: 0  !important; }
  .dropdown-group-link span {
    display: none; } }

@media (max-width: 800px) and (min-width: 576px) {
  .w-8-full {
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .w-8-half-block {
    max-width: 50% !important;
    flex-basis: 50% !important; } }

@media (max-width: 767px) and (min-width: 320px) {
  .rct-page-content {
    padding: 1.25rem; } }

@media (max-width: 650px) {
  .search-icon {
    width: auto; }
    .search-icon::before {
      left: 5px;
      top: -25px;
      font-size: 2rem;
      cursor: pointer; }
    .search-icon > div {
      display: none; } }

@media (max-width: 599px) {
  .to-do-list label {
    margin: 0;
    width: 30px; }
    .to-do-list label > span {
      width: 30px;
      height: 30px; }
  .new-customer-list .media .mr-15 {
    margin-right: 5px !important; }
  .dashboard-overlay {
    top: 57px; } }

@media (max-width: 575px) {
  .page-title-wrap, .breadcrumb {
    display: inline-block; }
  .breadcrumb {
    float: right;
    padding: 0 0.625rem; }
  .page-title h2 {
    font-size: 1.2rem; }
  .page-title .page-title-wrap i {
    vertical-align: -1px;
    margin-right: 5px; }
  .page-title .breadcrumb-item + .breadcrumb-item::before {
    padding: 0 0.3rem; }
  .page-title .rct-creadcrumb {
    font-size: 11px; }
  .all-mails-list .w-90 {
    width: 100% !important; } }

@media (max-width: 472px) {
  .navbar-right > li:nth-of-type(2) {
    display: none; }
  .navbar-right > li .dropdown-group-link {
    padding: 5px; }
    .navbar-right > li .dropdown-group-link.dropdown-toggle::after {
      display: none; }
  .chat-box-main img {
    width: 80px; }
  .page-title h2 {
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle; } }

/*========== All UI Element Style ==========*/
.leaflet-container {
  height: 400px;
  width: 100%; }

/*========== Alert Scss ==========*/
.icon-alert .alert {
  padding: 0; }
  .icon-alert .alert .alert-addon {
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    font-size: 1.563rem;
    width: 50px;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0; }
  .icon-alert .alert p {
    margin-bottom: 0;
    padding: 10px;
    display: inline-block;
    padding-left: 4.375rem; }

/*========= App Bar Scss ==========*/
.appbar-wrapper .hamburger span {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff; }

.appbar-wrapper .navbar-right > li:not(.dropdown) a {
  font-size: 1.875rem; }

/*========== Avatar Scss ==========*/
.avatar-wrapper [class^="border-"], .avatar-wrapper [class*="border-"] {
  border-width: 2px;
  border-style: solid; }

.avatar-wrapper ul li {
  margin-bottom: 10px; }

/*========== Buttons Scss ==========*/
.sub-heading {
  font-size: 14px;
  display: block;
  color: #727891;
  margin-bottom: 1.875rem;
  text-transform: capitalize; }

.btn *, a[role=button], button[type=button] {
  outline: none;
  text-transform: capitalize;
  font-weight: 400; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .btn {
  padding: 0.8rem 1rem !important;
  font-size: 1.1rem !important;
  line-height: 1.5 !important; }

[class^="btn-"]:focus, [class*="btn-"]:focus, button:focus {
  box-shadow: none !important;
  outline: none; }

.btn-warning, .btn-info {
  color: #fff; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning.active, .btn-info:hover, .btn-info:focus, .btn-info.active {
    color: #fff; }

.btn-icon i {
  font-size: 1.125rem;
  margin: 0 5px; }

.btn-small {
  padding: 4px 15px;
  font-size: 12px; }

.btn-xs {
  padding: 5px 10px !important;
  font-size: 12px !important;
  min-height: 30px !important; }

.btn-sm:not(.btn-group .btn), .btn-group-sm > .btn {
  font-size: 1rem !important;
  height: 2rem !important;
  width: 2rem !important;
  min-height: 2rem !important; }

.btn-transparent {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  border-radius: 40px !important;
  height: 52px !important; }

.btn-outline-default {
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  border-radius: 0.2rem;
  color: #464D69;
  padding: 4px 8px; }

/*========== Badge Scss ==========*/
.badge-xs {
  width: 0;
  height: 0;
  padding: 9px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  line-height: 0;
  font-size: 55%;
  position: absolute; }

.badge-sm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 3px 8px; }

.badge-lg {
  padding: 15px 25px !important; }

.badge-top-right {
  position: absolute;
  top: 4px;
  right: 5px; }

.badge:not(.badge-xs) {
  padding: 5px 15px 4px;
  color: #fff; }
  .badge:not(.badge-xs):hover, .badge:not(.badge-xs):focus, .badge:not(.badge-xs).active {
    color: #fff; }

.badge-default {
  background-color: #F5F9FB; }

/*========== Cards Scss ==========*/
.card-img-overlay {
  opacity: .8;
  color: #fff; }

/*========== Chip Scss ==========*/
.chip-wrapper:not([class*="chip-outline-"]) path {
  fill: #fff !important; }

.chip-wrapper:not([class*="chip-outline-"]) .rct-block-content > div {
  vertical-align: middle; }

/*======== GridList Scss =========*/
.gradient-overlay {
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent) !important; }

/*========== Lists Scss ==========*/
.list-wrapper .zmdi {
  line-height: 30px; }

.list-wrapper .listSection {
  background-color: #fff; }

/*========= Responsive ========*/
@media (max-width: 1024px) and (min-width: 320px) {
  .cardsmasonry-wrapper .card-columns {
    column-count: 2; } }

@media (max-width: 768px) {
  .app-wrapper .navbar-right li:last-child {
    display: none; } }

@media (max-width: 480px) {
  .app-wrapper .navbar-right li {
    width: 1.75rem; }
    .app-wrapper .navbar-right li a {
      font-size: 1.4rem; }
  .cardsmasonry-wrapper .card-columns {
    column-count: 1; } }

@media (max-width: 390px) {
  .app-wrapper .navbar-right li:nth-child(n+2) {
    display: none; } }

/*============ Advance Ui Elements ===========*/
.custom-tabs {
  border: none; }
  .custom-tabs .nav-item .nav-link {
    border: none;
    border-radius: 50px; }
    .custom-tabs .nav-item .nav-link:hover, .custom-tabs .nav-item .nav-link:focus, .custom-tabs .nav-item .nav-link.active {
      border: none;
      color: #464D69;
      background: rgba(0, 0, 0, 0.2); }

.sweet-alert .btn {
  padding: 0.3rem 2rem;
  font-size: 1rem; }

/*========= All form Elements Style =========*/
.input-group-prepend {
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }
  .input-group-prepend button {
    color: inherit; }

.input-lg {
  height: 52px;
  padding: 0.375rem 0.7rem; }

.input-lg-icon {
  height: 52px;
  padding: 0.375rem 2.7rem; }

.app-selectbox, .app-selectbox-sm {
  border: 1px solid none;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 52px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08); }
  .app-selectbox:before, .app-selectbox-sm:before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: -0.09em;
    right: 10px;
    width: 0;
    height: 0;
    border: 0.3em solid transparent;
    border-top-color: #464D69; }
  .app-selectbox select, .app-selectbox-sm select {
    padding: 0.3125rem 0.5rem;
    box-shadow: none;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-transform: capitalize;
    height: 52px !important; }
    .app-selectbox select:focus, .app-selectbox-sm select:focus {
      outline: none; }

.app-selectbox-sm {
  height: calc(2.25rem + 2px); }
  .app-selectbox-sm select {
    height: calc(2.25rem + 2px) !important; }

/*============ Tables Style ===========*/
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto; }

table thead tr[class*='bg-'] td, table thead tr[class*='bg-'] th, table tbody tr[class*='bg-'] td, table tbody tr[class*='bg-'] th {
  color: #fff !important; }

table thead {
  background-color: #F4F7FA; }
  table thead tr th {
    font-size: 14px;
    font-weight: 500;
    color: #464D69;
    border-width: 1px !important; }

table tbody tr td {
  font-size: 14px;
  font-weight: 300; }

table thead tr th, table thead tr td, table tbody tr th, table tbody tr td, table tfoot tr th, table tfoot tr td {
  vertical-align: middle !important; }

table .table-action a {
  color: #727891;
  font-size: 14px;
  margin-right: 10px; }
  table .table-action a:last-child {
    margin-right: 0; }
  table .table-action a:hover {
    color: #5D92F4; }

.table-lg tr td {
  padding: 1.438rem 0.75rem; }

.flip-scroll table tr th {
  color: #fff; }

.table-borderless {
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2; }
  .table-borderless thead tr th {
    font-size: 1.125rem;
    color: #464D69;
    padding: 1.25rem 0.75rem; }
  .table-borderless tbody tr td {
    border: none; }

@media only screen and (max-width: 767px) {
  .unseen table td:nth-child(4), .unseen table td:nth-child(7), .unseen table td:nth-child(8), .unseen table th:nth-child(4), .unseen table th:nth-child(7), .unseen table th:nth-child(8) {
    display: none; } }

@media only screen and (max-width: 991px) {
  .unseen table td:nth-child(2), .unseen table th:nth-child(2) {
    display: none; }
  .flip-scroll * html .flip-content {
    zoom: 1; }
  .flip-scroll table {
    display: block; }
  .flip-scroll thead {
    display: block;
    float: left; }
  .flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap; }
    .flip-scroll tbody tr {
      display: inline-block;
      margin-left: -5px;
      border-left: 1px solid #EBEDF2; }
  .flip-scroll td, .flip-scroll th {
    border-bottom: 1px solid #EBEDF2;
    border-right: 1px solid #EBEDF2;
    padding: 5px;
    width: auto;
    display: block;
    text-align: right .flip-scroll td, .flip-scroll th; }
    .flip-scroll td :last-child, .flip-scroll th :last-child {
      border-bottom: 1px solid #EBEDF2; } }

/*============ Editor Style =============*/
.rct-editor-wrapper .rdw-editor-toolbar {
  border: 1px solid #EBEDF2 !important;
  background-color: #5D92F4; }

.rct-editor-wrapper .rct-wysiwyg-editor {
  height: 300px !important;
  border: 1px solid #EBEDF2 !important;
  padding: 5px 15px !important;
  background: #fff; }

.quill {
  overflow: hidden; }

.ql-container {
  height: 300px; }

.ql-container.ql-snow, .ql-toolbar.ql-snow {
  border-color: #EBEDF2; }

/*========== Drag And Drop Scss =======*/
.table-dragula tbody tr {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }
  .table-dragula tbody tr td.w-5 {
    text-align: center; }

.drag-list-wrapper li {
  background: #5D92F4;
  background: rgba(93, 146, 244, 0.2);
  border: none; }
  .drag-list-wrapper li .drag-list {
    background: #5D92F4;
    background: rgba(93, 146, 244, 0.9);
    padding: 0.9375rem 0.625rem;
    color: #FAFAFA; }

/*=========== Pages Style ==========*/
/*================= Mail =================*/
.rct-mail-wrapper header,
.todo-wrapper header {
  background-color: #fff;
  box-shadow: none;
  border-top: 1px solid #EBEDF2; }

.rct-mail-wrapper .list-wrap,
.todo-wrapper .list-wrap {
  margin: -24px; }
  .rct-mail-wrapper .list-wrap .top-head,
  .todo-wrapper .list-wrap .top-head {
    border-bottom: 1px solid #EBEDF2;
    border-top: 1px solid #EBEDF2;
    padding: 0.625rem 1rem; }
  .rct-mail-wrapper .list-wrap ul .list-item,
  .todo-wrapper .list-wrap ul .list-item {
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: #fff; }
    .rct-mail-wrapper .list-wrap ul .list-item:not(:last-child),
    .todo-wrapper .list-wrap ul .list-item:not(:last-child) {
      border-bottom: 1px solid #EBEDF2; }
    .rct-mail-wrapper .list-wrap ul .list-item:hover,
    .todo-wrapper .list-wrap ul .list-item:hover {
      background-color: #E1EBFF; }

.mail-sidebar-wrap,
.todo-sidebar-wrap,
.chat-sidebar {
  overflow: hidden; }
  .mail-sidebar-wrap .user-wrap,
  .todo-sidebar-wrap .user-wrap,
  .chat-sidebar .user-wrap {
    background: url(/static/media/profile-banner.a87abcf8.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
    height: 150px; }
    .mail-sidebar-wrap .user-wrap .media,
    .todo-sidebar-wrap .user-wrap .media,
    .chat-sidebar .user-wrap .media {
      width: 100%;
      padding: 1rem;
      background-color: rgba(0, 0, 0, 0.7); }
  .mail-sidebar-wrap .sidebar-filters-wrap,
  .todo-sidebar-wrap .sidebar-filters-wrap,
  .chat-sidebar .sidebar-filters-wrap {
    padding: 0 1.25rem 1.25rem; }
    .mail-sidebar-wrap .sidebar-filters-wrap .filters,
    .todo-sidebar-wrap .sidebar-filters-wrap .filters,
    .chat-sidebar .sidebar-filters-wrap .filters {
      padding: 0;
      margin: 0; }
      .mail-sidebar-wrap .sidebar-filters-wrap .filters > div i,
      .todo-sidebar-wrap .sidebar-filters-wrap .filters > div i,
      .chat-sidebar .sidebar-filters-wrap .filters > div i {
        font-size: 1.25rem; }
      .mail-sidebar-wrap .sidebar-filters-wrap .filters > div .filter-title,
      .todo-sidebar-wrap .sidebar-filters-wrap .filters > div .filter-title,
      .chat-sidebar .sidebar-filters-wrap .filters > div .filter-title {
        font-size: 0.875rem; }

.email-detail-page-warrper .mail-detail {
  padding: 1rem 1.5rem 3rem 6rem;
  border-bottom: 1px solid #EBEDF2; }

.mail-reply-wrap textarea,
.task-foot textarea {
  max-height: 3rem;
  line-height: 2; }

/*================ Todo List ================*/
@media (max-width: 959px) {
  .todo-wrapper header {
    margin-left: 0 !important; } }

.todo-wrapper .list-wrap ul li > .media > label {
  margin: 0 1rem 0 0; }

.todo-wrapper .list-wrap ul li > .media .media-body span {
  margin: 0 0.25rem; }

.todo-wrapper .list-wrap .task-head {
  padding: 0.625rem 1rem; }

.todo-wrapper .task-detail .task-detail-content .task-content .task-box {
  position: relative;
  border: 1px solid #EBEDF2;
  padding: 0.625rem 1.875rem 0.625rem 0.625rem; }

.todo-wrapper .task-detail .task-detail-content .task-content .task-btn {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  position: absolute;
  top: -15px;
  right: -5px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 1rem; }
  .todo-wrapper .task-detail .task-detail-content .task-content .task-btn span {
    color: #5D92F4 !important; }

.todo-wrapper .task-detail .task-comment ul li {
  margin-bottom: 1rem; }

/*================== Chat ===================*/
.chat-wrapper .chat-main-body .chat-head {
  padding: 0.625rem 1.5rem;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09); }
  @media (max-width: 450px) {
    .chat-wrapper .chat-main-body .chat-head {
      padding: 0.75rem; }
      .chat-wrapper .chat-main-body .chat-head .video-icon {
        display: none; } }
  .chat-wrapper .chat-main-body .chat-head button {
    height: 2.25rem;
    width: 2.25rem;
    font-size: 1.25rem;
    margin: 0 0.25rem; }

.chat-wrapper .chat-main-body .chat-body .chat-bubble {
  max-width: 600px;
  padding: 0.625rem;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  display: flex;
  align-items: center; }
  .chat-wrapper .chat-main-body .chat-body .chat-bubble.even {
    border-radius: 20px 20px 20px 0; }
  .chat-wrapper .chat-main-body .chat-body .chat-bubble.odd {
    border-radius: 20px 20px 0px 20px; }

.chat-wrapper .chat-main-body .chat-footer .msg-input,
.chat-wrapper .chat-main-body .chat-footer .submit-btn {
  border-radius: 100px !important; }

.chat-wrapper .chat-content {
  position: relative;
  height: 100vh; }
  .chat-wrapper .chat-content .chat-box-main {
    position: absolute;
    top: 0;
    bottom: 10rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center; }

.chat-sidebar .user-wrap {
  height: auto; }

.chat-sidebar .search-wrapper .search-icon {
  position: absolute;
  right: 1rem;
  top: 0.9rem; }

.chat-sidebar .chat-list ul .user-list-item {
  cursor: pointer;
  padding: 1rem;
  transition: all 0.3s ease-in-out; }
  .chat-sidebar .chat-list ul .user-list-item .media-left .badge {
    position: absolute;
    bottom: 0px; }
  .chat-sidebar .chat-list ul .user-list-item .msg-count .badge {
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0; }
  .chat-sidebar .chat-list ul .user-list-item.item-active {
    background-color: #464D69;
    color: #fff; }
  .chat-sidebar .chat-list ul .user-list-item:hover:not(.item-active) {
    background-color: #E1EBFF; }

/*================ Gallery =================*/
figure.img-wrapper {
  position: relative;
  overflow: hidden;
  text-align: center;
  -webkit-perspective: 50em;
  perspective: 50em; }
  figure.img-wrapper * {
    -webkit-box-sizing: padding-box;
    box-sizing: padding-box;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out; }
  figure.img-wrapper img {
    max-width: 100%;
    vertical-align: top; }
  figure.img-wrapper figcaption {
    top: 50%;
    left: 20px;
    right: 20px;
    position: absolute;
    opacity: 0;
    z-index: 1; }
  figure.img-wrapper h2,
  figure.img-wrapper h4 {
    margin: 0; }
  figure.img-wrapper h2 {
    font-weight: 600; }
  figure.img-wrapper h4 {
    text-transform: uppercase; }
  figure.img-wrapper:after {
    background-color: #fff;
    position: absolute;
    content: "";
    display: block;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 0; }
  figure.img-wrapper a {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1; }
  figure.img-wrapper:hover figcaption,
  figure.img-wrapper.hover figcaption {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s; }
  figure.img-wrapper:hover:after, figure.img-wrapper.hover:after {
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
    opacity: 0.9; }

/*======= Pricing Scss =======*/
.rct-tooltip {
  width: 250px; }

.pricing-wrapper button {
  text-transform: capitalize; }

.pricing-wrapper .switch {
  vertical-align: middle;
  margin: 0 1.25rem; }

.price-list {
  margin-bottom: 6.25rem; }
  .price-list .rct-block {
    padding: 3.125rem; }
    .price-list .rct-block p {
      margin-bottom: 1.25rem; }

.pricing-title {
  font-size: 1.875rem;
  margin-bottom: 1.25rem;
  text-transform: capitalize; }

.amount-title {
  font-weight: 900;
  font-size: 2.25rem; }

.fixed-pricing {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  transition: all 0.4s ease-in-out 0s;
  margin-bottom: 24px;
  position: relative; }
  .fixed-pricing .pricing-box {
    border-right: 1px solid #EBEDF2; }

.pricing-body,
.pricing-head {
  padding: 1.875rem 3.125rem; }

.plan-info {
  background-color: #F5F9FB;
  padding: 0.625rem 3.125rem; }

.plan-info-listing li i {
  margin-right: 0.9375rem; }

.price-detail, .plan-info-listing {
  margin-bottom: 2.188rem; }
  .price-detail li, .plan-info-listing li {
    margin-bottom: 0.625rem;
    font-size: 0.875rem; }

.testimonial-wrapper .media-body p {
  font-style: italic;
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  line-height: 30px; }

.testimonial-wrapper .user-meta span {
  color: #A5A7B2; }

.faq-wrapper .card-title {
  margin-bottom: 1.25rem;
  font-size: 1rem; }

.faq-wrapper .card-text {
  font-size: 0.875rem;
  color: #A5A7B2; }

.testimonial-slider .slick-dots {
  text-align: left;
  bottom: -50px;
  left: 185px; }

/*======== About Scss =========*/
.section-title::after {
  content: "";
  width: 70px;
  height: 1px;
  display: inline-block;
  border: 2px solid #FF2B72;
  position: absolute;
  left: 0;
  right: 0;
  margin: 6px auto;
  border-radius: 30px;
  transition: all .3s ease-in-out 0s; }

.card-content p {
  color: #727891;
  text-align: justify; }

.about-detail .card-image img {
  width: 100%; }

/*======== Calender Scss =======*/
.calendar-wrapper .rbc-calendar {
  display: block; }

.rbc-month-row {
  min-height: 150px; }

.rbc-event {
  background-color: #5D92F4;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #5D92F4 !important; }

.rbc-toolbar button.rbc-active,
.rbc-toolbar button:hover {
  background-color: #5D92F4;
  color: #fff; }

@media (max-width: 700px) and (min-width: 320px) {
  .rbc-toolbar {
    font-size: 12px;
    flex-direction: column;
    align-items: start; }
    .rbc-toolbar span {
      margin: 5px 0; }
  .rbc-month-row {
    min-height: 70px; } }

/*========= Terms Condition =====*/
.terms-wrapper {
  overflow-y: auto;
  height: 100%; }

.terms-conditions-rules p {
  margin-bottom: 1.25rem; }

.terms-conditions-rules .sub-order {
  padding-left: 3.75rem; }

.terms-conditions-rules ol {
  padding-left: 2.5rem; }
  .terms-conditions-rules ol li {
    margin-bottom: 0.9375rem; }

.terms-conditions-rules .heading {
  font-size: 1.5rem;
  margin-bottom: 1.563rem; }

.terms-conditions-rules .nest-heading {
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0.625rem;
  padding-left: 1.875rem; }

.terms-conditions-rules p,
.terms-conditions-rules ol li,
.terms-conditions-rules .nest-heading {
  font-size: 0.875rem; }

/*========= Feedback ============*/
.feedback-wrapper .heading {
  font-size: 1.5rem;
  margin-bottom: 1.563rem;
  color: #A5A7B2; }

.like-box {
  border-top: 2px solid #A5A7B2;
  border-bottom: 2px solid #A5A7B2;
  border-left: 2px solid #A5A7B2;
  border-right: 2px solid #A5A7B2;
  width: 100px;
  height: 100px;
  text-align: center;
  margin-right: 3.25rem;
  padding: 0.625rem; }

.comment-sec .media {
  margin-bottom: 1.875rem; }

.comment-sec .comment-box {
  background-color: #F4F7FA;
  padding: 0.625rem; }
  .comment-sec .comment-box p {
    font-size: 0.875rem; }

.rct-tabs header {
  background-color: #fff;
  box-shadow: none;
  color: #A5A7B2;
  border-bottom: 1px solid #EBEDF2; }
  .rct-tabs header span {
    font-size: 0.875rem; }

.rct-tabs ul li {
  margin: 0 -24px !important;
  padding: 0.9375rem 1.25rem;
  border-bottom: 1px solid #EBEDF2; }

.rct-tabs .form-group .col-form-label {
  font-size: 1rem; }

.rct-tabs [role="tablist"] button {
  color: #464D69; }

.social-action a {
  padding: 5px; }

.list-action a, .social-action a,
.list-action button,
.social-action button {
  color: #A5A7B2;
  font-size: 1.125rem; }
  .list-action a:hover, .social-action a:hover,
  .list-action button:hover,
  .social-action button:hover {
    color: #5D92F4; }

.list-action a, .social-action a {
  margin-right: 0.625rem; }
  .list-action a:last-child, .social-action a:last-child {
    margin-right: 0; }

.feed-content {
  border-left: 3px solid #5D92F4; }
  .feed-content a {
    margin-bottom: 0;
    color: #677080;
    padding: 0.625rem;
    font-size: 1.125rem;
    line-height: 30px; }
    .feed-content a:hover {
      color: #5D92F4; }

.search-filter {
  padding: 1.25rem; }
  .search-filter .form-group {
    margin-right: 0.9375rem;
    display: inline-block;
    vertical-align: top; }

/*========= Report =============*/
.check-circle {
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #00D014;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  margin-right: 1.25rem; }

.report-list li {
  border-bottom: 1px solid #EBEDF2;
  padding: 1.25rem; }
  .report-list li:last-child {
    border-bottom: none; }
  .report-list li h2 {
    color: #464D69;
    font-size: 2rem; }

.report-status {
  background-color: #fff;
  border-top: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  border-radius: 5px; }
  .report-status > ul {
    border-bottom: 1px solid #EBEDF2;
    border-top: 1px solid #EBEDF2; }
    .report-status > ul > li {
      border-right: 1px solid #EBEDF2;
      margin: 0 !important;
      padding: 1.875rem; }
      .report-status > ul > li:last-child {
        border-right: none; }
      .report-status > ul > li h4 {
        color: #727891; }
      .report-status > ul > li .title {
        color: #464D69; }

.top-filter .form-group {
  float: left;
  margin-right: 0.9375rem; }

/*========= Faq(s) =============*/
.faq-page-wrapper .panel-heading {
  border-bottom: none; }
  .faq-page-wrapper .panel-heading > div {
    margin: 0; }
  .faq-page-wrapper .panel-heading h4 {
    color: #5D92F4;
    padding: 0; }

/*======== Responsive =========*/
@media (max-width: 1767px) and (min-width: 320px) {
  .about-detail .p-70 {
    padding: 1.25rem 1.25rem !important; }
  .about-content .title {
    line-height: 1.2; } }

@media (max-width: 1500px) and (min-width: 320px) {
  .about-content .title {
    font-size: 1.7rem; }
  .about-content .mb-50 {
    margin-bottom: 15px !important; } }

@media (max-width: 1400px) and (min-width: 320px) {
  .pricing-body,
  .pricing-head {
    padding: 1.875rem 1.125rem; }
  .price-list .rct-block {
    padding: 1.125rem; }
  .content-wrapper .title {
    line-height: 1.3; }
  .media-full img {
    width: 200px; } }

@media (max-width: 1300px) and (min-width: 320px) {
  .userProfile-wrapper .profile-top .profile-content {
    top: 20px !important;
    left: 30px !important; } }

@media (max-width: 1270px) {
  .about-content .title {
    font-size: 1.375rem; } }

@media (max-width: 765px) and (min-width: 320px) {
  .profile-content img {
    width: 90px;
    height: 90px; }
  .profile-content .media-body.pt-25 {
    padding-top: 5px !important; } }

@media (max-width: 720px) and (min-width: 320px) {
  .report-status > ul > li {
    padding: .8rem; }
    .report-status > ul > li h2 {
      font-size: 2rem; } }

@media (max-width: 700px) and (min-width: 320px) {
  .testimonial-wrapper.mb-50 {
    margin-bottom: 1.875rem; }
  .testimonial-wrapper .media {
    display: inline-block;
    text-align: center; }
    .testimonial-wrapper .media img {
      display: inline-block !important;
      margin: 0 auto 0.9375rem;
      margin-right: 0 !important; }
  .feedback-wrapper .rct-tabs ul li {
    display: inline-block !important; }
    .feedback-wrapper .rct-tabs ul li .list-action, .feedback-wrapper .rct-tabs ul li .social-action {
      text-align: center; }
  .feedback-wrapper .rct-tabs .media {
    display: inline-block;
    text-align: center; }
    .feedback-wrapper .rct-tabs .media img {
      margin-right: 0 !important;
      margin-bottom: 0.9375rem; } }

@media (max-width: 560px) and (min-width: 320px) {
  .report-status > ul > li {
    width: 100%;
    border-bottom: 1px solid #EBEDF2;
    border-right: none; }
    .report-status > ul > li:last-child {
      border-bottom: none; } }

@media (max-width: 575px) and (min-width: 320px) {
  .report-status .top-filter .w-20 {
    width: 35% !important; }
  .feedback-wrapper .comment-sec,
  .feedback-wrapper form {
    width: 100% !important; }
  .feedback-wrapper .ml-70 {
    margin-left: 0 !important; }
  .like-box {
    width: 40px;
    height: 40px;
    margin: 1.25rem 1.25rem 1.25rem 0; }
    .like-box h2,
    .like-box span {
      display: none; }
  .comment-sec .media,
  .comment-sec .media > div {
    display: inline-block;
    text-align: center; }
    .comment-sec .media img,
    .comment-sec .media > div img {
      margin-bottom: 0.9375rem; }
  .search-filter .w-40 {
    width: 50% !important; }
  .userProfile-wrapper .w-50,
  .userProfile-wrapper .w-75 {
    width: 100% !important; }
  .profile-content img {
    width: 70px;
    height: 70px; }
  .profile-content .media-body h2 {
    font-size: 1.275rem; }
  .all-mails-list .small.text-muted {
    display: none; }
  .all-mails-list .emails {
    width: 100% !important; } }

@media (max-width: 500px) and (min-width: 320px) {
  .userProfile-wrapper .profile-top .profile-content {
    top: 10px !important; }
  .userProfile-wrapper .messages-wrapper ul li:not(:first-child) {
    display: inline-block !important; }
  .userProfile-wrapper .messages-wrapper ul li span,
  .userProfile-wrapper .messages-wrapper ul li button {
    margin-bottom: 10px !important; }
  .userProfile-wrapper .messages-wrapper .toolbar label,
  .userProfile-wrapper .messages-wrapper .toolbar button {
    margin: 0; } }

@media (max-width: 415px) and (min-width: 320px) {
  .report-status .top-filter .w-20 {
    width: 100% !important; }
  .profile-content img {
    width: 50px;
    height: 50px; } }

/*================= Responsive scroll height ====================*/
@media (max-width: 1700px) {
  .app-boxed .chat-main-body .rct-scroll {
    height: calc(100vh - 369px) !important; } }

.app-horizontal .todo-wrapper .list-wrap .task-detail-wrapper .rct-scroll {
  height: calc(100vh - 242px) !important; }

.app-boxed .todo-wrapper .list-wrap .rct-scroll {
  height: calc(100vh - 382px) !important; }

@media (max-width: 990px) {
  .app-boxed .rct-page-content {
    height: calc(100vh - 10rem) !important; }
  .app-boxed .chat-main-body .rct-scroll,
  .app-boxed .chat-sidebar .chat-list .rct-scroll {
    height: calc(100vh - 275px) !important; }
  .app-boxed .rct-mail-wrapper .list-wrap .rct-scroll {
    height: calc(100vh - 211px) !important; }
  .app-boxed .todo-wrapper .list-wrap .rct-scroll {
    height: calc(100vh - 272px) !important; } }

@media (max-width: 959px) {
  .chat-box-main .sidebar-toggler, .chat-main-body .chat-sidebar-toggler {
    display: block !important; }
  .chat-sidebar .chat-list .rct-scroll {
    height: calc(100vh - 115px) !important; }
  .app .all-mails-list .rct-scroll {
    height: calc(100vh - 135px) !important; }
  .todo-sidebar-wrap .rct-scroll {
    height: calc(100vh - 221px) !important; }
  .mail-sidebar-wrap .rct-scroll {
    height: calc(100vh - 221px) !important; } }

@media (max-width: 599px) {
  .todo-wrapper .list-wrap .rct-scroll {
    height: calc(100vh - 180px) !important; } }

/*========================== Responsive App ===================*/
@media (max-width: 767px) {
  .rct-mail-wrapper .list-wrap .icon-wrap,
  .rct-mail-wrapper .list-wrap .emails .avatar-wrap,
  .rct-mail-wrapper .list-wrap .emails .media-body p {
    display: none !important; }
  .rct-mail-wrapper .list-wrap .list-item {
    padding: 0.75rem !important; }
    .rct-mail-wrapper .list-wrap .list-item .emails .media-body .w-25 {
      width: 100% !important; } }

/*=========== User Style =========*/
.card-block-content .media .media-left img {
  margin-top: -30px;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff; }

.user-image img {
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.21);
  margin: 0 auto; }

.profile-top {
  overflow: hidden; }

.profile-bottom {
  margin-top: -50px; }

.user-activity {
  padding: 0.9375rem; }
  .user-activity ul li {
    border-right: 2px solid #EBEDF2;
    padding: 0 0.625rem; }
    .user-activity ul li:last-child {
      border: none; }
    .user-activity ul li span {
      font-size: 1.375rem;
      margin: 0 10px; }

.card-right-action {
  color: #fff;
  border-radius: 100%;
  position: absolute;
  top: -8px;
  right: 5px;
  z-index: 99; }

.media-listing .media {
  padding: 1.25rem; }

/*======= User Management =====*/
.userProfile-wrapper .heading {
  font-size: 1.5rem;
  margin-bottom: 1.875rem; }

.userProfile-wrapper button {
  text-transform: capitalize;
  color: #A5A7B2; }

.userProfile-wrapper .bordered {
  border: 8px solid rgba(255, 255, 255, 0.23); }

.userProfile-wrapper .profile-top {
  position: relative; }
  .userProfile-wrapper .profile-top .profile-content {
    position: absolute;
    top: 80px;
    left: 110px; }
    .userProfile-wrapper .profile-top .profile-content .media-body h2, .userProfile-wrapper .profile-top .profile-content .media-body p {
      color: #fff;
      margin-bottom: 0; }

.prefrences-wrapper p {
  margin-bottom: 0;
  color: #A5A7B2; }

.prefrences-wrapper h5 {
  margin-bottom: 10px; }

.card-base {
  padding: 0.9375rem;
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  border-radius: 0.25rem;
  background-color: #FAFAFA;
  margin-bottom: 1.875rem; }

/*======= Responsive =======*/
@media (max-width: 1590px) and (min-width: 1199px) {
  .user-list-wrapper [class*="col-"] {
    max-width: 33.3%;
    flex: 0 0 33.3%; } }

@media (max-width: 992px) and (min-width: 801px) {
  .media-full {
    width: 100%;
    display: block; }
    .media-full img {
      width: 100%;
      margin-bottom: 15px; }
    .media-full .media-body {
      width: 100%; } }

@media (max-width: 575px) and (min-width: 320px) {
  .media-full {
    width: 100%;
    display: block; }
    .media-full img {
      width: 100%;
      margin-bottom: 15px; }
    .media-full .media-body {
      width: 100%; } }

@media (max-width: 588px) and (min-width: 320px) {
  .user-activity ul li {
    display: block;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #EBEDF2;
    padding: 10px; } }

/*=========== Ecommerce Style ==========*/
.shop-listing .rct-block {
  padding: 1.875rem 3.125rem 1.875rem 0.625rem; }

.shop-listing .media-body {
  transform: translate3d(0%, 40%, 0); }

.super {
  top: -12px;
  font-size: 15px;
  margin-left: 10px;
  color: #00D0BD; }

.invoice-head {
  border-bottom: 1px solid #EBEDF2; }
  .invoice-head ul {
    margin: 0; }
    .invoice-head ul li {
      display: inline-block;
      border-left: 1px solid #EBEDF2;
      padding: 1.25rem 1.875rem; }
      .invoice-head ul li a {
        color: #464D69; }
        .invoice-head ul li a i {
          font-size: 1.625rem;
          vertical-align: middle; }

.address span, .add-card span {
  display: block;
  color: #A5A7B2;
  font-size: 14px; }

.invoice-address span, .order-status span {
  display: block;
  line-height: 2; }

.invoice-title, .title {
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 60px; }

.add-card {
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  padding: 1.25rem; }

.name {
  color: #464D69 !important;
  font-weight: 500; }

.rct-filter {
  background-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  height: 52px;
  border-radius: 5px;
  display: flex; }
  .rct-filter ul {
    width: 100%;
    align-items: center;
    display: flex; }
    .rct-filter ul li {
      width: 50%;
      margin: 0 !important;
      text-align: center;
      line-height: 52px;
      font-size: 1.5rem; }
      .rct-filter ul li:last-child {
        border-right: none; }
      .rct-filter ul li:hover a {
        color: #5D92F4; }
      .rct-filter ul li a {
        color: #464D69; }

.Shop-grid-wrapper .shop-head .has-wrapper {
  position: relative; }
  .Shop-grid-wrapper .shop-head .has-wrapper .search-icon {
    position: absolute;
    right: 2rem;
    top: 1.25rem;
    font-size: 1rem; }

/*======== Shop ========*/
.shop-wrapper .filter-sm-wrap .ais-Panel-header {
  border: none; }

.shop-wrapper .filters-wrapper .ais-RangeInput-input {
  width: 4rem; }

.shop-wrapper .ais-MenuSelect-select,
.shop-wrapper .ais-SortBy-select {
  width: 100%;
  background-image: none;
  cursor: pointer; }

.shop-wrapper .ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem; }
  .shop-wrapper .ais-Hits-list .ais-Hits-item {
    border: none;
    box-shadow: none;
    margin: 0 0 1.5rem 0;
    padding: 0 0.75rem;
    display: grid; }
    .shop-wrapper .ais-Hits-list .ais-Hits-item .product-info h4 {
      font-weight: 500; }
    .shop-wrapper .ais-Hits-list .ais-Hits-item .overlay-wrap {
      min-height: 16rem;
      max-height: 16rem; }
    .shop-wrapper .ais-Hits-list .ais-Hits-item .overlay-content {
      visibility: hidden;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: 0.3s all ease-in-out; }
      .shop-wrapper .ais-Hits-list .ais-Hits-item .overlay-content .cart-btn {
        transform: translateY(100%);
        transition: 0.3s all ease-in-out; }
    .shop-wrapper .ais-Hits-list .ais-Hits-item:hover .overlay-content {
      opacity: 1;
      visibility: visible; }
      .shop-wrapper .ais-Hits-list .ais-Hits-item:hover .overlay-content .cart-link {
        transform: translateY(0%); }

.shop-wrapper .ais-RefinementList-list li label,
.shop-wrapper .ais-NumericMenu-list li label {
  margin: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .shop-wrapper .ais-RefinementList-list li label input,
  .shop-wrapper .ais-NumericMenu-list li label input {
    margin: 0 0.625rem;
    height: 1rem;
    width: 1rem;
    cursor: pointer; }
  .shop-wrapper .ais-RefinementList-list li label .ais-RefinementList-labelText,
  .shop-wrapper .ais-NumericMenu-list li label .ais-RefinementList-labelText {
    width: 80%; }

.shop-wrapper .ais-RefinementList-list li label .ais-RefinementList-count {
  float: right; }

.shop-wrapper .ais-RatingMenu-link {
  padding: 0.5rem; }

.shop-wrapper .ais-ClearRefinements-button {
  padding: 1rem;
  width: 100%;
  font-size: 1rem;
  border-radius: 0; }

.shop-wrapper .ais-Panel-header {
  border: none; }

.shop-wrapper .ais-Pagination-item--selected .ais-Pagination-link {
  background-color: #5D92F4;
  border-color: #5D92F4; }

/*======== Checkout ========*/
.checkout-wrap .checkout-form-wrap {
  border-right: 1px solid #EBEDF2; }
  .checkout-wrap .checkout-form-wrap header button {
    min-width: 50%; }

.checkout-wrap .payment-wrap .rccs__cvc {
  color: #000 !important; }

/*======== Responsive ========*/
@media (max-width: 992px) and (min-width: 320px) {
  .shop-listing .mr-50 {
    margin-right: 0 !important; }
  .shop-listing .rct-block {
    padding: 1.875rem 1rem 1.875rem 0.625rem; } }

@media (max-width: 767px) and (min-width: 320px) {
  .shop-listing .media-body {
    transform: translate3d(0%, 15%, 0); } }

@media (max-width: 700px) and (min-width: 320px) {
  .shop-listing .d-flex {
    display: inline-block !important;
    width: 100%;
    text-align: center; }
  .shop-listing .media {
    width: 100%;
    display: block; }
    .shop-listing .media .media-left {
      width: 100%; }
    .shop-listing .media .media-body {
      width: 100%;
      transform: translate3d(0%, 0%, 0); } }

@media (max-width: 575px) and (min-width: 320px) {
  .shop-grid .rct-block-content {
    text-align: center; }
  .invoice-wrapper .add-full-card.d-flex {
    display: inline-block !important;
    width: 100%; }
  .invoice-wrapper .add-full-card .add-card {
    width: 100%; }
  .shop-wrapper .ais-Hits-item {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .shop-wrapper .ais-Hits-item {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 576px) and (max-width: 991px) {
  .shop-wrapper .ais-Hits-item {
    flex: 0 0 50%;
    max-width: 50%; } }

/*=========== Session Style =========*/
.session-wrapper, .error-wrapper, .rct-session-wrapper {
  background-image: url(/static/media/rct-session-banner.2163e1a1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: fixed;
  right: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden; }
  .session-wrapper .session-inner-wrapper, .error-wrapper .session-inner-wrapper, .rct-session-wrapper .session-inner-wrapper {
    transform: translate3d(0%, 10%, 0);
    padding-bottom: 1.875rem; }

.session-header {
  background: none !important;
  box-shadow: none !important; }
  .session-header > div {
    padding: 0; }

.session-logo {
  display: flex;
  align-items: center; }
  .session-logo i {
    font-size: 1.875rem;
    margin-right: 0.9375rem;
    color: #fff; }

.has-wrapper {
  position: relative; }
  .has-wrapper .has-input {
    border: none;
    padding: 0.625rem 1.563rem; }
  .has-wrapper .has-icon {
    position: absolute;
    right: 25px;
    top: 13px;
    font-size: 1.25rem; }

.btn-large {
  height: 52px; }

.error-wrapper {
  background: #00D0BD;
  background-image: none;
  overflow-x: hidden; }

.error-body h2 {
  font-weight: 900;
  text-shadow: 9px 10px 1px rgba(0, 0, 0, 0.08); }

.oops {
  font-size: 3.75rem; }

.bold {
  font-size: 13.75rem; }

.error-msg {
  font-size: 4.375rem; }

.rct-session-wrapper .row-eq-height {
  align-items: center; }

.rct-session-wrapper header {
  height: 100px;
  background: none !important;
  justify-content: space-around;
  box-shadow: none; }

.rct-session-wrapper .session-inner-wrapper .session-body {
  background-color: #fff;
  border-radius: 5px;
  padding: 2rem 6.25rem; }
  .rct-session-wrapper .session-inner-wrapper .session-body p {
    color: #A5A7B2;
    font-size: 0.875rem; }
    .rct-session-wrapper .session-inner-wrapper .session-body p a {
      text-decoration: underline; }
      .rct-session-wrapper .session-inner-wrapper .session-body p a:hover {
        color: #5D92F4; }

.rct-session-wrapper .has-wrapper .has-input {
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2; }

[class*="circle-btn-"] {
  border-radius: 50px !important; }

.circle-btn-sm {
  min-width: 160px !important; }

.circle-btn-xs {
  min-width: 120px !important; }

.session-slider .slick-slide {
  position: relative; }
  .session-slider .slick-slide img {
    border-radius: 6px; }
  .session-slider .slick-slide .rct-img-overlay {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    padding: 0.9375rem 0.9375rem 1.563rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    color: #fff; }
    .session-slider .slick-slide .rct-img-overlay p {
      margin: 0;
      font-size: 0.875rem; }
    .session-slider .slick-slide .rct-img-overlay h5 {
      font-style: italic;
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 1.25rem; }

.session-slider .slick-dots {
  text-align: right;
  top: 0;
  height: 30px; }

/*======= Responsive =======*/
@media (max-width: 1199px) and (min-width: 320px) {
  .rct-session-wrapper .container {
    max-width: 100%; }
  .rct-session-wrapper .session-inner-wrapper .session-body {
    padding: 2rem 1.875rem; }
    .rct-session-wrapper .session-inner-wrapper .session-body [class*="btn-"]:not(.btn-success) {
      height: 0px !important;
      width: 35px;
      font-size: 1rem;
      margin-bottom: 5px !important; }
    .rct-session-wrapper .session-inner-wrapper .session-body p.mb-20, .rct-session-wrapper .session-inner-wrapper .session-body .session-head.mb-30 {
      margin-bottom: 0.9375 !important; } }

@media (max-width: 767px) {
  .rct-session-wrapper [class*="col-"] {
    max-width: 100%;
    flex: 100%; }
  .rct-session-wrapper .session-inner-wrapper {
    transform: translate3d(0%, 0%, 0); }
  .rct-session-wrapper .session-slider {
    display: none; } }

@media (max-width: 600px) {
  .error-body .bold {
    font-size: 8.75rem; }
  .error-body .error-msg {
    font-size: 2.375rem; } }

@media (max-width: 575px) {
  .circle-btn-xs {
    min-width: 85px !important;
    padding: 5px 5px 3px !important; }
  .rct-session-wrapper .session-head h2 {
    font-size: 1.4rem;
    margin-bottom: 0.4rem; }
  .rct-session-wrapper header a.mr-15 {
    display: none; } }

/*======= All App Effect Is Mentioned Here ========*/
.rct-notify {
  display: flex;
  -moz-animation: notify 1.5s infinite linear;
  -o-animation: notify 1.5s infinite linear;
  -webkit-animation: notify 1.5s infinite linear;
  animation: notify 1.5s infinite linear;
  border-radius: 100%; }

@-webkit-keyframes notify {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(179, 182, 183, 0.96); }
  70% {
    -webkit-box-shadow: 0 0 0 12px rgba(179, 182, 183, 0.96); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(179, 182, 183, 0.96); } }

@keyframes notify {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(179, 182, 183, 0.96);
    box-shadow: 0 0 0 0 rgba(179, 182, 183, 0.96); }
  70% {
    -moz-box-shadow: 0 0 0 12px rgba(179, 182, 183, 0);
    box-shadow: 0 0 0 12px rgba(179, 182, 183, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(179, 182, 183, 0);
    box-shadow: 0 0 0 0 rgba(179, 182, 183, 0); } }

/*=========== Weather Widget Effect =============*/
.rct-weather-widget {
  background-image: url(/static/media/weather-1.c13b98f5.png);
  background-size: cover;
  -moz-animation: animatedBackground 25s infinite ease;
  -o-animation: animatedBackground 25s infinite ease;
  -webkit-animation: animatedBackground 25s infinite ease;
  animation: animatedBackground 25s infinite ease; }

@-webkit-keyframes animatedBackground {
  0% {
    background-position: 0 0; }
  1% {
    background-position: 0 0; }
  50% {
    background-position: -1000px 0; }
  51% {
    background-position: -1000px 0; }
  99% {
    background-position: 0 0; }
  100% {
    background-position: 0 0; } }

@keyframes animatedBackground {
  0% {
    background-position: 0 0; }
  1% {
    background-position: 0 0; }
  50% {
    background-position: -1000px 0; }
  51% {
    background-position: -1000px 0; }
  99% {
    background-position: 0 0; }
  100% {
    background-position: 0 0; } }

/*===== Setting Rotate Animation ======*/
.rotate {
  -webkit-transition: 0.6s ease-out;
  -moz-transition: 0.6s ease-out;
  -ms-transition: 0.6s ease-out;
  -o-transition: 0.6s ease-out;
  transition: 0.6s ease-out !important; }

.rotate:hover {
  -webkit-transform: rotateZ(360deg);
  -moz-transform: rotateZ(360deg);
  -ms-transform: rotateZ(360deg);
  -o-transform: rotateZ(360deg);
  transform: rotateZ(360deg); }

/*====== Bell Shake Animation =====*/
@-ms-keyframes ring {
  0% {
    -ms-transform: rotate(15deg); }
  50% {
    -ms-transform: rotate(-15deg); }
  100% {
    -ms-transform: rotate(15deg); } }

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(15deg); }
  50% {
    -moz-transform: rotate(-15deg); }
  100% {
    -moz-transform: rotate(15deg); } }

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(15deg); }
  50% {
    -webkit-transform: rotate(-15deg); }
  100% {
    -webkit-transform: rotate(15deg); } }

@keyframes ring {
  0% {
    transform: rotate(15deg); }
  50% {
    transform: rotate(-15deg); }
  100% {
    transform: rotate(15deg); } }

.shake {
  -ms-animation: ring .3s infinite;
  -moz-animation: ring .3s infinite;
  -webkit-animation: ring .3s infinite;
  animation: ring .3s infinite; }

@keyframes spin-icon {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.spin-icon {
  -webkit-animation: spin-icon 2s infinite linear;
  animation: spin-icon 2s infinite linear; }

/*======= Theme Panel =======*/
.fixed-plugin {
  position: fixed;
  right: -2px;
  width: auto;
  z-index: 9;
  border-radius: 10px 0 0 10px;
  top: 130px; }
  .fixed-plugin i {
    padding: 0.625rem; }
  .fixed-plugin .header-title {
    line-height: 25px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase; }
  .fixed-plugin .badge {
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    height: 35px;
    position: relative;
    width: 35px;
    padding: 0 !important;
    transition: all .34s; }
  .fixed-plugin .dropdown > button.btn-secondary {
    background: none;
    border: none;
    padding: 0; }
    .fixed-plugin .dropdown > button.btn-secondary:hover, .fixed-plugin .dropdown > button.btn-secondary.active, .fixed-plugin .dropdown > button.btn-secondary:focus {
      background: none; }
  .fixed-plugin .dropdown.show .dropdown-menu {
    transform: translate3d(0, -40px, 0) !important;
    opacity: 1;
    visibility: visible; }
  .fixed-plugin .dropdown .dropdown-menu {
    right: 45px;
    left: auto !important;
    width: 19rem;
    border-radius: 5px;
    padding: 1.25rem 0rem;
    background-color: #fff;
    transform: translate3d(0, 100px, 0) !important; }
    .fixed-plugin .dropdown .dropdown-menu::before, .fixed-plugin .dropdown .dropdown-menu::after {
      border: none; }
  .fixed-plugin .background-img .img-holder {
    border: 3px solid transparent;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 4px; }
    .fixed-plugin .background-img .img-holder img {
      max-height: 100%;
      height: 100px;
      width: 50px; }
    .fixed-plugin .background-img .img-holder.active {
      border-color: #FF3739; }

/*========== Tour scss =========*/
.joyride-tooltip {
  width: 23.13rem !important;
  padding: 0 !important;
  border-radius: 0; }
  .joyride-tooltip.right .joyride-tooltip__triangle {
    left: -12px !important;
    top: 8px; }
  .joyride-tooltip.bottom .joyride-tooltip__triangle {
    top: -12px !important; }
  .joyride-tooltip.left .joyride-tooltip__triangle {
    right: -12px !important; }
  .joyride-tooltip .joyride-tooltip__header, .joyride-tooltip .joyride-tooltip__main, .joyride-tooltip .joyride-tooltip__footer {
    padding: 0.625rem !important; }
  .joyride-tooltip .joyride-tooltip__header {
    border-bottom: 1px solid #5D92F4;
    background-color: #5D92F4;
    color: #fff; }
  .joyride-tooltip .joyride-tooltip__footer {
    border-top: 1px solid #EBEDF2; }
  .joyride-tooltip .joyride-tooltip__button--skip {
    color: #f04; }
  .joyride-tooltip .joyride-tooltip__close {
    background: none; }
    .joyride-tooltip .joyride-tooltip__close::before {
      content: "\F136";
      font-family: Material-Design-Iconic-Font;
      color: #fff; }

/*========== Widgets scss =========*/
.widget-heading {
  font-size: 1.625rem;
  font-weight: 500;
  color: #727891;
  margin-bottom: 1.875rem; }

.flex-chart .rct-block-content {
  height: 100%;
  display: flex;
  flex-direction: column; }

.to-do-list ul > div:hover .hover-action {
  opacity: 1; }

.hover-action {
  opacity: 0;
  transition: all 0.4s linear 0s; }
  .hover-action button, .hover-action a {
    height: 34px;
    width: 34px;
    font-size: 1.3rem; }

.new-mail > div,
.new-mail > li {
  padding: 0.625rem 0.9375rem;
  border-bottom: 1px solid #EBEDF2; }
  .new-mail > div:hover .hover-action,
  .new-mail > li:hover .hover-action {
    opacity: 1; }

.new-mail .subject {
  font-size: 1rem;
  font-weight: 500;
  color: #464D69;
  margin-bottom: 0.625rem; }

.new-mail .message {
  color: #677080;
  font-size: 12px; }

.comment-section ul > div:not(:last-child) {
  border-bottom: 1px solid #EBEDF2; }

.comment-section ul > div .comment-date {
  opacity: 1;
  transition: all 0.2s ease-in-out; }

.comment-section ul > div:hover .hover-action {
  opacity: 1; }

.comment-section ul > div:hover .comment-date {
  opacity: 0; }

@media (max-width: 500px) {
  .comment-section ul > div {
    display: block !important; }
    .comment-section ul > div .avatar-wrap {
      margin: 0 0 1rem 0 !important; }
    .comment-section ul > div .comment-action {
      width: 100% !important;
      text-align: left !important; } }

.weather-top {
  position: relative; }
  .weather-top .weather-over {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    padding: 0.625rem 1.875rem; }
  .weather-top .weather-head,
  .weather-top .weather-temp {
    color: #fff; }
  .weather-top .weather-temp h2 {
    font-size: 3.75rem;
    font-weight: 600;
    margin: 0; }
  .weather-top .weather-head h4 {
    font-size: 1.5rem;
    font-weight: 500; }
  .weather-top .weather-head i {
    font-size: 5rem; }

.weather-bottom ul li {
  border-bottom: 1px solid #EBEDF2;
  padding: 0.625rem 0.9375rem; }
  .weather-bottom ul li .w-icon i {
    font-size: 1.5rem; }

.notification-box {
  border: none; }
  .notification-box ul li {
    margin-bottom: 1.25rem; }
    .notification-box ul li:last-child {
      margin-bottom: 0; }

.message-head {
  color: #727891; }
  .message-head i {
    font-size: 1rem;
    margin-right: 5px; }

.notify-user {
  position: relative; }
  .notify-user .badge {
    width: 25px;
    border-radius: 100%;
    padding: 0 !important;
    margin: 0;
    font-size: 1rem;
    height: 25px;
    line-height: 20px;
    border: 2px solid #fff;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 30px; }

.small-btn {
  width: 33px !important;
  height: 30px !important;
  font-size: 1rem !important; }

.new-customer-list li a {
  font-size: 1.1rem;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  padding: 0;
  margin: 3px; }
  .new-customer-list li a:hover, .new-customer-list li a:active, .new-customer-list li a:focus {
    color: inherit; }

.activity-widget li {
  border-left: 1px solid #EBEDF2;
  padding: 0 20px 15px 20px;
  position: relative; }
  .activity-widget li span.activity-time {
    display: block;
    font-size: 12px; }
  .activity-widget li span.badge {
    position: absolute;
    right: auto;
    top: 0;
    left: -9.75px;
    min-height: 1.125rem;
    min-width: 1.125rem;
    font-size: 0; }
    .activity-widget li span.badge:before {
      border: 3px solid #fff;
      position: absolute;
      content: "";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 100%; }

.support-widget-wrap ul li .content-title {
  width: 20%;
  color: #727891; }

.stock-exchange ul li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EBEDF2;
  padding: 0.9375rem; }

.share-friend .social-share li {
  padding: 0;
  margin-right: 1rem; }
  .share-friend .social-share li:last-child {
    margin: 0; }
  .share-friend .social-share li a {
    color: #fff;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem; }

.review-slider .rct-block-title, .review-slider .faq-page-wrapper .panel-heading, .faq-page-wrapper .review-slider .panel-heading {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.1); }
  .review-slider .rct-block-title h4, .review-slider .faq-page-wrapper .panel-heading h4, .faq-page-wrapper .review-slider .panel-heading h4 {
    color: #fff; }

.review-slider .slick-dots {
  text-align: left;
  padding-left: 1rem;
  bottom: 0; }
  .review-slider .slick-dots li {
    margin: 0; }
    .review-slider .slick-dots li button:before {
      color: #fff;
      font-size: 0.5rem; }

.blog-layout-three {
  overflow: hidden; }
  .blog-layout-three .blog-overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.81) 100%); }

.user-profile-widget > img {
  height: 150px; }

.user-profile-widget .user-avatar {
  margin-top: -6rem; }

.blog-list-wrap ul .post-item .post-img {
  width: 4rem; }

.blog-list-wrap ul .post-item:hover .hover-action {
  opacity: 1; }

.project-managemnet-wrapper .table .progress {
  height: 0.3125rem;
  border-radius: 2rem; }
  .project-managemnet-wrapper .table .progress .progress-bar {
    border-radius: 2rem; }

.task-management-wrapper .project-heading {
  padding: 0.5rem 1.5rem; }

.task-management-wrapper .task-title {
  width: 60%; }
  .task-management-wrapper .task-title h4 {
    word-wrap: break-word;
    color: #727891; }

.team-avatar ul {
  position: relative;
  margin-top: 5px; }
  .team-avatar ul li {
    margin-right: 0 !important; }
    .team-avatar ul li > div {
      height: 2rem;
      width: 2rem;
      border: 2px solid transparent;
      transform: translateY(0) scale(1);
      transition: all 0.2s ease-in-out; }
    .team-avatar ul li:hover > div {
      border-color: #fff;
      z-index: 999;
      transform: translateY(-4px) scale(1.02);
      box-shadow: 0 14px 24px rgba(62, 57, 107, 0.2); }
    .team-avatar ul li > div {
      height: 1.875rem;
      width: 1.875rem; }
  .team-avatar ul li:not(:first-child) {
    margin-left: -0.85rem; }

.activity-board-wrapper .comment-box {
  background-color: #FAFAFA;
  border-top: 1px solid #EBEDF2;
  border-bottom: 1px solid #EBEDF2;
  border-left: 1px solid #EBEDF2;
  border-right: 1px solid #EBEDF2;
  padding: 0.625rem 1.875rem 0.625rem 0.625rem; }

.activity-board-wrapper .attachment-wrap .list-inline-item .overlay-content {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s ease-in-out; }

.activity-board-wrapper .attachment-wrap .list-inline-item:hover .overlay-content {
  opacity: 1; }

.activity-board-wrapper .progress {
  height: 0.75rem;
  border-radius: 2.5rem; }
  .activity-board-wrapper .progress .progress-bar {
    border-radius: 2.5rem; }

.list-group-item {
  border-color: #EBEDF2; }

.trending-news-widegt {
  background-image: url(/static/media/profile-bg.5573c7e7.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
  left: 15.65rem;
  right: 15.6rem; }
  .trending-news-widegt:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: -1; }
  .trending-news-widegt .slick-arrow {
    z-index: 1; }
    .trending-news-widegt .slick-arrow.slick-prev {
      right: 5rem;
      left: auto; }
    .trending-news-widegt .slick-arrow.slick-next {
      right: 3rem; }
    .trending-news-widegt .slick-arrow:before {
      font-size: 1.5rem;
      opacity: 1; }
  .trending-news-widegt .trending-block {
    padding: 2.95rem 3rem;
    border-right: 1px solid #fff; }
  @media (max-width: 768px) {
    .trending-news-widegt .trending-block {
      display: none !important; }
    .trending-news-widegt .slick-slider {
      width: 100% !important; }
      .trending-news-widegt .slick-slider .slick-list {
        height: 60px !important; }
        .trending-news-widegt .slick-slider .slick-list .slider-text-wrap p {
          display: none; }
        .trending-news-widegt .slick-slider .slick-list .slider-content > div {
          width: 100% !important; } }
  .trending-news-widegt .slick-slider {
    width: 85%; }
    .trending-news-widegt .slick-slider .slick-slide {
      border: none;
      padding: 1rem; }
    .trending-news-widegt .slick-slider .month-wrap .date {
      line-height: 1; }
    .trending-news-widegt .slick-slider .slider-content > div {
      width: 80%; }

.visitors-widget .top-content .count-value {
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1; }

.visitors-widget .collapse {
  height: 85%; }

.newslater-widget .collapse {
  height: 88%; }

.subscribers-widget .overlay-content {
  pointer-events: none; }
  .subscribers-widget .overlay-content .count-value {
    font-size: 1.75rem; }

/* ============== Social Share Widget ====================*/
.social-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  border-radius: 5px;
  margin-bottom: 24px;
  transition: all 0.4s ease-in-out 0s;
  padding: 1.25rem 1.25rem;
  background-color: #fff; }
  .social-card span {
    display: block; }
  .social-card > span {
    width: 40%;
    text-align: center; }
    .social-card > span:last-child {
      border-left: 1px dashed #EBEDF2; }
  .social-card .social-icon {
    width: 4.1875rem;
    height: 4.1875rem;
    background-color: #FAFAFA;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: all 0.4s ease-in-out; }
    .social-card .social-icon i {
      transition: all 0.4s ease-in-out;
      font-size: 1.75rem; }
  .social-card:hover {
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08); }
    .social-card:hover .social-icon {
      border-radius: 0 !important;
      border-color: transparent !important; }
      .social-card:hover .social-icon i {
        color: #fff !important; }
    .social-card:hover .facebook.social-icon {
      background-color: #3B5998 !important; }
    .social-card:hover .twitter.social-icon {
      background-color: #52AEFF !important; }
    .social-card:hover .linkedin.social-icon {
      background-color: #4875B4 !important; }
    .social-card:hover .google.social-icon {
      background-color: #F13A30 !important; }

/*==================== Top author ================*/
.top-author-wrap .slick-slider {
  height: 90%; }
  .top-author-wrap .slick-slider .slick-track, .top-author-wrap .slick-slider .slick-list, .top-author-wrap .slick-slider .slick-slide, .top-author-wrap .slick-slider .slick-slide > div, .top-author-wrap .slick-slider .author-detail-wrap {
    height: 100%; }

.top-author-wrap .author-detail-wrap .author-avatar {
  padding: 2.25rem 0;
  margin-top: -2px; }
  .top-author-wrap .author-detail-wrap .author-avatar .avatar-img {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0; }
    .top-author-wrap .author-detail-wrap .author-avatar .avatar-img img {
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.29); }
    @media (max-width: 599px) {
      .top-author-wrap .author-detail-wrap .author-avatar .avatar-img {
        top: 30px; }
        .top-author-wrap .author-detail-wrap .author-avatar .avatar-img img {
          height: 65px;
          width: 65px; } }

.top-author-wrap .author-detail-wrap .authors-info {
  min-height: 235px; }

.top-author-wrap .author-detail-wrap .author-contact-info li {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.25rem; }
  .top-author-wrap .author-detail-wrap .author-contact-info li:not(:last-child) {
    border-bottom: 1px dashed #EBEDF2; }

.top-author-wrap .author-detail-wrap .social-info li {
  margin: 0 0.25rem; }

.top-author-wrap .author-detail-wrap .footer-content li {
  width: 33%;
  padding: 0.625rem 0; }
  .top-author-wrap .author-detail-wrap .footer-content li:not(:last-child) {
    border-right: 1px solid #EBEDF2; }

.top-author-wrap .slick-arrow {
  top: 2rem;
  z-index: 9;
  opacity: 0;
  transition: all 0.2s ease-in-out; }
  .top-author-wrap .slick-arrow.slick-prev {
    left: 20px;
    transform: translateX(1rem); }
  .top-author-wrap .slick-arrow.slick-next {
    right: 30px;
    transform: translateX(-1rem); }
  .top-author-wrap .slick-arrow:before {
    font-size: 1.5rem; }

.top-author-wrap:hover .slick-arrow {
  opacity: 1;
  transform: translateX(0); }

/*============= Top news wrap ==============*/
.top-news-wrap {
  overflow: hidden; }
  .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item {
    display: block !important; }
    .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item img {
      width: 100%; }
    .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item .overlay-content {
      transition: all 0.3s ease-in-out;
      background-color: rgba(0, 0, 0, 0); }
      .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item .overlay-content .overlay-holder {
        background-color: rgba(0, 0, 0, 0.6);
        transition: all 0.3s ease-in-out;
        overflow: hidden; }
        .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item .overlay-content .overlay-holder p {
          height: 0;
          transition: all 0.4s ease-in-out;
          transform: translateY(1rem);
          opacity: 0; }
        @media (max-width: 599px) {
          .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item .overlay-content .overlay-holder {
            margin-bottom: 0 !important;
            width: 100% !important; }
            .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item .overlay-content .overlay-holder .meta-info, .top-news-wrap .main-slider-wrap .slick-slide .main-slider-item .overlay-content .overlay-holder p {
              display: none !important; } }
  @media (max-width: 599px) {
    .top-news-wrap .slider-btn-wrap {
      display: none; } }
  .top-news-wrap .slider-btn-wrap .slick-list {
    margin: 0 2rem;
    z-index: 0; }
    .top-news-wrap .slider-btn-wrap .slick-list .slick-slide {
      cursor: pointer;
      padding: 0 10px; }
      .top-news-wrap .slider-btn-wrap .slick-list .slick-slide img {
        transition: all 0.3s linear; }
    .top-news-wrap .slider-btn-wrap .slick-list .slick-current img {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5); }
  .top-news-wrap .slider-btn-wrap .slick-arrow {
    top: 0;
    bottom: 0;
    display: flex !important;
    align-items: center;
    width: 1.5rem;
    height: 100%;
    transition: all 0.3s ease-in-out;
    opacity: 0 !important; }
    .top-news-wrap .slider-btn-wrap .slick-arrow:before {
      color: #464D69;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      font-size: 1.5rem; }
    .top-news-wrap .slider-btn-wrap .slick-arrow.slick-prev {
      left: 0.5rem;
      transform: translateX(1rem); }
    .top-news-wrap .slider-btn-wrap .slick-arrow.slick-next {
      right: 0.5rem;
      transform: translateX(-1rem); }
  .top-news-wrap:hover .overlay-content {
    background-color: rgba(0, 0, 0, 0.4) !important; }
    .top-news-wrap:hover .overlay-content .overlay-holder {
      background-color: rgba(0, 0, 0, 0.9) !important; }
      .top-news-wrap:hover .overlay-content .overlay-holder p {
        height: 2rem !important;
        transform: translateY(0) !important;
        opacity: 1 !important; }
  .top-news-wrap:hover .slider-btn-wrap .slick-arrow {
    opacity: 1 !important;
    transform: translateX(0) !important; }
    .top-news-wrap:hover .slider-btn-wrap .slick-arrow:before {
      opacity: 1 !important; }

/*================== Top Headlines widget============*/
@media (max-width: 500px) {
  .top-headlines-widget li {
    display: block !important; }
    .top-headlines-widget li .news-content {
      display: block !important; }
      .top-headlines-widget li .news-content .img-wrap {
        margin: 0 0 1rem 0 !important; }
        .top-headlines-widget li .news-content .img-wrap img {
          width: 100%; }
    .top-headlines-widget li .news-time {
      width: 100% !important;
      text-align: left !important; } }

/*================= Twitter Feed V2 ==================*/
.twitter-feeds-widget .slick-slide {
  padding: 1rem 0; }

.twitter-feeds-widget .twitter-content .avatar-wrap {
  margin-bottom: 2.5rem; }
  .twitter-feeds-widget .twitter-content .avatar-wrap img {
    transition: all 0.3s ease-in-out; }
  .twitter-feeds-widget .twitter-content .avatar-wrap .overlay-content {
    top: -3px;
    left: -6px;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s ease-in-out; }
    .twitter-feeds-widget .twitter-content .avatar-wrap .overlay-content .badge {
      height: 2rem;
      width: 2rem;
      font-size: 1rem;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.29); }

.twitter-feeds-widget:hover img {
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.29); }

.twitter-feeds-widget:hover .overlay-content {
  transform: translate3d(-0.5rem, -0.5rem, 0) !important; }

/*========= Responsive ==========*/
@media (max-width: 1300px) and (min-width: 320px) {
  .promo-coupon img {
    width: 130px;
    height: 130px; } }

@media (max-width: 500px) and (min-width: 320px) {
  .project-managemnet-wrapper .table thead tr th:last-child span {
    display: none; }
  .team-avatar {
    display: none; }
  .blog-list-wrap .post-item,
  .blog-list-wrap .post-item .post-content {
    display: block !important; }
    .blog-list-wrap .post-item .post-img,
    .blog-list-wrap .post-item .post-content .post-img {
      width: 100% !important;
      margin-right: 0 !important;
      margin-bottom: 1.875rem; } }

/*================ Video player ==============*/
.video-player-wrapper .video-react .video-react-big-play-button {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  line-height: 2.75rem;
  top: 45%;
  left: 46%; }

@media (max-width: 500px) {
  .video-player-wrapper .table tr td {
    font-size: 0.75rem; } }

/*================ Image Cropper =============*/
.image-cropper-wrap .rct-block-content > div {
  overflow: hidden; }

.image-cropper-wrap .rct-block-content .cropper-container.cropper-bg {
  width: 100% !important; }

@media (max-width: 500px) {
  .image-cropper-wrap .rct-block-content.crop-wrapper > div > div:not(.d-flex) {
    height: 250 !important; }
  .image-cropper-wrap .rct-block-content .form-group {
    display: block !important;
    text-align: center; }
    .image-cropper-wrap .rct-block-content .form-group .w-30, .image-cropper-wrap .rct-block-content .form-group .w-40 {
      width: 100% !important; }
    .image-cropper-wrap .rct-block-content .form-group .form-control-file {
      width: 46% !important;
      margin: 0 auto; } }

/*=============== DropZone ================*/
.dropzone-wrapper .dropzone .dz-preview.dz-image-preview {
  background: transparent !important; }

.dropzone-wrapper .dropzone .dz-error-message, .dropzone-wrapper .dropzone .dz-preview.dz-error .dz-error-mark {
  display: none !important; }

.dropzone-wrapper .dropzone .dz-remove {
  padding: 0.25rem;
  background-color: #FF3739;
  color: #fff;
  text-decoration: none;
  border-radius: 0.3125rem;
  margin-top: 1rem; }
  .dropzone-wrapper .dropzone .dz-remove:hover {
    text-decoration: none; }

/*====== All App Layout Include Here =====*/
/*======= Mini Sidebar Style Here =====*/
.mini-sidebar .side-title {
  display: none; }

.mini-sidebar .app-conrainer-wrapper {
  left: 85px !important; }

.mini-sidebar .top-sidebar .sidebar-user-block .user-info {
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out; }

.mini-sidebar .rct-sidebar {
  width: 85px;
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: .2s, .2s, .35s;
  transition-duration: .2s, .2s, .35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch; }
  .mini-sidebar .rct-sidebar .site-logo .logo-normal {
    opacity: 0;
    -webkit-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0); }
  .mini-sidebar .rct-sidebar:hover .logo-normal {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .mini-sidebar .rct-sidebar:hover .sub-menu {
    display: block; }
  .mini-sidebar .rct-sidebar .rct-mainMenu .side-title {
    display: none !important; }
  .mini-sidebar .rct-sidebar .rct-mainMenu > li {
    padding: 1rem 2rem !important;
    transition: all 0.3s ease-in-out; }
    .mini-sidebar .rct-sidebar .rct-mainMenu > li:not(.list-item) a {
      padding: 0 !important;
      display: flex;
      align-items: center; }
    .mini-sidebar .rct-sidebar .rct-mainMenu > li .menu-icon, .mini-sidebar .rct-sidebar .rct-mainMenu > li .rct-mainMenu > li > a .menu-icon, .mini-sidebar .rct-sidebar .rct-mainMenu > li > a .rct-mainMenu > li .menu-icon {
      float: left;
      text-align: center; }
    .mini-sidebar .rct-sidebar .rct-mainMenu > li > a .menu {
      left: 35px; }
    .mini-sidebar .rct-sidebar .rct-mainMenu > li .menu {
      left: 60px; }
    .mini-sidebar .rct-sidebar .rct-mainMenu > li.list-item:after {
      opacity: 0; }
    .mini-sidebar .rct-sidebar .rct-mainMenu > li .menu,
    .mini-sidebar .rct-sidebar .rct-mainMenu > li > a .menu {
      opacity: 0;
      transition: all 0.4s linear 0s;
      position: absolute;
      -webkit-transform: translate3d(-25px, 0, 0);
      -ms-transform: translate3d(-25px, 0, 0);
      transform: translate3d(-25px, 0, 0); }
  .mini-sidebar .rct-sidebar .sub-menu {
    display: none; }
  .mini-sidebar .rct-sidebar:hover {
    width: 16.25rem; }
    .mini-sidebar .rct-sidebar:hover .top-sidebar .sidebar-user-block .user-info {
      width: auto !important;
      height: 30px !important;
      opacity: 1; }
    .mini-sidebar .rct-sidebar:hover .rct-mainMenu .side-title {
      display: block !important; }
    .mini-sidebar .rct-sidebar:hover .rct-mainMenu > li,
    .mini-sidebar .rct-sidebar:hover .sub-menu > li {
      padding: 1rem 1.5rem !important; }
      .mini-sidebar .rct-sidebar:hover .rct-mainMenu > li .menu,
      .mini-sidebar .rct-sidebar:hover .rct-mainMenu > li > a .menu,
      .mini-sidebar .rct-sidebar:hover .rct-mainMenu > li a,
      .mini-sidebar .rct-sidebar:hover .sub-menu > li .menu,
      .mini-sidebar .rct-sidebar:hover .sub-menu > li > a .menu,
      .mini-sidebar .rct-sidebar:hover .sub-menu > li a {
        transform: translateZ(0);
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); }
      .mini-sidebar .rct-sidebar:hover .rct-mainMenu > li.list-item:after,
      .mini-sidebar .rct-sidebar:hover .sub-menu > li.list-item:after {
        opacity: 1; }

.mini-sidebar .sub-menu > li > a {
  position: relative;
  opacity: 0;
  transition: all 0.4s linear 0s;
  -webkit-transform: translate3d(-25px, 0, 0);
  -ms-transform: translate3d(-25px, 0, 0);
  transform: translate3d(-25px, 0, 0); }

.mini-sidebar .rct-header {
  left: 85px; }

/*====== Dark Layout Style Here ======*/
body.dark-mode,
body.dark-mode .app-boxed .rct-page {
  color: #fff;
  background-image: url(/static/media/dark-layout-bg.b86ac8d9.png); }

.dark-mode .sidebar-color,
.dark-mode .fixed-plugin .sidebar-overlay {
  display: none; }

.dark-mode .loader-overlay {
  background-color: rgba(0, 0, 0, 0.8); }

.dark-mode .sidebar-overlay-light {
  background-color: rgba(0, 0, 0, 0.8); }

.dark-mode .rct-header,
.dark-mode .rct-footer,
.dark-mode .rct-block-footer,
.dark-mode .card-footer,
.dark-mode .list-group-item,
.dark-mode .report-title,
.dark-mode .modal-content,
.dark-mode input:not(.input-search),
.dark-mode select,
.dark-mode .rct-filter,
.dark-mode .report-status,
.dark-mode .button-nav-wrapper button,
.dark-mode [role="document"],
.dark-mode textarea,
.dark-mode .listSection,
.dark-mode .sweet-alert,
.dark-mode .rct-wysiwyg-editor,
.dark-mode .bg-light,
.dark-mode .bg-light-yellow,
.dark-mode .timeline-Widget,
.dark-mode .horizontal-menu,
.dark-mode .horizontal-menu .sub-menu,
.dark-mode .horizontal-menu .sub-menu-child,
.dark-mode .horizontal-menu .sub-menu-sub-child,
.dark-mode table thead,
.dark-mode .total-earn-chart .default-btn-group button,
.dark-mode .dropdown-foot,
.dark-mode .fixed-plugin .dropdown .dropdown-menu,
.dark-mode .rct-mail-wrapper .list-wrap ul .list-item,
.dark-mode .todo-wrapper .list-wrap ul .list-item,
.dark-mode .rct-mail-wrapper .list-wrap .top-head,
.dark-mode .todo-wrapper .list-wrap .top-head,
.dark-mode .user-list-wrap > div > div,
.dark-mode .chat-head,
.dark-mode .mail-list-wrap > div > div,
.dark-mode .todo-list-wrap > div > div,
.dark-mode .rct-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu ul li:not(.user-profile-top) a {
  background-color: #2c3644 !important; }

.dark-mode .quciklink-dropdown .dropdown-menu .dropdown-list li,
.dark-mode .notification-dropdown .dropdown-menu .dropdown-list li,
.dark-mode .language-dropdown .dropdown-menu .dropdown-list li,
.dark-mode .cart-dropdown .dropdown-menu .dropdown-list li {
  background-color: #2c3644 !important; }
  .dark-mode .quciklink-dropdown .dropdown-menu .dropdown-list li:hover,
  .dark-mode .notification-dropdown .dropdown-menu .dropdown-list li:hover,
  .dark-mode .language-dropdown .dropdown-menu .dropdown-list li:hover,
  .dark-mode .cart-dropdown .dropdown-menu .dropdown-list li:hover {
    background-color: #2c3644 !important; }

.dark-mode .horizontal-menu li:hover > a,
.dark-mode .horizontal-menu .sub-menu li:hover > a,
.dark-mode .horizontal-menu .sub-menu li .sub-menu-child li:hover > a,
.dark-mode .table-hover tbody tr:hover,
.dark-mode .quicklink-list,
.dark-mode .sidebar-user-block .rct-dropdown .dropdown-menu ul,
.dark-mode .aqua-ripple > div:hover,
.dark-mode .social-card .social-icon,
.dark-mode .chat-sidebar .chat-list ul .user-list-item.item-active, .dark-mode .dropzone-wrapper .dropzone {
  background-color: rgba(0, 0, 0, 0.2) !important; }

.dark-mode .drawer-wrapper div,
.dark-mode .comment-box,
.dark-mode .dropdown-menu,
.dark-mode .card-base,
.dark-mode .rct-session-wrapper .session-inner-wrapper .session-body,
.dark-mode .todo-wrapper .task-box,
.dark-mode figure.img-wrapper::after,
.dark-mode .plan-info,
.dark-mode .btn-light,
.dark-mode .rct-mail-wrapper header,
.dark-mode .todo-wrapper header {
  background: #303030; }

.dark-mode .rct-mail-wrapper .list-wrap ul .list-item:hover,
.dark-mode .todo-wrapper .list-wrap ul .list-item:hover,
.dark-mode .chat-sidebar .chat-list ul .user-list-item:hover,
.dark-mode .task-detail-top {
  background: #434f5a !important; }

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6,
.dark-mode p,
.dark-mode span,
.dark-mode a,
.dark-mode table,
.dark-mode th,
.dark-mode td,
.dark-mode select,
.dark-mode input,
.dark-mode .menu-icon,
.dark-mode .mini-sidebar .rct-sidebar .rct-mainMenu > li > a .menu-icon,
.mini-sidebar .rct-sidebar .rct-mainMenu > li > a .dark-mode .menu-icon,
.dark-mode .side-arrow,
.dark-mode .top-news-wrap .slider-btn-wrap .slick-arrow:before,
.dark-mode input::placeholder,
.dark-mode textarea::placeholder,
.dark-mode .form-control,
.dark-mode .rct-block *,
.dark-mode .header-title,
.dark-mode .humburger,
.dark-mode .header-icon,
.dark-mode .notification-icon a button,
.dark-mode .cart-icon a button,
.dark-mode .text-muted,
.dark-mode .page-title h2,
.dark-mode .active.breadcrumb-item,
.dark-mode .text-dark,
.dark-mode textarea,
.dark-mode .total-earn-chart .default-btn-group button,
.dark-mode .ais-Panel-header {
  color: #fff !important; }

.dark-mode .pricing-wrapper *,
.dark-mode .about-wrapper *,
.dark-mode .panel-wrapper *,
.dark-mode .list-wrapper *,
.dark-mode .menu-paper-wrapper *,
.dark-mode .popover-wrapper *,
.dark-mode .date-time-wrapper *,
.dark-mode .stepper-wrapper *,
.dark-mode .tabs-wrapper *,
.dark-mode .textfields-wrapper *,
.dark-mode .select-wrapper *,
.dark-mode .re-chart-wrapper *,
.dark-mode .icons-wrapper *,
.dark-mode .data-table-wrapper *,
.dark-mode .redux-table-wrapper *,
.dark-mode .divider-wrapper *,
.dark-mode .drawer-wrapper *,
.dark-mode .feedback-wrapper *,
.dark-mode .userProfile-wrapper *,
.dark-mode .avatar-wrapper *,
.dark-mode .rct-block * {
  color: #fff !important; }

.dark-mode .rct-block,
.dark-mode .social-card,
.dark-mode .card,
.dark-mode .panel,
.dark-mode .rct-tabs header,
.dark-mode .pricing-box,
.dark-mode .chat-bubble.even,
.dark-mode .btn-light i {
  background-color: #2c3644;
  color: #fff;
  border-color: rgba(0, 0, 0, 0.1); }

.dark-mode .quicklink-wrapper .header-icon,
.dark-mode .search-icon .search-wrapper .search-input-lg {
  border-color: #fff !important; }

.dark-mode .rct-block *,
.dark-mode input:not(.input-search),
.dark-mode textarea,
.dark-mode .modal-header,
.dark-mode .modal-footer,
.dark-mode .report-status *,
.dark-mode .report-status,
.dark-mode .rct-mail-wrapper *,
.dark-mode .todo-wrapper *,
.dark-mode .chat-wrapper *,
.dark-mode .dropdown *,
.dark-mode .chat-head {
  border-color: rgba(0, 0, 0, 0.1) !important; }

.dark-mode .app-boxed .agency-menu {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5); }
  .dark-mode .app-boxed .agency-menu .nav-item-active:after,
  .dark-mode .app-boxed .agency-menu .sub-menu:after,
  .dark-mode .app-boxed .agency-menu .sub-menu-child:after,
  .dark-mode .app-boxed .agency-menu .sub-menu-sub-child:after {
    border-color: transparent transparent #2c3644 #2c3644 !important;
    box-shadow: -3px 3.5px 7px rgba(5, 0, 0, 0.25) !important; }

.dark-mode .language-icon,
.dark-mode .upgrade-btn {
  background-color: #5D92F4 !important; }

.dark-mode .rct-sidebar .sidebar-user-block .rct-dropdown .dropdown-menu {
  background: #303030 !important; }

/*==== Rtl Layout Style Here =====*/
.rtl * .mr-0, .rtl * .mx-0 {
  margin-left: 0 !important;
  margin-right: inherit !important; }

.rtl * .ml-0,
.rtl * .mx-0 {
  margin-right: 0 !important;
  margin-left: inherit !important; }

.rtl * .mr-1, .rtl * .mx-1 {
  margin-left: 0.25rem !important;
  margin-right: inherit !important; }

.rtl * .ml-1,
.rtl * .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: inherit !important; }

.rtl * .mr-2, .rtl * .mx-2 {
  margin-left: 0.5rem !important;
  margin-right: inherit !important; }

.rtl * .ml-2,
.rtl * .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: inherit !important; }

.rtl * .mr-3, .rtl * .mx-3 {
  margin-left: 1rem !important;
  margin-right: inherit !important; }

.rtl * .ml-3,
.rtl * .mx-3 {
  margin-right: 1rem !important;
  margin-left: inherit !important; }

.rtl * .mr-4, .rtl * .mx-4 {
  margin-left: 1.5rem !important;
  margin-right: inherit !important; }

.rtl * .ml-4,
.rtl * .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: inherit !important; }

.rtl * .mr-5, .rtl * .mx-5 {
  margin-left: 0.3125rem !important;
  margin-right: inherit !important; }

.rtl * .ml-5,
.rtl * .mx-5 {
  margin-right: 0.3125rem !important;
  margin-left: inherit !important; }

.rtl * .mr-10, .rtl * .mx-10 {
  margin-left: 0.625rem !important;
  margin-right: inherit !important; }

.rtl * .ml-10,
.rtl * .mx-10 {
  margin-right: 0.625rem !important;
  margin-left: inherit !important; }

.rtl * .mr-15, .rtl * .mx-15 {
  margin-left: 0.9375rem !important;
  margin-right: inherit !important; }

.rtl * .ml-15,
.rtl * .mx-15 {
  margin-right: 0.9375rem !important;
  margin-left: inherit !important; }

.rtl * .mr-20, .rtl * .mx-20 {
  margin-left: 1.25rem !important;
  margin-right: inherit !important; }

.rtl * .ml-20,
.rtl * .mx-20 {
  margin-right: 1.25rem !important;
  margin-left: inherit !important; }

.rtl * .mr-25, .rtl * .mx-25 {
  margin-left: 1.563rem !important;
  margin-right: inherit !important; }

.rtl * .ml-25,
.rtl * .mx-25 {
  margin-right: 1.563rem !important;
  margin-left: inherit !important; }

.rtl * .mr-30, .rtl * .mx-30 {
  margin-left: 1.875rem !important;
  margin-right: inherit !important; }

.rtl * .ml-30,
.rtl * .mx-30 {
  margin-right: 1.875rem !important;
  margin-left: inherit !important; }

.rtl * .mr-40, .rtl * .mx-40 {
  margin-left: 2.5rem !important;
  margin-right: inherit !important; }

.rtl * .ml-40,
.rtl * .mx-40 {
  margin-right: 2.5rem !important;
  margin-left: inherit !important; }

.rtl * .mr-50, .rtl * .mx-50 {
  margin-left: 3.125rem !important;
  margin-right: inherit !important; }

.rtl * .ml-50,
.rtl * .mx-50 {
  margin-right: 3.125rem !important;
  margin-left: inherit !important; }

.rtl * .mr-60, .rtl * .mx-60 {
  margin-left: 3.75rem !important;
  margin-right: inherit !important; }

.rtl * .ml-60,
.rtl * .mx-60 {
  margin-right: 3.75rem !important;
  margin-left: inherit !important; }

.rtl * .mr-70, .rtl * .mx-70 {
  margin-left: 4.375rem !important;
  margin-right: inherit !important; }

.rtl * .ml-70,
.rtl * .mx-70 {
  margin-right: 4.375rem !important;
  margin-left: inherit !important; }

@media (min-width: 576px) {
  .rtl * .mr-sm-0, .rtl * .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-0,
  .rtl * .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-1, .rtl * .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-1,
  .rtl * .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-2, .rtl * .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-2,
  .rtl * .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-3, .rtl * .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-3,
  .rtl * .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-4, .rtl * .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-4,
  .rtl * .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-5, .rtl * .mx-sm-5 {
    margin-left: 0.3125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-5,
  .rtl * .mx-sm-5 {
    margin-right: 0.3125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-10, .rtl * .mx-sm-10 {
    margin-left: 0.625rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-10,
  .rtl * .mx-sm-10 {
    margin-right: 0.625rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-15, .rtl * .mx-sm-15 {
    margin-left: 0.9375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-15,
  .rtl * .mx-sm-15 {
    margin-right: 0.9375rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-20, .rtl * .mx-sm-20 {
    margin-left: 1.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-20,
  .rtl * .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-25, .rtl * .mx-sm-25 {
    margin-left: 1.563rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-25,
  .rtl * .mx-sm-25 {
    margin-right: 1.563rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-30, .rtl * .mx-sm-30 {
    margin-left: 1.875rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-30,
  .rtl * .mx-sm-30 {
    margin-right: 1.875rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-40, .rtl * .mx-sm-40 {
    margin-left: 2.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-40,
  .rtl * .mx-sm-40 {
    margin-right: 2.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-50, .rtl * .mx-sm-50 {
    margin-left: 3.125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-50,
  .rtl * .mx-sm-50 {
    margin-right: 3.125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-60, .rtl * .mx-sm-60 {
    margin-left: 3.75rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-60,
  .rtl * .mx-sm-60 {
    margin-right: 3.75rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-sm-70, .rtl * .mx-sm-70 {
    margin-left: 4.375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-sm-70,
  .rtl * .mx-sm-70 {
    margin-right: 4.375rem !important;
    margin-left: inherit !important; } }

@media (min-width: 768px) {
  .rtl * .mr-md-0, .rtl * .mx-md-0 {
    margin-left: 0 !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-0,
  .rtl * .mx-md-0 {
    margin-right: 0 !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-1, .rtl * .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-1,
  .rtl * .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-2, .rtl * .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-2,
  .rtl * .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-3, .rtl * .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-3,
  .rtl * .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-4, .rtl * .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-4,
  .rtl * .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-5, .rtl * .mx-md-5 {
    margin-left: 0.3125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-5,
  .rtl * .mx-md-5 {
    margin-right: 0.3125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-10, .rtl * .mx-md-10 {
    margin-left: 0.625rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-10,
  .rtl * .mx-md-10 {
    margin-right: 0.625rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-15, .rtl * .mx-md-15 {
    margin-left: 0.9375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-15,
  .rtl * .mx-md-15 {
    margin-right: 0.9375rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-20, .rtl * .mx-md-20 {
    margin-left: 1.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-20,
  .rtl * .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-25, .rtl * .mx-md-25 {
    margin-left: 1.563rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-25,
  .rtl * .mx-md-25 {
    margin-right: 1.563rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-30, .rtl * .mx-md-30 {
    margin-left: 1.875rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-30,
  .rtl * .mx-md-30 {
    margin-right: 1.875rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-40, .rtl * .mx-md-40 {
    margin-left: 2.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-40,
  .rtl * .mx-md-40 {
    margin-right: 2.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-50, .rtl * .mx-md-50 {
    margin-left: 3.125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-50,
  .rtl * .mx-md-50 {
    margin-right: 3.125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-60, .rtl * .mx-md-60 {
    margin-left: 3.75rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-60,
  .rtl * .mx-md-60 {
    margin-right: 3.75rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-md-70, .rtl * .mx-md-70 {
    margin-left: 4.375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-md-70,
  .rtl * .mx-md-70 {
    margin-right: 4.375rem !important;
    margin-left: inherit !important; } }

@media (min-width: 992px) {
  .rtl * .mr-lg-0, .rtl * .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-0,
  .rtl * .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-1, .rtl * .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-1,
  .rtl * .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-2, .rtl * .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-2,
  .rtl * .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-3, .rtl * .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-3,
  .rtl * .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-4, .rtl * .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-4,
  .rtl * .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-5, .rtl * .mx-lg-5 {
    margin-left: 0.3125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-5,
  .rtl * .mx-lg-5 {
    margin-right: 0.3125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-10, .rtl * .mx-lg-10 {
    margin-left: 0.625rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-10,
  .rtl * .mx-lg-10 {
    margin-right: 0.625rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-15, .rtl * .mx-lg-15 {
    margin-left: 0.9375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-15,
  .rtl * .mx-lg-15 {
    margin-right: 0.9375rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-20, .rtl * .mx-lg-20 {
    margin-left: 1.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-20,
  .rtl * .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-25, .rtl * .mx-lg-25 {
    margin-left: 1.563rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-25,
  .rtl * .mx-lg-25 {
    margin-right: 1.563rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-30, .rtl * .mx-lg-30 {
    margin-left: 1.875rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-30,
  .rtl * .mx-lg-30 {
    margin-right: 1.875rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-40, .rtl * .mx-lg-40 {
    margin-left: 2.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-40,
  .rtl * .mx-lg-40 {
    margin-right: 2.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-50, .rtl * .mx-lg-50 {
    margin-left: 3.125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-50,
  .rtl * .mx-lg-50 {
    margin-right: 3.125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-60, .rtl * .mx-lg-60 {
    margin-left: 3.75rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-60,
  .rtl * .mx-lg-60 {
    margin-right: 3.75rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-lg-70, .rtl * .mx-lg-70 {
    margin-left: 4.375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-lg-70,
  .rtl * .mx-lg-70 {
    margin-right: 4.375rem !important;
    margin-left: inherit !important; } }

@media (min-width: 1200px) {
  .rtl * .mr-xl-0, .rtl * .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-0,
  .rtl * .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-1, .rtl * .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-1,
  .rtl * .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-2, .rtl * .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-2,
  .rtl * .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-3, .rtl * .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-3,
  .rtl * .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-4, .rtl * .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-4,
  .rtl * .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-5, .rtl * .mx-xl-5 {
    margin-left: 0.3125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-5,
  .rtl * .mx-xl-5 {
    margin-right: 0.3125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-10, .rtl * .mx-xl-10 {
    margin-left: 0.625rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-10,
  .rtl * .mx-xl-10 {
    margin-right: 0.625rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-15, .rtl * .mx-xl-15 {
    margin-left: 0.9375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-15,
  .rtl * .mx-xl-15 {
    margin-right: 0.9375rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-20, .rtl * .mx-xl-20 {
    margin-left: 1.25rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-20,
  .rtl * .mx-xl-20 {
    margin-right: 1.25rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-25, .rtl * .mx-xl-25 {
    margin-left: 1.563rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-25,
  .rtl * .mx-xl-25 {
    margin-right: 1.563rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-30, .rtl * .mx-xl-30 {
    margin-left: 1.875rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-30,
  .rtl * .mx-xl-30 {
    margin-right: 1.875rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-40, .rtl * .mx-xl-40 {
    margin-left: 2.5rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-40,
  .rtl * .mx-xl-40 {
    margin-right: 2.5rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-50, .rtl * .mx-xl-50 {
    margin-left: 3.125rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-50,
  .rtl * .mx-xl-50 {
    margin-right: 3.125rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-60, .rtl * .mx-xl-60 {
    margin-left: 3.75rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-60,
  .rtl * .mx-xl-60 {
    margin-right: 3.75rem !important;
    margin-left: inherit !important; }
  .rtl * .mr-xl-70, .rtl * .mx-xl-70 {
    margin-left: 4.375rem !important;
    margin-right: inherit !important; }
  .rtl * .ml-xl-70,
  .rtl * .mx-xl-70 {
    margin-right: 4.375rem !important;
    margin-left: inherit !important; } }

.rtl * .app-conrainer-wrapper {
  right: 85px !important;
  left: inherit !important; }

.rtl * .mx-10 {
  margin: 0 0.625rem !important; }

.rtl * .tooltip {
  left: 0 !important;
  right: auto !important; }

.rtl * .rct-header .quicklink-wrapper .dropdown-menu {
  left: auto !important;
  right: -1.5rem !important; }
  .rtl * .rct-header .quicklink-wrapper .dropdown-menu:after, .rtl * .rct-header .quicklink-wrapper .dropdown-menu:before {
    left: auto !important;
    right: 10% !important; }

.rtl * .fixed-plugin .switch-btn > span {
  justify-content: flex-end; }

.rtl * .rct-scroll {
  direction: rtl !important; }
  .rtl * .rct-scroll > div:last-child {
    left: 2px;
    right: auto !important; }

@media (max-width: 2560px) and (min-width: 1800px) {
  .rtl * .rct-scroll {
    direction: rtl !important; }
    .rtl * .rct-scroll > div:first-child {
      margin-right: 0 !important;
      margin-left: -15px !important; }
    .rtl * .rct-scroll > div:last-child {
      left: 2px;
      right: auto !important; } }

.rtl * .rct-dropdown .dropdown-menu,
.rtl * .notification-icon .dropdown-menu,
.rtl * .cart-icon .dropdown-menu {
  right: auto !important;
  left: 0 !important; }
  .rtl * .rct-dropdown .dropdown-menu::after, .rtl * .rct-dropdown .dropdown-menu::before,
  .rtl * .notification-icon .dropdown-menu::after,
  .rtl * .notification-icon .dropdown-menu::before,
  .rtl * .cart-icon .dropdown-menu::after,
  .rtl * .cart-icon .dropdown-menu::before {
    left: 10%;
    right: inherit; }

.rtl * .notification-icon .dropdown-menu,
.rtl * .cart-icon .dropdown-menu {
  left: -22px !important;
  right: auto !important; }
  .rtl * .notification-icon .dropdown-menu .dropdown-body li button i,
  .rtl * .cart-icon .dropdown-menu .dropdown-body li button i {
    margin-left: 0.5rem;
    margin-right: inherit; }

.rtl * .ais-RangeInput-submit {
  margin: 0 0.5rem 0 0; }

.rtl * .filter-sm-wrap [role="button"] {
  left: 8px;
  right: inherit; }

.rtl * .app-container {
  overflow-x: hidden;
  overflow-y: hidden; }

.rtl * .list-inline-item:not(:last-child),
.rtl * .page-title .page-title-wrap > i,
.rtl * .table-action a,
.rtl * .card-action a i,
.rtl * .session-logo i,
.rtl * .ladgend,
.rtl * .all-mails-list .media-body span.badge,
.rtl * .todo-listing .media-body span.badge,
.rtl * .timeline-ring,
.rtl * .plan-info-listing li i,
.rtl * .like-box,
.rtl * .search-filter .form-group,
.rtl * .rct-dropdown .dropdown-menu ul li a i,
.rtl * .navbar-right > li .dropdown-group-link img,
.rtl * .btn-icon i,
.rtl * .joyride-tooltip__button--secondary {
  margin-right: inherit !important; }

.rtl * .rct-block-title .contextual-link a, .rtl * .faq-page-wrapper .panel-heading .contextual-link a, .faq-page-wrapper .rtl * .panel-heading .contextual-link a,
.rtl * .rct-mail-wrapper header,
.rtl * .todo-wrapper header,
.rtl * .chat-wrapper header {
  margin-left: inherit !important; }

.rtl * .timeline-wrapper::before,
.rtl * .testimonial-slider .slick-dots,
.rtl * .switch.on .switch-toggle,
.rtl * .todo-listing .task-detail-top .badge-bottom,
.rtl * label {
  left: inherit; }

.rtl * .contextual-link,
.rtl * .contextual-link,
.rtl * .app-selectbox::before,
.rtl * .app-selectbox-sm::before,
.rtl * .notification-container,
.rtl * .has-wrapper .has-icon,
.rtl * .task-btn,
.rtl * .divider-wrapper ul li > div,
.rtl * .panel-heading > div,
.rtl * .todo-listing .task-detail-content .task-btn,
.rtl * .joyride-tooltip__close {
  right: inherit; }

.rtl * .navbar-right > li .dropdown-group-link img {
  margin-left: 5px !important; }

.rtl * .page-title .page-title-wrap > i {
  margin-left: 0.3125rem; }

.rtl * .table-action a,
.rtl * .card-action a i,
.rtl * .session-logo i,
.rtl * .ladgend,
.rtl * .all-mails-list .media-body span.badge,
.rtl * .todo-listing .media-body span.badge,
.rtl * .btn-icon i,
.rtl * .joyride-tooltip__button--secondary {
  margin-left: 0.625rem; }

.rtl * .timeline-wrapper::before {
  right: 9px; }

.rtl * .timeline-ring,
.rtl * .plan-info-listing li i,
.rtl * .search-filter .form-group,
.rtl * .rct-dropdown .dropdown-menu ul li a i {
  margin-left: 0.9375rem; }

.rtl * .rct-block-title .contextual-link a, .rtl * .faq-page-wrapper .panel-heading .contextual-link a, .faq-page-wrapper .rtl * .panel-heading .contextual-link a {
  margin-right: 0.9375rem; }

.rtl * .list-inline-item:not(:last-child) {
  margin-left: 0.5rem; }

.rtl * .pull-right,
.rtl * .float-right {
  float: left !important; }

.rtl * .pull-left,
.rtl * .float-left {
  float: right !important; }

.rtl * .text-right {
  text-align: left !important; }

.rtl * .ql-editor,
.rtl * .to-do-list .float-left,
.rtl * .media-body,
.rtl * td,
.rtl * .rct-mainMenu li,
.rtl * .rct-dropdown .dropdown-menu ul li a,
.rtl * .post-item,
.rtl * .comment-wrap {
  text-align: right !important; }

.rtl * .list-group {
  padding: 0; }

.rtl * .feed-content {
  border-right: 3px solid #5D92F4;
  border-left: none; }

.rtl * .contextual-link,
.rtl * .joyride-tooltip__close {
  left: 20px; }


.rtl * .app-selectbox::before,
.rtl * .app-selectbox-sm::before {
  left: 10px; }

.rtl * .notification-container {
  left: 0; }

.rtl * .todo-listing .task-detail-content .task-btn {
  left: -5px; }

.rtl * .to-do-list label,
.rtl * .card-base label,
.rtl * .todo-all-list label {
  margin-right: -14px;
  margin-left: 16px; }

.rtl * .testimonial-slider .slick-dots {
  text-align: right;
  right: 185px; }

.rtl * .switch.on .switch-toggle {
  right: 23px; }

.rtl * .has-wrapper .has-icon {
  left: 25px; }

.rtl * .rct-mail-wrapper .all-mails-list ul li {
  padding-left: 2rem !important; }

.rtl * .todo-wrapper header {
  margin-right: 250px; }

.rtl * .rct-mail-wrapper header {
  margin-right: 280px; }

.rtl * .todo-listing .task-detail-top .badge-bottom {
  right: 45px; }

.rtl * label {
  right: 0; }

.rtl * .task-btn {
  left: -5px; }

.rtl * .search-icon:before {
  right: 0.3125rem !important;
  left: 0 !important; }

.rtl * .search-icon .Select-placeholder,
.rtl * .search-icon .Select-input,
.rtl * .search-icon .Select-value {
  padding-left: 0 !important;
  padding-right: 1.875rem !important; }

.rtl * .team-avatar ul li:not(:first-child) {
  margin-left: 0rem !important;
  margin-right: -0.85rem !important; }

.rtl * .chip-wrapper svg {
  margin: 0 -8px 0 4px; }

.rtl * .divider-wrapper ul li > div {
  text-align: right;
  left: 4px; }

.rtl * .like-box {
  margin-left: 3.25rem; }

.rtl * .panel-heading > div {
  left: 8px; }

.rtl * .input-group-prepend {
  border-right: 1px solid #DBDBDB;
  border-left: none;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rtl * .input-group > .form-control:not(:first-child),
.rtl * .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rtl * .list-action a, .rtl * .social-action a,
.rtl * .social-action a {
  margin-left: 0.625rem; }

.rtl * .fixed-plugin {
  left: 0;
  border-radius: 0 10px 10px 0px;
  right: inherit; }
  .rtl * .fixed-plugin .dropdown .dropdown-menu {
    right: -19rem !important; }
    .rtl * .fixed-plugin .dropdown .dropdown-menu > div > div {
      margin-left: -10px;
      margin-right: inherit; }
    .rtl * .fixed-plugin .dropdown .dropdown-menu::before {
      border-left: .6rem solid transparent;
      border-right: 0.6rem solid #fff;
      left: 0; }

.rtl .chat-list .rct-scroll > div {
  margin-right: 0 !important;
  margin-left: -15px; }

.rtl .chat-list li {
  border-left: none !important;
  border-right: 6px solid rgba(0, 0, 0, 0); }
  .rtl .chat-list li.item-active {
    border-right-color: #FF3739; }

.rtl .agency-menu .nav > .nav-item .sub-menu > .nav-item:after {
  left: auto !important; }

@media (max-width: 559px) {
  .rtl .chat-list .rct-scroll > div {
    margin: 0 !important; }
  .rtl .chat-sidebar .chat-list .rct-scroll ul {
    margin: 0 !important; } }

/*======= header ======*/
.rtl .app-header .rct-header .site-logo {
  padding: 1rem 2rem 0.8rem 4rem; }

.rtl .app-header .rct-header .navbar-left {
  margin: 0 0.5rem 0 0; }

/*======= For SideBar ======*/
.rtl.mini-sidebar .rct-sidebar .site-logo {
  padding: 0.9375rem 0.5rem; }
  .rtl.mini-sidebar .rct-sidebar .site-logo .logo-mini img {
    margin: 0 0.9375rem !important; }

.rtl.mini-sidebar .rct-sidebar .rct-mainMenu > li .menu-icon, .rtl.mini-sidebar .rct-sidebar .rct-mainMenu > li > a .rct-mainMenu > li .menu-icon {
  float: right; }

.rtl.mini-sidebar .rct-sidebar .rct-mainMenu > li .side-arrow {
  transform: rotate(180deg) !important;
  right: 13.5rem; }

.rtl .collapsed-sidebar .rct-header {
  right: 0 !important; }

.rtl .rct-header.mui-fixed {
  right: 16.25rem;
  left: 0; }

.rtl .rct-dropdown .dropdown-menu ul li a i {
  float: right; }

.rtl .rct-sidebar .rct-mainMenu li.list-item:after {
  content: "\F2FA" !important;
  right: auto !important;
  left: 1.5rem; }

.rtl .rct-sidebar .rct-mainMenu li.list-item.item-active:after {
  transform: rotate(-90deg) !important; }

.rtl .rct-sidebar .rct-mainMenu .sub-menu ul li a {
  padding: 0.75rem 3.4rem 0.75rem 1.5rem !important; }

.rtl .rct-sidebar .rct-mainMenu > div .sub-menu li .side-arrow {
  margin-left: 1.5rem !important; }

.rtl .rct-sidebar .rct-mainMenu > div .sub-menu li.item-active .side-arrow {
  transform: rotate(90deg) !important; }

.rtl .rct-sidebar .rct-mainMenu div .side-arrow,
.rtl .rct-sidebar .rct-mainMenu li .side-arrow {
  margin-left: 0px;
  margin-right: inherit;
  transform: rotate(180deg); }

.rtl .rct-sidebar .rct-mainMenu > li .menu-icon, .rtl .mini-sidebar .rct-sidebar .rct-mainMenu > li .rct-mainMenu > li > a .menu-icon, .mini-sidebar .rtl .rct-sidebar .rct-mainMenu > li .rct-mainMenu > li > a .menu-icon, .rtl .mini-sidebar .rct-sidebar .rct-mainMenu > li > a .rct-mainMenu > li .menu-icon, .mini-sidebar .rtl .rct-sidebar .rct-mainMenu > li > a .rct-mainMenu > li .menu-icon {
  margin-left: 0.9375rem;
  margin-right: inherit; }

.rtl .rct-sidebar .rct-mainMenu > li.item-active .side-arrow {
  transform: rotate(90deg) !important; }

.rtl .rct-sidebar .rct-mainMenu > li > a .menu {
  right: 45px;
  left: inherit; }

.rtl .rct-sidebar .rct-mainMenu > li .menu {
  right: 60px;
  left: inherit; }

.rtl .rct-sidebar .rct-mainMenu .sub-menu li {
  padding-right: 3.8rem;
  padding-left: inherit; }

.rtl .rct-sidebar .rct-mainMenu .side-title {
  padding: 0 0.6875rem; }

.rtl .rct-mail-wrapper header .d-flex button,
.rtl .todo-wrapper header .d-flex button {
  transform: rotate(180deg); }

.rtl .notification-dropdown .dropdown-menu,
.rtl .language-dropdown .dropdown-menu,
.rtl .cart-dropdown .dropdown-menu {
  left: 0 !important;
  right: auto !important; }

.rtl .app-boxed .rct-page .agency-menu .nav > .nav-item .sub-menu {
  left: auto;
  right: 1.5rem; }
  .rtl .app-boxed .rct-page .agency-menu .nav > .nav-item .sub-menu:after {
    left: auto;
    right: 2rem; }
  .rtl .app-boxed .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child {
    left: auto;
    right: 15.8rem; }
    .rtl .app-boxed .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child:after {
      left: auto;
      right: -0.5rem;
      transform: rotate(-135deg); }
    .rtl .app-boxed .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child li .sub-menu-sub-child {
      left: auto;
      right: 12rem; }
      .rtl .app-boxed .rct-page .agency-menu .nav > .nav-item .sub-menu .nav-item > .sub-menu-child li .sub-menu-sub-child:after {
        left: auto;
        right: -0.5rem;
        transform: rotate(-135deg); }

.rtl .app-boxed.app-boxed-v2 .trending-news-widegt .slick-track,
.rtl .app-boxed.app-boxed-v2 .top-author-wrap .slick-track,
.rtl .app-boxed.app-boxed-v2 .twitter-feeds-widget .slick-track,
.rtl .app-boxed.app-boxed-v2 .main-slider-wrap .slick-track {
  direction: rtl; }

.rtl .app-boxed.app-boxed-v2 .main-slider-wrap .slick-slide {
  float: left; }

.rtl .app-boxed.app-boxed-v2 .trending-news-widegt .trending-block {
  border-right: none;
  border-left: 1px solid #fff; }

.rtl .app-boxed.app-boxed-v2 .trending-news-widegt .slick-arrow.slick-prev {
  right: auto;
  left: 3rem; }

.rtl .app-boxed.app-boxed-v2 .trending-news-widegt .slick-arrow.slick-next {
  right: auto;
  left: 1rem; }

.rtl .app-boxed.app-boxed-v2 .top-author-wrap .author-detail-wrap .footer-content li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #EBEDF2; }

.rtl .app-boxed.app-boxed-v2 .top-author-wrap .slick-arrow.slick-prev {
  right: 50px !important;
  left: auto;
  transform: translateX(-1rem); }

.rtl .app-boxed.app-boxed-v2 .top-author-wrap .slick-arrow.slick-next {
  left: 50px;
  right: auto;
  transform: translateX(1rem); }

.rtl .app-boxed.app-boxed-v2 .top-author-wrap:hover .slick-arrow {
  transform: translate(0); }

.rtl .app-boxed.app-boxed-v2 .top-news-wrap .slider-btn-wrap .slick-arrow.slick-prev {
  right: 1rem;
  left: auto;
  transform: translateX(-1rem); }

.rtl .app-boxed.app-boxed-v2 .top-news-wrap .slider-btn-wrap .slick-arrow.slick-next {
  left: 1rem;
  right: auto;
  transform: translateX(1rem); }

.rtl .app-boxed.app-boxed-v2 .top-news-wrap .slider-btn-wrap:hover .slick-arrow {
  transform: translate(0); }

.rtl .activity-widget ul li {
  border-left: none;
  border-right: 1px solid #EBEDF2; }
  .rtl .activity-widget ul li span.badge {
    right: -9.75px;
    left: auto; }

.rtl .social-card > span:last-child {
  border-left: none !important;
  border-right: 1px dashed #EBEDF2; }

@media (max-width: 500px) {
  .rtl .top-headlines-widget li .news-time,
  .rtl .comment-section ul > div .avatar-wrap,
  .rtl .comment-section ul > div .comment-date {
    text-align: right !important; } }

@media (max-width: 1199px) and (min-width: 320px) {
  .rtl .rct-header.mui-fixed {
    right: 0 !important; } }

@media (max-width: 959px) and (min-width: 320px) {
  .rtl .rct-mail-wrapper header,
  .rtl .todo-wrapper header,
  .rtl .chat-wrapper header {
    margin-right: 0 !important; } }

@media (max-width: 340px) and (min-width: 320px) {
  .rtl * .list-inline-item:not(:first-child) {
    margin-left: 0rem; } }

@media (max-width: 1200px) and (min-width: 900px) {
  .rtl .agency-menu .nav > .nav-item:last-child > .sub-menu > .nav-item:last-child .sub-menu-child {
    right: 0; }
    .rtl .agency-menu .nav > .nav-item:last-child > .sub-menu > .nav-item:last-child .sub-menu-child li .sub-menu-sub-child {
      right: 15.8rem !important; } }

/*========= Boxed Layout In Rtl =========*/
.boxed-layout.rtl .rct-header.mui-fixed {
  right: 0; }

.boxed-layout.rtl .rct-page-wrapper [direction="left"] {
  right: 16.25rem; }

/*=========== Horizontal Menu ============*/
.rtl .horizontal-menu > ul > li .sub-menu {
  right: 0;
  left: inherit; }
  .rtl .horizontal-menu > ul > li .sub-menu li a {
    padding: 0.8125rem 1.563rem 0.8125rem 0.625rem; }
    .rtl .horizontal-menu > ul > li .sub-menu li a::after {
      left: 10px;
      right: inherit;
      transform: rotate(180deg); }
    .rtl .horizontal-menu > ul > li .sub-menu li a i {
      margin-left: 0.625rem;
      margin-right: inherit;
      float: right; }
  .rtl .horizontal-menu > ul > li .sub-menu li .sub-menu-child {
    right: 15rem; }
    .rtl .horizontal-menu > ul > li .sub-menu li .sub-menu-child li .sub-menu-sub-child {
      right: 15rem; }

.rtl .horizontal-menu > ul > li > a::after {
  margin-right: 0.9375rem;
  margin-left: inherit; }

.rtl .horizontal-menu > ul > li > a i {
  margin-left: 0.625rem;
  margin-right: inherit; }

.rtl .app-boxed .agency-menu {
  padding: 0 0 0 1.5rem !important; }

@media (max-width: 700px) and (min-width: 320px) {
  .rtl .horizontal-menu > ul > li .sub-menu li .sub-menu-child {
    right: 0;
    left: inherit; }
    .rtl .horizontal-menu > ul > li .sub-menu li .sub-menu-child li a {
      padding-right: 1.875rem;
      padding-left: inherit; } }

@media (max-width: 450px) {
  .rtl .language-dropdown .dropdown-menu {
    left: -5rem !important; }
  .rtl .notification-dropdown .dropdown-menu {
    left: -3rem !important; } }

/*========= App Boxed Layout ========*/
.boxed-layout {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  width: 1280px !important;
  background-image: url(/static/media/boxed-bg.1fa5de4f.png); }
  .boxed-layout .search-icon {
    width: 16rem; }
  .boxed-layout .b-50 {
    max-width: 50% !important;
    flex-basis: 50% !important; }
  .boxed-layout .b-100 {
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .boxed-layout .card-heading {
    padding: 0.625rem; }
  .boxed-layout .about-detail .p-70 {
    padding: 10px !important; }
  .boxed-layout .about-detail .title {
    margin-bottom: 10px !important;
    font-size: 1.5rem;
    line-height: 1.5; }
  .boxed-layout .dash-cards .media .mr-25 {
    margin-right: 10px !important; }
  .boxed-layout .dash-cards .media img {
    width: 50px;
    height: 50px; }
  .boxed-layout .rct-weather-widget .d-flex {
    display: inline-block !important; }
  .boxed-layout .rct-weather-widget .weather-content {
    text-align: center; }
  .boxed-layout .rct-weather-widget h2, .boxed-layout .rct-weather-widget h3, .boxed-layout .rct-weather-widget h4 {
    font-size: 1rem;
    margin: 0; }
  .boxed-layout .rct-weather-widget i {
    line-height: 70px;
    font-size: 2.5rem !important; }
  .boxed-layout .current-widget h2 {
    font-size: 2.1rem; }
  .boxed-layout .featured-section-icon {
    font-size: 2rem; }

/*====== All themes Files Include Here ======*/
/*====== Danger Theme ======*/
body.theme-danger .text-primary,
body.theme-danger .breadcrumb a,
body.theme-danger .dropdown-foot a,
body.theme-danger .faq-page-wrapper .panel-heading h4,
body.theme-danger .agency-menu .nav > .nav-item.nav-item-active > .nav-link,
body.theme-danger .agency-menu .nav .nav-item:hover > .nav-link {
  color: #FF3739 !important; }

body.theme-danger .rct-dropdown .dropdown-menu .rct-scroll ul li a:hover,
body.theme-danger .humburger,
body.theme-danger .search-icon::before,
body.theme-danger .Select-placeholder,
body.theme-danger .Select-arrow-zone,
body.theme-danger .header-icon:not(.language-icon),
body.theme-danger .navbar-right > li > a > button,
body.theme-danger .navbar-right > li > button,
body.theme-danger .dropdown-group-link,
body.theme-danger .search-icon-btn {
  color: #fff !important; }

body.theme-danger .badge-primary *,
body.theme-danger .bg-primary *,
body.theme-danger .btn-primary * {
  color: #fff !important; }

body.theme-danger .badge-primary,
body.theme-danger .bg-primary,
body.theme-danger .btn-primary,
body.theme-danger .app-header .rct-header,
body.theme-danger .chat-body .chat-bubble.odd,
body.theme-danger .rct-dropdown .dropdown-menu ul li a:hover,
body.theme-danger .fixed-plugin,
body.theme-danger header .dropdown-group-link,
body.theme-danger figure.img-wrapper::after,
body.theme-danger .rct-sidebar .sidebar-overlay-light .site-logo,
body.theme-danger .rbc-event {
  background: #FF3739 !important; }

body.theme-danger .btn-primary,
body.theme-danger .rct-dropdown .dropdown-group-link,
body.theme-danger .feed-content,
body.theme-danger .rbc-event,
body.theme-danger .agency-menu .nav > .nav-item .sub-menu > .nav-item:after {
  border-color: #FF3739 !important; }

body.theme-danger .quicklink-wrapper .dropdown-menu:after,
body.theme-danger .quicklink-wrapper .dropdown-menu:before,
body.theme-danger .sidebar-user-block .rct-dropdown.show .dropdown-menu:before,
body.theme-danger .sidebar-user-block .rct-dropdown.show .dropdown-menu:after {
  border-bottom-color: #FF3739 !important; }

body.theme-danger .header-icon:hover,
body.theme-danger .header-icon:focus {
  border-color: #fff !important; }

body.theme-danger .text-danger {
  color: #5D92F4 !important; }

body.theme-danger .badge-danger,
body.theme-danger .bg-danger,
body.theme-danger .btn-danger {
  background: #5D92F4 !important; }

body.theme-danger .btn-danger {
  border-color: #5D92F4 !important; }

/*====== Info Theme ======*/
body.theme-info .text-primary,
body.theme-info .breadcrumb a,
body.theme-info .dropdown-foot a,
body.theme-info .faq-page-wrapper .panel-heading h4,
body.theme-info .agency-menu .nav > .nav-item.nav-item-active > .nav-link,
body.theme-info .agency-menu .nav .nav-item:hover > .nav-link {
  color: #00D0BD !important; }

body.theme-info .rct-dropdown .dropdown-menu .rct-scroll ul li a:hover,
body.theme-info .humburger,
body.theme-info .search-icon::before,
body.theme-info .Select-placeholder,
body.theme-info .Select-arrow-zone,
body.theme-info .header-icon:not(.language-icon),
body.theme-info .navbar-right > li > a > button,
body.theme-info .navbar-right > li > button,
body.theme-info .dropdown-group-link,
body.theme-info .search-icon-btn {
  color: #fff !important; }

body.theme-info .badge-primary *,
body.theme-info .bg-primary *,
body.theme-info .btn-primary * {
  color: #fff !important; }

body.theme-info .badge-primary,
body.theme-info .bg-primary,
body.theme-info .btn-primary,
body.theme-info .app-header .rct-header,
body.theme-info .chat-body .chat-bubble.odd,
body.theme-info .rct-dropdown .dropdown-menu ul li a:hover,
body.theme-info .fixed-plugin,
body.theme-info header .dropdown-group-link,
body.theme-info figure.img-wrapper::after,
body.theme-info .rct-sidebar .sidebar-overlay-light .site-logo,
body.theme-info .rbc-event {
  background: #00D0BD !important; }

body.theme-info .btn-primary,
body.theme-info .rct-dropdown .dropdown-group-link,
body.theme-info .feed-content,
body.theme-info .rbc-event,
body.theme-info .agency-menu .nav > .nav-item .sub-menu > .nav-item:after {
  border-color: #00D0BD !important; }

body.theme-info .quicklink-wrapper .dropdown-menu:after,
body.theme-info .quicklink-wrapper .dropdown-menu:before,
body.theme-info .sidebar-user-block .rct-dropdown.show .dropdown-menu:before,
body.theme-info .sidebar-user-block .rct-dropdown.show .dropdown-menu:after {
  border-bottom-color: #00D0BD !important; }

body.theme-info .header-icon:hover,
body.theme-info .header-icon:focus {
  border-color: #fff !important; }

body.theme-info .text-info {
  color: #5D92F4 !important; }

body.theme-info .badge-info, body.theme-info .bg-info, body.theme-info .btn-info {
  background: #5D92F4 !important; }

body.theme-info .btn-info {
  border-color: #5D92F4 !important; }

/*====== Sucsess Theme ======*/
body.theme-success .text-primary,
body.theme-success .breadcrumb a,
body.theme-success .dropdown-foot a,
body.theme-success .faq-page-wrapper .panel-heading h4,
body.theme-success .agency-menu .nav > .nav-item.nav-item-active > .nav-link,
body.theme-success .agency-menu .nav .nav-item:hover > .nav-link {
  color: #00D014 !important; }

body.theme-success .rct-dropdown .dropdown-menu .rct-scroll ul li a:hover,
body.theme-success .humburger,
body.theme-success .search-icon::before,
body.theme-success .Select-placeholder,
body.theme-success .Select-arrow-zone,
body.theme-success .header-icon:not(.language-icon),
body.theme-success .navbar-right > li > a > button,
body.theme-success .navbar-right > li > button,
body.theme-success .dropdown-group-link,
body.theme-success .search-icon-btn {
  color: #fff !important; }

body.theme-success .badge-primary *,
body.theme-success .bg-primary *,
body.theme-success .btn-primary * {
  color: #fff !important; }

body.theme-success .badge-primary,
body.theme-success .bg-primary,
body.theme-success .btn-primary,
body.theme-success .app-header .rct-header,
body.theme-success .chat-body .chat-bubble.odd,
body.theme-success .rct-dropdown .dropdown-menu ul li a:hover,
body.theme-success .fixed-plugin,
body.theme-success header .dropdown-group-link,
body.theme-success figure.img-wrapper::after,
body.theme-success .rct-sidebar .sidebar-overlay-light .site-logo,
body.theme-success .rbc-event {
  background: #00D014 !important; }

body.theme-success .btn-primary,
body.theme-success .rct-dropdown .dropdown-group-link,
body.theme-success .feed-content,
body.theme-success .rbc-event,
body.theme-success .agency-menu .nav > .nav-item .sub-menu > .nav-item:after {
  border-color: #00D014 !important; }

body.theme-success .quicklink-wrapper .dropdown-menu:after,
body.theme-success .quicklink-wrapper .dropdown-menu:before,
body.theme-success .sidebar-user-block .rct-dropdown.show .dropdown-menu:before,
body.theme-success .sidebar-user-block .rct-dropdown.show .dropdown-menu:after {
  border-bottom-color: #00D014 !important; }

body.theme-success .header-icon:hover,
body.theme-success .header-icon:focus {
  border-color: #fff !important; }

body.theme-success .text-success {
  color: #5D92F4 !important; }

body.theme-success .badge-success,
body.theme-success .bg-success,
body.theme-success .btn-success {
  background: #5D92F4 !important; }

body.theme-success .btn-success {
  border-color: #5D92F4 !important; }

/*====== Warning Theme ======*/
body.theme-warning .text-primary,
body.theme-warning .breadcrumb a,
body.theme-warning .dropdown-foot a,
body.theme-warning .faq-page-wrapper .panel-heading h4,
body.theme-warning .agency-menu .nav > .nav-item.nav-item-active > .nav-link,
body.theme-warning .agency-menu .nav .nav-item:hover > .nav-link {
  color: #FFB70F !important; }

body.theme-warning .rct-dropdown .dropdown-menu .rct-scroll ul li a:hover,
body.theme-warning .humburger,
body.theme-warning .search-icon::before,
body.theme-warning .Select-placeholder,
body.theme-warning .Select-arrow-zone,
body.theme-warning .header-icon:not(.language-icon),
body.theme-warning .navbar-right > li > a > button,
body.theme-warning .navbar-right > li > button,
body.theme-warning .dropdown-group-link,
body.theme-warning .search-icon-btn {
  color: #fff !important; }

body.theme-warning .badge-primary *,
body.theme-warning .bg-primary *,
body.theme-warning .btn-primary * {
  color: #fff !important; }

body.theme-warning .badge-primary,
body.theme-warning .bg-primary,
body.theme-warning .btn-primary,
body.theme-warning .app-header .rct-header,
body.theme-warning .chat-body .chat-bubble.odd,
body.theme-warning .rct-dropdown .dropdown-menu ul li a:hover,
body.theme-warning .fixed-plugin,
body.theme-warning header .dropdown-group-link,
body.theme-warning figure.img-wrapper::after,
body.theme-warning .rct-sidebar .sidebar-overlay-light .site-logo,
body.theme-warning .rbc-event {
  background: #FFB70F !important; }

body.theme-warning .btn-primary,
body.theme-warning .rct-dropdown .dropdown-group-link,
body.theme-warning .feed-content,
body.theme-warning .rbc-event,
body.theme-warning .agency-menu .nav > .nav-item .sub-menu > .nav-item:after {
  border-color: #FFB70F !important; }

body.theme-warning .quicklink-wrapper .dropdown-menu:after,
body.theme-warning .quicklink-wrapper .dropdown-menu:before,
body.theme-warning .sidebar-user-block .rct-dropdown.show .dropdown-menu:before,
body.theme-warning .sidebar-user-block .rct-dropdown.show .dropdown-menu:after {
  border-bottom-color: #FFB70F !important; }

body.theme-warning .header-icon:hover,
body.theme-warning .header-icon:focus {
  border-color: #fff !important; }

body.theme-warning .text-warning {
  color: #5D92F4 !important; }

body.theme-warning .badge-warning,
body.theme-warning .bg-warning,
body.theme-warning .btn-warning {
  background: #5D92F4 !important; }

body.theme-warning .btn-warning {
  border-color: #5D92F4 !important; }

/*====== Secondary Theme ======*/
body.theme-secondary .text-primary,
body.theme-secondary .breadcrumb a,
body.theme-secondary .dropdown-foot a,
body.theme-secondary .faq-page-wrapper .panel-heading h4,
body.theme-secondary .agency-menu .nav > .nav-item.nav-item-active > .nav-link,
body.theme-secondary .agency-menu .nav .nav-item:hover > .nav-link {
  color: #677080 !important; }

body.theme-secondary .rct-dropdown .dropdown-menu .rct-scroll ul li a:hover,
body.theme-secondary .humburger,
body.theme-secondary .search-icon::before,
body.theme-secondary .Select-placeholder,
body.theme-secondary .Select-arrow-zone,
body.theme-secondary .header-icon:not(.language-icon),
body.theme-secondary .navbar-right > li > a > button,
body.theme-secondary .navbar-right > li > button,
body.theme-secondary .dropdown-group-link,
body.theme-secondary .search-icon-btn {
  color: #fff !important; }

body.theme-secondary .header-icon:hover,
body.theme-secondary .header-icon:focus {
  border-color: #fff !important; }

body.theme-secondary .badge-primary *,
body.theme-secondary .bg-primary *,
body.theme-secondary .btn-primary * {
  color: #fff !important; }

body.theme-secondary .badge-primary,
body.theme-secondary .bg-primary,
body.theme-secondary .btn-primary,
body.theme-secondary .app-header .rct-header,
body.theme-secondary .chat-body .chat-bubble.odd,
body.theme-secondary .rct-dropdown .dropdown-menu ul li a:hover,
body.theme-secondary .fixed-plugin,
body.theme-secondary header .dropdown-group-link,
body.theme-secondary figure.img-wrapper::after,
body.theme-secondary .rct-sidebar .sidebar-overlay-light .site-logo,
body.theme-secondary .rbc-event {
  background: #677080 !important; }

body.theme-secondary .btn-primary,
body.theme-secondary .rct-dropdown .dropdown-group-link,
body.theme-secondary .feed-content,
body.theme-secondary .rbc-event,
body.theme-secondary .agency-menu .nav > .nav-item .sub-menu > .nav-item:after {
  border-color: #677080 !important; }

body.theme-secondary .quicklink-wrapper .dropdown-menu:after,
body.theme-secondary .quicklink-wrapper .dropdown-menu:before,
body.theme-secondary .sidebar-user-block .rct-dropdown.show .dropdown-menu:before,
body.theme-secondary .sidebar-user-block .rct-dropdown.show .dropdown-menu:after {
  border-bottom-color: #677080 !important; }

body.theme-secondary .text-secondary {
  color: #5D92F4 !important; }

body.theme-secondary .badge-secondary, body.theme-secondary .bg-secondary, body.theme-secondary .btn-secondary {
  background: #5D92F4 !important; }

body.theme-secondary .btn-secondary {
  border-color: #5D92F4 !important; }
